import React, { useState } from 'react';
import APIConstant from '../../utility/APIConstant';
import './Acl.scss'
import Checkbox from '@mui/material/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../../redux/actions/userActions'
const Acl = () => {

    const dispatch = useDispatch();
    const userInfo = useSelector( state => state.user );
    const roles = APIConstant.ROLES;
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    let acl_name_styles={width:"15%",position: 'sticky',left: 0,zIndex:2,background: 'white',paddingLeft:'12px'}
    const [acl_res,setACL] = useState(userInfo.acl )

    const interpolateAcess = () =>{
        const features =  {}
        acl_res.features.forEach(item => {
            features[item.id] = item;
        })
        let root = acl_res.acl.find(item => {return item.role === 0});
        root = JSON.parse( JSON.stringify(root));
        root.access.map(item => {
            item.f = features[item.fid];
            item.grants.map(g =>{
                const gname =  item.f.permissions.find(p => { return (p.id) === (g)  })
                return gname;
            })

            return item;
        })
        return root.access;
    }

    const acl_table = interpolateAcess();
    console.log(acl_table)


    const setPermission = (event,roleid, fid,  prmid ) => {
        const checked = event.target.checked;
        const role = acl_res.acl.find(r => { return roleid === r.role})
        const feature = role.access.find(a => {return a.fid === fid})
        if(feature !== undefined)    {
            // console.log('ROLE  ',role)
            // console.log('FEATURE  ',feature.fid, '--',feature.grants)
            // console.log('PERMSN  ',prmid)
            checked ? feature.grants.push(prmid) : feature.grants.splice(feature.grants.indexOf(prmid),1)

        }
        else{
            const g = [];
            g.push(prmid)
            role.access.push({'fid':fid,'grants': g})
            // console.log('feature no there',role.access)
        }
        console.log(acl_res.acl)
    } 

    const getPermission = (roleid, fid,  prmid) => {
        const role = acl_res.acl.find(r => { return roleid === r.role})
        const feature = role.access.find(a => {return a.fid === fid})
        if(feature !== undefined)   {
             return ( feature.grants.indexOf(prmid) !== -1 ? true : false )
        }
        else    {
            return false;
        }
    }


    const saveAcl = ()=>{
        console.debug("acl_res.acl",acl_res.acl,userInfo?.loggedInUser)
        dispatch(userActions.updateAclRequest({"_id":"6270adcbca076d36eee9e9e2","acl":acl_res.acl}))
    }

    return <div className='acl-items-container'>
    <div className='acl-items'>
        <br></br>
        <div className='bold-text' style={{width:"50%",float:"left"}}>Features</div> 
                        <ul className='role-items list-style-none' style={{width:"50%",float:"left"}}>
                            { acl_res.roles.map( ( key, index ) => {
                                return <li key={ key.id }>
                                    <div >{ key.name }</div>
                                </li>

                            } ) }
                        </ul>

        <div className='row'>
            <div className='col-lg-12 col-md-12 col-sm-12'>
                <ul className='features list-style-none'>
                    {
                        acl_table.map( ( item, index ) => {
                            return (<li key={ item.f.id } className='acl-flex-item'>
                               
                                <div className='custom-position'>
                               <div className='bold-text action-type'> {item.f.name}</div> 
                               {item.f.permissions.map((p,pindex) => {
                                   return (
                                   <ul>
                                   
                                   <li className='acl-flex-item' key={pindex}>
                                         <div style={acl_name_styles}>{ p.name }</div>
                                         <div style={{width:"50%"}} className='acl-flex-item'>
 

                                         {acl_res.acl.map(( grnt, gindx ) => {
                                              const rolerant = grnt.access.find(f => {return f.fid=== item.f.id})
                                             return (
                                               
                                                <> <Checkbox { ...label } defaultChecked={getPermission(grnt.role, item.f.id, p.id)} key={(gindx) }  onChange={(event) => { setPermission(event,grnt.role, item.f.id, p.id) }}/><span>
                                                    {/* ROLE::{(grnt.role)}<br></br>
                                                    FID::{item.f.id}  <br></br>  
                                                    PRM::{p.id}   */}
                                                     </span> </> 
                                                )


                                         })}
                                            
                                         </div>
                                        </li>

                                   </ul>
                                   )
                               })}
                               </div>

                            </li>)
                        })
                    }
                    
                    {/* { Object.keys( userInfo?.acl ).length > 0 && userInfo?.acl?.flist.length > 0 && userInfo?.acl?.flist.map( ( flist ) => {
                        return <li key={ flist?.id } className='acl-flex-item'>
                            <div className='custom-position'>
                               <div className='bold-text'>{ flist?.name }</div> 
                                { userInfo?.acl?.permissions.length > 0 && <ul className='list-style-none'> { userInfo?.acl?.permissions.map( ( p,index ) => {
                                    return <li className='acl-flex-item'>
                                        <div style={{width:"50%"}}>{ p.name }</div>
                                        <div style={{width:"50%"}} className='acl-flex-item'>{ Object.keys( roles ).map( ( key, index ) => {
                                            return <Checkbox { ...label } key={index}/>
                                        } ) }
                                        </div>
                                    </li>
                                } ) }</ul> }
                            </div>
                        </li>
                    } ) } */}


                </ul>
            </div>

        </div>

        <div className='btn-items'>
            <button className= {'btn btn-save'} 
            onClick={()=>saveAcl()}
            >Save</button>
            <button className='btn btn-cancel'>Cancel</button>
        </div>


     </div>
    </div>
}

export default Acl