import React, { useState,useEffect } from 'react'
import './Updates.scss';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions';
import SupplimentaryPanel from './SupplimentaryPanel';

const Updates = () => {

    const userInfo = useSelector( state => state?.user );
    let Updates=userInfo?.supplementaries?.updates;
    const [slectedContent, setSlectedContent] = useState(null);
    const [slectedUpdateId, setSlectedUpdateId] = useState(0);
    const dispatch = useDispatch();
    let selectedUpdate;


    const handleOpen = (updates,index) => {
        setSlectedContent(updates);
        setSlectedUpdateId(index);
    }

    useEffect(()=>{
        if(Updates){ setSlectedContent(Updates[slectedUpdateId])}
    },[Updates])
    
    if(Updates){selectedUpdate = (slectedContent !==null ? slectedContent : Updates[0] );}

    const handleEdit = (item) =>{
        dispatch(utilityActions.toggleSideSheet({
            direction:'right',
            width:800,
            title:'Edit Update',
            open:true,
            component:<SupplimentaryPanel name={'update'} mode={'Edit'} data={item} />
        }))
    }

    return (
        <div className='update-continer'>
        {!Updates?.length ? <p className='no-data'>Currently there is no updates available...</p> : <div className="row">
            <div className="col-sm-4">
                <ul className='updates-list'>
                    {
                        Updates?.map((item, index) => {
                            let slectedUpdate = `${index === slectedUpdateId && 'active-update'} updates`;
                            let updateId = `course-update-id-${item?._id}`
                            return (<li key={index} className={slectedUpdate} id={updateId} onClick={() => handleOpen(item,index)}>
                                <div className='list-heading'> <p>{item?.content?.title}</p> </div>
                            </li>)
                        })
                    }
                </ul>
            </div>
            <div className="col-sm-8">
                {selectedUpdate && 
                <div className='slected-cntent'>
                    <div className='list-heading'><p className='heading'>{selectedUpdate?.content?.title}</p> <p className='edit-action' onClick={()=>handleEdit(selectedUpdate)}><i className="fa fa-pencil" aria-hidden="true"></i>Edit</p> </div>
                    <div dangerouslySetInnerHTML={{ __html: selectedUpdate?.content?.description }}  ></div>
                </div>}
            </div>
        </div>}
        </div>
    )
}

export default Updates