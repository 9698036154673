import React,{useEffect, useState, useMemo, useRef} from "react";
import ReactECharts from "echarts-for-react";
import {
  optionGenerateChartInteractions,
  optionGeneratePieChartInteractions,
  optionGenerateRadarChart,
} from "./CommonGraph/EchartOptionGenerate";
import * as utilityActions from '../../redux/actions/utilityActions'
import { useDispatch, useSelector } from "react-redux";

const StatisticsInfoChartsRender = ({ filteredData, select, clientId, clientCoursesData, minuteData }) => {
  const [graphData,setGraphData]=useState([])
  const gridRadarRef = useRef(null)
  const {dateFrom, dateTo} = useSelector((state) => state.user.statisticTab);
  const returnNumberSuffix = (num) => {
    try {
      const parsedNum = parseInt(num, 10);
      if (isNaN(parsedNum)) {
        throw new Error("Invalid input. Please provide a valid number.");
      }

      const lastTwoDigits = parsedNum % 100;
      const lastDigit = parsedNum % 10;

      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return "th";
      }

      switch (lastDigit) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    } catch (error) {
      console.error(error.message);
      return "";
    }
  };
  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  const userInteractionGraphTitle = (select) => {
    if (select?.dateSel.length !== 0) {
      const formattedDate = `${select.dateSel}${returnNumberSuffix(
        select.dateSel
      )} ${getMonthName(select.monthSel)} ${select.yearSel}`;
      return `${formattedDate} houly Interactions`;
    } else if (select?.weekSel.length !== 0) {
      const weekNum = select.weekSel.split("-")[1];
      const formattedDate = `${weekNum}${returnNumberSuffix(
        weekNum
      )} Week of ${getMonthName(select.monthSel)} ${select.yearSel}`;
      return `${formattedDate} daily Interactions`;
    } else if (select?.monthSel.length !== 0) {
      const formattedDate = `${getMonthName(select.monthSel)} ${
        select.yearSel
      }`;
      return `${formattedDate} daily Interactions`;
    } else if (select?.yearSel.length !== 0) {
      const formattedDate = `${select.yearSel}`;
      return `${formattedDate} monthly Interactions`;
    } else {
      return "Interactions";
    }
  };

  function getMonthName(monthNumber) {
    // array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Validating the month number
    if (monthNumber < 1 || monthNumber > 12) {
      throw new Error(
        "Invalid month number. Month number must be between 1 and 12."
      );
    }

    // Return the corresponding month name
    return monthNames[monthNumber - 1];
  }
  const datStr = (dt) => (String(dt).length === 1 ? `0${dt}` : String(dt));

  function getMonthNumbersBetween(fromDate, toDate, year) {
    const monthNumbers = [];
    const start = new Date(fromDate);
    const end = new Date(toDate);

    // Normalize to the first day of the month
    start.setDate(1);
    end.setDate(1);

    // Loop through the months
    while (start <= end) {
        monthNumbers.push(start.getMonth() + 1); // getMonth() returns 0-11, so we add 1
        start.setMonth(start.getMonth() + 1);
    }
    const from = new Date(fromDate)
  if(from.getMonth() >= 9 && from.getMonth() <= 11){
    return from.getFullYear() == year ? ["09", "10", "11", "12"] : ["01", "02", "03", "04"] 
  }
    return monthNumbers;
}
  
  const returnradaroptions = () => {
    try {
      const options = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "item",
          position: function (point, params, dom) {
            const topPosition = "5%";
            if (gridRadarRef?.current) {
              const chartWidth = gridRadarRef.current.offsetWidth;
              const tooltipWidth = dom.offsetWidth; // Get the width of the tooltip

              // If cursor is on the left side, position tooltip to the right
              if (point[0] < chartWidth / 2) {
                const rightPosition =
                  Math.min(chartWidth - tooltipWidth, chartWidth * 0.95) - 10;
                return [rightPosition, topPosition]; // Position near the right edge
              } else {
                // If cursor is on the right side, position tooltip to the left
                const leftPosition = "2%";
                return [leftPosition, topPosition]; // Position near the left edge
              }
            }
            return ["50%", topPosition]; // Default to center if not determined
          },
        },
      };

      let radarData = [];
      let indicator = [];
      const { yearSel, dateSel, monthSel, weekSel } = select || {};

      if (dateSel) {
        // const maxVal = filteredData?.[yearSel]?.months?.[monthSel]?.maxVisual
        const dateSelected =
          filteredData?.[yearSel]?.months?.[monthSel]?.dates?.[dateSel]?.hours;
        const hours = Array.from({ length: 24 }, (_, ind) => ind + 1);
        const maxVal = hours.reduce(
          (acc, hr) =>
            acc < (dateSelected?.[datStr(hr - 1)]?.count || 0)
              ? dateSelected?.[datStr(hr - 1)]?.count
              : acc,
          0
        );
        indicator = hours.map((hr, idx) => ({
          name: datStr(hr),
          max: maxVal || 100,
        }));
        radarData = hours.map((hr) =>
          dateSelected?.[datStr(hr - 1)]
            ? dateSelected?.[datStr(hr - 1)]?.count || 0
            : 0
        );
      } else if (weekSel) {
        const weekC = weekSel.split("-")[1];
        // prettier-ignore
        let dataY = [
                  ...Array(7)
                  
                ].map((_, i)=> {
                 return(weekC - 1)*7 + i + 1
                } )

        if (dataY[6] > 31) {
          dataY = dataY.slice(0, 3);
        }
        // const maxVal = filteredData?.[yearSel]?.months?.maxVisual
        const dateSelected = filteredData?.[yearSel]?.months?.[monthSel]?.dates;
        const maxVal = dataY.reduce(
          (acc, dt) =>
            acc < (dateSelected?.[datStr(dt)]?.count || 0)
              ? dateSelected?.[datStr(dt)]?.count
              : acc,
          0
        );
        radarData = dataY.map((dt) =>
          dateSelected?.[datStr(dt)]
            ? dateSelected?.[datStr(dt)]?.count || 0
            : 0
        );
        indicator = dataY.map((dt, idx) => ({
          name: datStr(dt),
          max: maxVal || 100,
        }));
      } else if (monthSel) {
        const dataY = new Array(
          new Date(yearSel, Number(monthSel), 0).getDate()
        )
          .fill(0)
          .map((_, idx) => idx + 1);
        const monthSelected =
          filteredData?.[yearSel]?.months?.[monthSel]?.dates;
        const maxVal = filteredData?.[yearSel]?.months?.[monthSel]?.maxVisual;
        radarData = dataY.map((dt) =>
          monthSelected?.[datStr(dt)]
            ? monthSelected?.[datStr(dt)]?.count || 0
            : 0
        );
        indicator = dataY.map((dt, idx) => ({
          name: datStr(dt),
          max: maxVal || 100,
        }));
      } else if (yearSel) {
        let dataY
        if(dateFrom && dateTo){
          dataY = getMonthNumbersBetween(dateFrom, dateTo, yearSel)
        }
        else{

          dataY = new Array(12).fill(0).map((_, idx) => idx + 1);
        }
        const yearSelected = filteredData?.[yearSel]?.months;
        // const maxVal = filteredData?.[yearSel]?.maxVisual
        const maxVal = dataY.reduce(
          (acc, mth) =>
            acc < (yearSelected?.[datStr(mth)]?.count || 0)
              ? yearSelected?.[datStr(mth)]?.count
              : acc,
          0
        );
        radarData = dataY.map((mth) =>{
          return yearSelected?.[datStr(mth)]
            ? yearSelected?.[datStr(mth)]?.count || 0
            : 0
        });
        indicator = dataY.map((mth, idx) => ({
          name: getMonthName(datStr(mth)),
          max: maxVal || 100,
        }));
      }
      if (radarData.length && indicator.length) {
        options["radar"] = {
          indicator: [indicator[0], ...indicator.slice(1).reverse()],
        };
        options["series"] = [
          {
            name: "Data 1",
            type: "radar",
            areaStyle: {},
            data: [
              {
                value: [radarData[0] ,...radarData.slice(1).reverse()],
                name: userInteractionGraphTitle(select),
              },
            ],
          },
        ];
        options["legend"] = {
          data: [userInteractionGraphTitle(select)],
        };
        options["tooltip"] = {
          formatter: function (params) {
            let tooltipHtml = `<div style="font-size:14px;color:#666;font-weight:400;line-height:1;">${params?.name || ""}</div><div style="margin: 10px 0 0;line-height:1;">`
            indicator.forEach((item, idx) => {
              tooltipHtml+=`<div style="margin: 4px 0 0;line-height:1;"><span style="display:inline-block;vertical-align:middle;margin-right:8px;margin-left:3px;border-radius:4px;width:4px;height:4px;background-color:#5470c6;"></span><span style="font-size:14px;color:#666;font-weight:400;margin-left:2px">${item.name}</span><span style="float:right;margin-left:20px;font-size:14px;color:#666;font-weight:900">${formatNumber(radarData[idx])}</span><div style="clear:both"></div></div><div style="clear:both"></div>`
            })
            tooltipHtml+=`</div>`
            return tooltipHtml;
          },
        }
      }

      return options;
    } catch (err) {
      console.error(err);
      return {};
    }
  };

  const getGraphdata=(select,data)=>{
    if(select?.dateSel){
      const yearData =data?.[select?.yearSel]
      const monthData= yearData?.months?.[select?.monthSel]
      const dateData= monthData?.dates?.[select?.dateSel].data
      setGraphData(dateData)
    }
    else if(select?.weekSel){
      const { weekSel } = select;
      const year = select.yearSel
      const month= select.monthSel
      const weekC = weekSel.split("-")[1];
      let date2 = weekC*7
      let date1=(weekC-1)*7+1<31?(weekC-1)*7+1:""
      const weekArrstats= []
      for(let i = date1;i<=date2;i++){
        i=i<10?`0${i}`:i
        if(data[year]?.months?.[month]?.dates?.[i]){
            const dateinWeek=data[year]?.months?.[month]?.dates?.[i].data
          weekArrstats.push(...dateinWeek)
        }
         setGraphData(weekArrstats)
      }
    }
    else if(select?.monthSel){

      const array=[]
      const yearData =data?.[select?.yearSel]
      const monthData= yearData?.months?.[select?.monthSel]
      if(monthData){
       
       Object.keys(monthData?.dates).map(date=>{
         array.push(...monthData?.dates?.[date]?.data)
       })
      
      }
         setGraphData(array)
    }
    else if(select?.yearSel){
      const array=[]
      const yearData =data?.[select?.yearSel]
      if(yearData?.months){
        Object.keys(yearData?.months).map(item=>{
       Object.keys(yearData?.months?.[item].dates).map(value=>{
         
         array.push(...yearData?.months?.[item].dates?.[value]?.data)
       })
       })
      }
      setGraphData(array)
    }
    else{
      setGraphData([])
    }

  }
  const getActionsChartData = (data) => {
    const allActions={}
    
    data.forEach(item=>{ 
      const actionType = item?.action
      if(actionType.toLowerCase() === 'interacted'){
        const key = item?.stmt.split(" ")[2] 

        if(allActions?.[key]){
          allActions[key]++
        }
        else{
          allActions[key] = 1
        }
      }
    })
    const actionsLineChartData=Object.entries(allActions).map(item=>{
      return item
    })
    
    return actionsLineChartData
  }

  const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getActivityChartData = (data) => {
    const activityObj= {
      'interacted': 'Interacted',
      'experienced' : 'Experienced',
      'played' : 'Video Played',
      'paused' : 'Video Paused',
      'launched' : 'Launched',
      'cc-subtitle-enabled' : 'CC Subtitle Enabled',

    }
    const allActivities={}
    // data.forEach(item => {
    //   const [courseid, topicid] = item.key.split(":").slice(3,5)
    //   const key = topicid
    //   if(allActivities?.[key]){
    //     allActivities[key]++
    //   }
    //   else{
    //     allActivities[key] = 1
    //   }
    // })
    data.forEach(item=>{ 
      const key = item?.action
      if(allActivities?.[key]){
        allActivities[key]++
      }
      else{
        allActivities[key] = 1
      }
    })
    const activityLineChartData=Object.entries(allActivities).map(item=>{
      return [activityObj?.[item[0]] || capitalize(String(item[0])), item[1]] 
    })
   
    return activityLineChartData
  }
  const getUserChartData = (data) => {
    const allUsers={}
    data.forEach(item=>{
      if(allUsers?.[item.name]){
        allUsers[item.name]++
      }
      else{
        allUsers[item.name] = 1
      }
    })
    const userLineChartData=Object.entries(allUsers).map(item=>{
      return item
    })
    
    return userLineChartData
  }

  const colorPallete = [
    [148.21, 100, 0.3294], 
      [199.67,95.81, 37.45],
      [235.96, 76.07, 22.94],
      [32.61, 86.79, 58.43],
      [356.69, 86.6, 59.02],
      [193.33, 62.9, 28.04],
      [211.43, 43.57, 52.75],
      [71.65, 61.78, 55.88]
  ]
  function getRandomNumber(upto) {
    return Math.floor(Math.random() * upto); // Generates a random integer from 0 to 7
  }
  const turnToHsl = (hslArr) => {
    return `hsl(${hslArr[0]}, ${hslArr[1]}%, ${hslArr[2]}%)`
  }
  const restrictBetween = (from, to, num) => {
    if(from > num)
      return num + from
    if(to < num)
      return num- (100-to)
    return num
  }
  function generateColors(color, steps) {
    const baseHSL = color[getRandomNumber(8)];
    const i = getRandomNumber(steps) + 1;
    const j = getRandomNumber(steps) + 1;
    // const lightness = Math.floor((i + 1) * (100 / (steps + 1))) + 1;
    // const greyness = Math.floor((j + 1) * (100 / (steps + 1))) + 1;
    const lightness = restrictBetween(10, 80,getRandomNumber(100)+1)
    const greyness = restrictBetween(20, 80,getRandomNumber(100)+1)
    // const colorGenerated = [baseHSL[0], baseHSL[1], restrictBetween(20, 80, lightness)];
    const colorGenerated = [baseHSL[0], greyness, lightness];
    return turnToHsl(colorGenerated);
  }

  const getPiChartData=(data)=>{
    const clientCourses=clientCoursesData?.[clientId]?.courses
    // const coursesId=Object.keys(clientCourses)
    const allCourses={}
  
    data.forEach(item=>{
      const courseid = item.key.split(":")[4]
       if(allCourses?.[courseid]){
        allCourses[courseid]++
       }
       else{
          allCourses[courseid]=1
       }
    })
    const piChartData=[]
    Object.entries(allCourses).forEach(item=>{
      if(clientCourses?.[item[0]]){
        const color = generateColors(colorPallete, 1000)
            piChartData.push({
              name:clientCourses[item[0]],
              value:item[1],
              itemStyle:{color: color}
            })
          }
          
    })
    
    return piChartData
    
  }


  
    const pieChartData = useMemo(()=> getPiChartData(graphData), [graphData])
  
 
  useEffect(()=>{
    getGraphdata(select,filteredData)
  },[select])
  
  useEffect(()=>{
    if(minuteData?.length !== 0){

      setGraphData(minuteData)
    }
  },[minuteData])

  // useEffect(()=>{
  //   if(graphData.length){
  //     getPiChartData(graphData)
  //   }
  // },[graphData])

  const viewMoreText = (
    <>
      Show More <i class="fa fa-arrow-right" aria-hidden="true"></i>
    </>
  );
  const list = [1, 2, 3, 4];
  return (
    <div className="statistic-info-grid-container">
      <div className="statistic-info-grid">
        <div className="grid-col grid-col-1">
        <div className="grid-item" ref={gridRadarRef}>
          <GraphContainer
            header={"Interactions"}
            options={returnradaroptions()}
            cstClass={'radar-container'}
            echartOptions={optionGenerateRadarChart}
          />
        </div>
        <div className="grid-item">
          <GraphContainer
            header={"Courses"}
            echartOptions={optionGeneratePieChartInteractions}
            data={pieChartData}
          />
        </div>
<div className="grid-item">
          <GraphContainer
            header={"Actions"}
            shortInfo={"Users Actions"}
            list={list}
            listType={1}
            growthBox={true}
            viewMore={viewMoreText}
            options={{}}
            echartOptions={optionGenerateChartInteractions}
            data={getActionsChartData(graphData)}
          />
        </div>
        </div>
        <div className="grid-col grid-col-2">
        <div className="grid-item">
          <GraphContainer
            header={"Users"}
            shortInfo={"Users Interacted"}
            list={true}
            listType={2}
            growthBox={true}
            viewMore={viewMoreText}
            options={{
              lineStyleColor:'rgba(170, 142, 57)',
              itemStyleColor:'rgba(170, 142, 57)',
              areaStyleColor:'rgba(170, 142, 57, 0.7)',
              
            }}
            echartOptions={optionGenerateChartInteractions}
            data={getUserChartData(graphData)}
          />
        </div>
        
        
        <div className="grid-item">
          <GraphContainer
            header={"Activities"}
            shortInfo={"Users Activities"}
            list={list}
            listType={1}
            growthBox={true}
            viewMore={viewMoreText}
            options={{
              lineStyleColor:'rgba(112, 112, 112)',
              itemStyleColor:'rgba(112, 112, 112)',
              areaStyleColor:'rgba(112, 112, 112, 0.7)',
            }}
            echartOptions={optionGenerateChartInteractions}
            data={getActivityChartData(graphData)}
          />
        </div>
        </div>
      
        
        
        
      </div>
    </div>
  );
};

// data prop should be in this format for list [[x1,y1],[x2,y2],...]

const GraphContainer = ({
  header,
  shortInfo,
  growthBox,
  viewMore,
  chartHeight,
  list,
  cstClass,
  echartOptions,
  listType,
  options,
  data
}) => {

  const dispatch = useDispatch()

  let sortedList = []
  if(list && data){
   
    sortedList=[...data]

    sortedList.sort((a,b) => {
      return b[1] - a[1]
    })
    
    
  }

  const SideSheetDisplay = ({data,ListItem}) => {
    return <div className="sidesheet-viewmore-data-container">
      <div className="list">
        {data?.map((item,i) => <ListItem item={item} key={i+1}/>)}
      </div>
    </div>
  }

  const onClickViewMore = (title, data, ListItem) => (e) => {
    
      dispatch(utilityActions.toggleSideSheet({
        width: 700,
        direction: 'right',
        title: title,
        open: true,
        component: <SideSheetDisplay data={data} ListItem={ListItem}/>
    }))
   
  }

  const ListItem = ({item}) => {
        return <div className="d-flex list-item">
        <div className="counts-box">
        <span style={{color:options?.lineStyleColor||"#7c8cc2"}}>{item[1]}</span>
        </div>
        <p className="mb-0 comment">{item[0]}</p>
      </div>
  }
  const ListItemUser = ({item}) => {
    return  <div className="d-flex list-item">
    <div className="user-icon">
    <i class="fa fa-user-circle-o" aria-hidden="true"></i>

    </div>
    <div className="user-name-inter">
    <p className="mb-0 name">{item[0]}</p>
    <p className="mb-0 interaction">Interactions: {item[1]}</p>
    </div>
  </div>
  }
  
  return (
    <div className={`graph-container-common ${ cstClass ? cstClass : "" }`}>
      <h5 className="header mb-0">{header}</h5>
      {growthBox && sortedList.length!==0 && (
        <div className="growth-box-details">
          <p className="growth-num mb-0">{sortedList?.[0]?.[1]}</p>
          <p className="growth-percentage mb-0">
            {/* <div className="arrow-icon">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
            </div>{" "} */}
            {/* <span className="percentage">29%</span> from previous{" "} */}
            {/* <span className="days">30</span> days */}
           
            {sortedList?.[0][0] === 'paused' || sortedList?.[0][0] === 'played'? `Video ${sortedList?.[0][0]}`  : sortedList?.[0][0]}
          </p>
        </div>
      )}
      <div className={`graph-wrapper ${!growthBox ? "without-growth" : ""}`}>
        <ReactECharts
          style={{ height: chartHeight || "100%", width: "100%" }}
          option={echartOptions(options, data)}
        />
      </div>
      {shortInfo && (
        <div className="short-info">
          <p className="mb-0">{shortInfo}</p>
        </div>
      )}
      {list && (
        <div className="list">
          {listType === 1
            ? sortedList
                .slice(0, 6)
                .map((item, i) => <ListItem item={item} key={i + 1} />)
            : sortedList
                .slice(0, 4)
                .map((item, i) => <ListItemUser item={item} key={i + 1} />)}
        </div>
      )}

      {viewMore && listType === 1 && sortedList.length>6 && <div onClick={onClickViewMore(shortInfo, sortedList.slice(6), ListItem)} className="view-more-box"><span>{viewMore}</span></div>}
      {viewMore && listType === 2 && sortedList.length>4 &&<div onClick={onClickViewMore(shortInfo, sortedList.slice(4), ListItemUser)} className="view-more-box"><span>{viewMore}</span></div>}
    </div>
  );
};

export default StatisticsInfoChartsRender;
