import React, { useEffect, useState } from 'react'
import APIConstant from '../../utility/APIConstant';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import './CourseEdit.scss'
import * as userActions from '../../redux/actions/userActions'
import * as utilityActions from '../../redux/actions/utilityActions'
import { useToasts } from 'react-toast-notifications';

const CourseEdit = ( { data } ) => {

    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const [ inputValue, setSearchInputValue ] = useState( '' );
    const [ users, setFilteredUsers ] = useState( [] );
    const [ selectedUser, setUserSelected ] = useState( '' )
    const [ message, setAssignedMessage ] = useState( '' )
    const userInfo = useSelector( state => state.user );
    const [ assignedUsers, setAssignedUsers ] = useState( [] );
    const [checked, setChecked] = useState([]);
    const [allUsersList, setAllUsersList] = useState([]);
    const [totalAssignUsers, setTotalAssignUsers] = useState([]);

    const [assignedUsersList, setAssignedUsersList] = useState([]);
    const [equal, setEqual] = useState(true)
    const not = (a, b) => {
      return a.filter((value) => b.indexOf(value) === -1);
    };
  
    const intersection = (a, b) => {
      return a.filter((value) => b.indexOf(value) !== -1);
    };
  
    const union = (a, b) => {
      return [...a, ...not(b, a)];
    };
    const leftChecked = intersection(checked, allUsersList);
    const rightChecked = intersection(checked, assignedUsersList);
 
    const handleToggle = (value) => () => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];
  
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
  
      setChecked(newChecked);
    };
  
    const numberOfChecked = (items) => intersection(checked, items).length;
  
    const handleToggleAll = (items) => () => {
      if (numberOfChecked(items) === items?.length) {
        setChecked(not(checked, items));
      } else {
        setChecked(union(checked, items));
      }
    };
  
    const handleCheckedRight = () => {
      setAssignedUsersList(assignedUsersList.concat(leftChecked));
      setAllUsersList(not(allUsersList, leftChecked));
      setChecked(not(checked, leftChecked));
      setTotalAssignUsers(leftChecked)
      setEqual(false)
    };

   
  
    const handleCheckedLeft = () => {
      setAllUsersList(allUsersList.concat(rightChecked));
      setAssignedUsersList(not(assignedUsersList, rightChecked));
      setChecked(not(checked, rightChecked));
      setEqual(true)
    };
  
    const customList = (title, items) => (
      <Card>
        <CardHeader
          sx={{ px: 2, py: 1 }}
          avatar={
            <Checkbox
              onClick={handleToggleAll(items)}
              checked={
                numberOfChecked(items) === items?.length && items?.length !== 0
              }
              indeterminate={
                numberOfChecked(items) !== items?.length &&
                numberOfChecked(items) !== 0
              }
              disabled={items?.length === 0}
              inputProps={{
                "aria-label": "all items selected"
              }}
            />
          }
          title={title}
          subheader={`${numberOfChecked(items)}/${items?.length} selected`}
        />
        <Divider />
        <List
          sx={{
            width: 200,
            height: 230,
            bgcolor: "background.paper",
            overflow: "auto"
          }}
          dense
          component="div"
          role="list"
        >
          {items?.map((value) => {
            const labelId = `transfer-list-all-item-${value
            }-label`;
  
            return (
              <ListItem
                key={value}
                role="listitem"
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      "aria-labelledby": labelId
                    }}
                  />
                </ListItemIcon>
             <ListItemText id={labelId} primary={value?.firstname ? value?.firstname : value?.user?.firstname} /> 
              </ListItem>
            );
          })}
        </List>
      </Card>
    );


    useEffect( () => {
        let users = []
        if ( userInfo.allUsers.length > 0 ) {
            userInfo.allUsers.forEach( ( item ) => {
                const found = item.courses.filter( ( cr ) => {
                    return cr.coursename === data?.coursename
                } );
                if ( found.length > 0 ) {
                    users.push( item?.user )
                }
            } );
            setAssignedUsers( [ ...users ] );
            setAssignedUsersList(assignedUsers);
        }
    }, [ userInfo?.allUsers ] )

    useEffect(()=>{
    setAllUsersList(userInfo?.allUsers)   
    },[])

  useEffect( () => {
   if(assignedUsers?.length > 0){
   setAssignedUsersList(assignedUsers);
   }
 
    var filteredUsersList = userInfo?.allUsers?.filter(val =>{
    
      let assignedUsersIds = assignedUsers.map((item)=>{
          return item._id
 
      })
      return !assignedUsersIds.includes(val?.user?._id
        )
  
    })
    setAllUsersList(filteredUsersList)
}, [assignedUsers] )


    const handleInputChange = async ( e ) => {
        try {
            await setSearchInputValue( e.target.value );
            if ( inputValue.length > 1 ) {
                let converted = inputValue.toLowerCase();
                const items = userInfo?.allUsers.filter( ( item ) => item?.user?.firstname?.toLowerCase().includes( converted )
                    || item?.user?.lastname?.toLowerCase().includes( converted ) || item?.user?.email?.toLowerCase().includes( converted ) ).map( ( cr, index ) => {
                        return cr;
                    } );
                setFilteredUsers( items );
            } else if ( inputValue.length === 1 ) {
                setFilteredUsers( [] );
            }


        } catch ( e ) {
            console.log( e );
        }
    }
    const assignUserForCourse=async()=>{
      const totalIds=totalAssignUsers?.map((ele)=>{
        return ele?.user?._id
      })

      let assignUserObj={
        "userids":totalIds,
        "courseid":data._id,
        "flag":"user",
        "assignedby": userInfo?.loggedInUser?._id
      }
   dispatch( userActions.userCourseAssignmentRequest( assignUserObj ) );

      addToast( "Course Assigned Successfully", {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-right",
    } );
    setTimeout( () => {
      dispatch( utilityActions.toggleSideSheet( {
          direction: 'left',
          title: '',
          open: false
      } ) );
  }, 300 )


    }
    const assignCourse = () => {

        setFilteredUsers( [] );
        const found = userInfo?.allUsers.filter( ( item ) => {
            return item?.user?._id === selectedUser._id
        } );

        if ( found.length > 0 ) {
            if ( !isItemFound( found[ 0 ]?.courses, data?.coursename ) ) {
                found[ 0 ]?.courses.push( { _id: data?._id, coursename: data?.coursename, status: data?.status } );
                let crsIds = []
                found[ 0 ]?.courses.forEach( ( el ) => {
                    if ( crsIds.indexOf( el._id ) === -1 ) {
                        crsIds.push( el._id )
                    }
                } )

                let assignObj = {
                    "userid": selectedUser._id,
                    "assignedcourses": crsIds,
                    "assignedby": userInfo?.loggedInUser?._id
                }
                dispatch( userActions.courseAssignmentRequest( assignObj ) );

                addToast( "Course Assigned Successfully", {
                    appearance: "success",
                    autoDismiss: true,
                    placement: "bottom-center",
                } );

                setTimeout( () => {
                    dispatch( utilityActions.toggleSideSheet( {
                        direction: 'left',
                        title: '',
                        open: false
                    } ) );
                }, 300 )
            } else {
                setAssignedMessage( 'Course already assigned to this user ' )
            }
        }
    }

    const isItemFound = ( courses, coursename ) => {
        const found = courses.filter( ( cr ) => {
            return cr?.coursename === coursename
        } )

        if ( found.length > 0 ) {
            return true
        }
        return false;
    }

    return <div>
        <div className='row'>
            <div className='col-lg-8 col-md-6 col-sm-12'>
                <div>
                    <label className='label-text'>Module Name</label>
                </div>
                <p className='text-bold'>{ data?.coursename }
                </p>
            </div>
            <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                    <label className='label-text'>Language</label>
                </div>
                <p className='text-bold'>{ data?.language }</p>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-8 col-md-4 col-sm-12'>
                <div>
                    <label className='label-text'>Course Category</label>
                </div>
                <p className='text-bold'>{ APIConstant.CATEGORY[ data?.coursecategory ]?.name }</p>
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12'>
               
            </div>
        </div>

        { selectedUser !== '' && <h6 className='message'>{ message }</h6> }

       
        <div className='row mT20'>
        <div className='col-lg-12 col-md-12 col-sm-12'>
            <div className='assign-users'>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">

      <Grid item>{customList("Users List", allUsersList)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            <i class="fa fa-chevron-right"></i>
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <i class="fa fa-chevron-left"></i>

          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Assigned Users", assignedUsersList)}</Grid>
    </Grid>
            </div>
            <div className='assigned-user'>
              <button 
              disabled={equal}
                        className={ equal ? 'btn-disabled' : 'add-btn' }
                        onClick={ () => assignUserForCourse() }
                    >Assign</button>
            </div>
          
        </div>
        </div>
        <div className='row mT20'>
            <div className='col-lg-6 col-md-6 col-sm-12'>
                <div className='flex-item'>

                    <TextField label="Search User" id="outlined-size-small"
                        InputLabelProps={ { shrink: true } } fullWidth
                        inputProps={ { maxLength: 50 } }
                        value={ inputValue } onChange={ handleInputChange }
                        size="small" />

                    <button disabled={ selectedUser === '' || inputValue =='' }
                        className={ selectedUser === '' ? 'btn-disabled' : 'add-btn' }
                        onClick={ () => assignCourse() }
                    >Assign1</button>
                </div>

                { users.length > 0 && <ul className='users-list'>
                    {
                        users.map( ( item ) => {
                            return <li key={ item?.user?._id }
                                className={ item?.user?._id === selectedUser?._id ? 'active-item' : 'list-item' }
                                onClick={ () => setUserSelected( item?.user ) }>
                                { item?.user?.firstname } - { item?.user?.email }
                            </li>
                        } )
                    }
                </ul> }
            </div>
            <div className='col-lg-1 col-md-1 col-sm-12'></div>
           
        </div>
        <div className='row mT20'> 
        <div className='col-lg-12 col-md-12 col-sm-12'>
            <label className='label-text'>Assigned users</label>
                <ul className='assigned-users'>
                    { assignedUsers.length > 0 && assignedUsers.map( ( user,index ) => {
                        return <li key={index}>{ user.firstname } - <span className='text-bold'>{ APIConstant.ROLES[ user?.role ]?.name }</span></li>
                    } ) }
                </ul>
                {assignedUsers.length === 0 && <p>No users assigned...</p>}
            </div>
        </div>
    </div>
}

export default CourseEdit