import React, { useState, useEffect } from 'react';
import './Notes.scss';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions';
import * as userActions from '../../redux/actions/userActions';
import { useToasts } from 'react-toast-notifications';
import CeAssets from '../../assets/img/CeAssets.png';
import APIConstant from '../../utility/APIConstant';

const Notes = () => {
    const userInfo = useSelector(state => state?.user);
    const utility = useSelector(state => state.utility);
    const { addToast } = useToasts();
    const Notes = userInfo?.supplementaries?.notes;
    const [selectedNotes, setSelectedNotes] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedId, setSelectedId] = useState(0);
    const dispatch = useDispatch();
    const courseId = utility?.selectedCourseUpdates?._id;

    // Load selected notes from sessionStorage on mount
    useEffect(() => {
        const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
        if (courseId && savedSelections[courseId]?.notes) {
            setSelectedIds(savedSelections[courseId].notes);
        }
    }, [courseId]);

    // Save selected notes to sessionStorage on change
    useEffect(() => {
        if (selectedIds.length > 0) {
            const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
        if (courseId) {
            savedSelections[courseId] = {
                ...savedSelections[courseId],
                notes: selectedIds
            };
            sessionStorage.setItem('vignetteSelections', JSON.stringify(savedSelections));
        }
    }
    }, [selectedIds, courseId]);

    const handleOpen = (item, id) => {
        setSelectedNotes(item);
        setSelectedId(id);
        handleSelect(item?._id); // Toggle selection when card is clicked
    };

    const handleSelect = (id) => {
        setSelectedIds((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter(itemId => itemId !== id); // Deselect
            }
            return [...prevSelected, id]; // Select
        });
    };

    const handleSelectAll = () => {
        if (selectedIds.length === Notes.length) {
            setSelectedIds([]); // Deselect all
            const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
            savedSelections[courseId] = {
            ...savedSelections[courseId],
            notes: []
        };
            sessionStorage.setItem('vignetteSelections', JSON.stringify(savedSelections));
        } else {
            setSelectedIds(Notes.map(note => note._id)); // Select all
        }
    };

    const handleSave = () => {
        let reqObj = {
            "configid": courseId,
            "vids": selectedIds,
            "type": 10
        };
        dispatch(userActions.addConfigVignettesRequest(reqObj, addToast));
        // dispatch(utilityActions.setSelectedSupplimentaryReq(utility?.selectedCourseUpdates, 1));
    };

    useEffect(() => {
        if (Notes) {
            setSelectedNotes(Notes[selectedId]);
        }
    }, [Notes, selectedId]);

    return (
        <div className='notes-continer'>
            {Notes?.length > 0 ? (
                <>
                    <div className="select-all-container">
                        <button
                            className="select-all-button"
                            onClick={handleSelectAll}
                        >
                            {selectedIds.length === Notes.length ? 'Deselect All' : 'Select All'}
                        </button>
                    </div>
                    <div className="row">
                        <div className="notes-list">
                            <div className="row">
                                {Notes?.map((item, idx) => {
                                    const isSelected = selectedIds.includes(item._id);
                                    const activeImageClass = `name-layer ${isSelected ? 'active-img' : ''}`;
                                    return (
                                        <div key={idx} className='col-lg-2 col-md-2 col-xs-2'>
                                            <div
                                                className="notes-card"
                                                onClick={() => handleOpen(item, idx)}
                                            >
                                                <div className='inner-content'>
                                                    <span className={activeImageClass}>{item?.content?.title}</span>
                                                    <img
                                                        src={APIConstant.getFilePath(item?.content?.path)}
                                                        alt="Note"
                                                        onError={(e) => {
                                                            e.target.onerror = null;
                                                            e.target.src = CeAssets;
                                                        }}
                                                    />
                                                    {isSelected && (
                                                        <div className="check-icon">✔</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="save-button-container">
                        <button
                            className="save-button"
                            disabled={selectedIds.length === 0}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </>
            ) : (
                <p className='no-data'>Currently there are no posters available...</p>
            )}
        </div>
    );
};

export default Notes;
