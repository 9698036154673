import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Drawer, RadioGroup, FormControlLabel, Radio, List, ListItem, ListItemText, ListItemSecondaryAction, TablePagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Accordion, AccordionSummary, AccordionDetails, Typography, Switch, Chip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import L from 'leaflet';
import 'leaflet.markercluster';
import APIConstant from '../../utility/APIConstant';
import MapMarkerIcon from '../../assets/img/map-pin.png';

const MapComponent = ({reset}) => {
  let users = useSelector(state => state.user.userCache);
  let activeUsers = useSelector(state => state.user.activeUserCache);
  let activeUser= users.filter(item=>{
    return activeUsers?.includes(JSON.stringify(item.uid))})
 

  const [map, setMap] = useState(null); // Map instance state
  const [selectedOption, setSelectedOption] = useState('browser'); // Selected radio option
  const [drawerOpen, setDrawerOpen] = useState(false); // Drawer state
  const [selectedUser, setSelectedUser] = useState(null); // Selected user state
  const [clusterUsers, setClusterUsers] = useState([...users]); // Users in the current cluster
  const [page, setPage] = useState(0); // Current page in pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Rows per page in pagination
  const [sortByCountryName, setSortByCountryName] = useState(true); // Sort by country name or count
  const clusterColors = ['#0250B4', '#002873', '#000A50', '#0178E6', '#444B4D'];
  const mapRef = useRef(null);

  useEffect(() => {
    const initMap = () => {
      if (mapRef.current) {
        mapRef.current.remove(); // Destroy the previous map instance if it exists
      }
      setSelectedUser(null)
      setClusterUsers([...users])

      const mapInstance = L.map('map', {
        center: [20, 0],
        zoom: 2,
        minZoom: 2,
        maxZoom: 5,
        attributionControl: false
      });

      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(mapInstance);

      setMap(mapInstance);
      mapRef.current = mapInstance;

      const markers = L.markerClusterGroup({
        iconCreateFunction: function (cluster) {
          const childCount = cluster.getChildCount();
          let c = ' marker-cluster-';
          if (childCount < 10) {
            c += 'small';
          } else if (childCount < 100) {
            c += 'medium';
          } else {
            c += 'large';
          }
      
          const colorIndex = Math.min(clusterColors.length - 1, Math.floor(childCount / 10)); // Adjust color based on cluster size
          const color = clusterColors[colorIndex];
      
          return new L.DivIcon({
            html: '<div><span>' + childCount + '</span></div>',
            className: 'marker-cluster' + c,
            iconSize: new L.Point(40, 40),
            iconColor: color // Set cluster color
          });
        }
      });

      markers.on('clusterclick', (e) => {
        const markersInCluster = e.layer.getAllChildMarkers();
        const usersInCluster = markersInCluster.map(marker => marker.user);
        setClusterUsers(usersInCluster);
      });

      mapInstance.addLayer(markers);

      users.forEach(user => {
        if (user.geo.coords.lat && user.geo.coords.long) {
          const marker = L.marker([user.geo.coords.lat, user.geo.coords.long], {
            icon: L.icon({
              iconUrl: MapMarkerIcon,
              iconSize: [35, 35]
            })
          });

          marker.bindPopup(
            `<div classname="tooltip">
            <div>Userid - ${user.uid}</div>
              <div>${user.geo.city}, ${user.geo.state}, ${user.geo.country}</div>
            </div>`
          );

          marker.on('click', () => {
            setSelectedUser(user);
            setClusterUsers([user]);
          });

          marker.user = user; // Store user in marker for cluster click access
          markers.addLayer(marker);
        }
      });

      // Handle marker click from outside the map
      window.handleMarkerClick = (uid) => {
        const user = users.find(u => u.uid === uid);
        setSelectedUser(user);
        setClusterUsers([user]);
      };
    };

    initMap();

    return () => {
      if (mapRef.current) {
        mapRef.current.remove();
        mapRef.current = null;
      }
    };
  }, [users,reset]);

  // Group users by browser or location
  const groupUsers = (option) => {
    const grouped = clusterUsers.reduce((acc, user) => {
      const key = option === 'browser' ? extractBrowserName(user.browser_info.name) : user.geo.country === "" ? 'Unknown Country' : APIConstant.COUNTRY_CODE[user.geo.country] || 'Unknown Country';
      if (!acc[key]) acc[key] = [];
      acc[key].push(user);
      return acc;
    }, {});

    if (sortByCountryName) {
      const sorted = Object.keys(grouped).sort().reduce((acc, key) => {
        acc[key] = grouped[key];
        return acc;
      }, {});
      return sorted;
    } else {
      const sortedByCount = Object.keys(grouped).sort((a, b) => grouped[b].length - grouped[a].length).reduce((acc, key) => {
        acc[key] = grouped[key];
        return acc;
      }, {});
      return sortedByCount;
    }
  };

  // Function to extract browser name from user agent string
  const extractBrowserName = (encodedStr) => {
    try {
      const decodedStr = decodeURIComponent(encodedStr);
      if (decodedStr.includes('OPR') || decodedStr.includes('Opera')) return 'Opera';
      if (decodedStr.includes('Edg')) return 'Edge';
      if (decodedStr.includes('Chrome') && !decodedStr.includes('Chromium') && !decodedStr.includes('Edg')) return 'Chrome';
      if (decodedStr.includes('Safari') && !decodedStr.includes('Chrome')) return 'Safari';
      if (decodedStr.includes('Firefox')) return 'Firefox';
      if (decodedStr.includes('MSIE') || decodedStr.includes('Trident')) return 'Internet Explorer';
      return 'Unknown Browser';
    } catch (e) {
      console.error('Error parsing user agent string:', e);
      return 'Unknown Browser';
    }
  };

  // Handle closing the drawer
  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedUser(null);
  };

  // Handle pagination page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle changing rows per page in pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const genrateActiveUsers=()=>{

    return <>
     <Accordion key={"Active User"}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{`Active User `}</Typography>
          <Chip label={`${activeUser.length}`} style={{ marginLeft: 'auto' }} />
        </AccordionSummary>
        <AccordionDetails>
            <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Browser</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { activeUser.length>0?activeUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                  <TableRow key={user.uid}>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell>{user.browser_info.ipaddress}</TableCell>
                    <TableCell>{extractBrowserName(user.browser_info.name)}</TableCell>
                    <TableCell>{`${user.geo.city}, ${user.geo.state}, ${user.geo.country}`}</TableCell>
                  </TableRow>
                )):<div>No Active Users currently</div>}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={activeUser.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </AccordionDetails>
          </Accordion>
    </>
  }

  // Render accordion content based on selected option
  const renderAccordionContent = () => {
    const groupedUsers = groupUsers(selectedOption);
    return Object.entries(groupedUsers).map(([key, value]) => (
      <Accordion key={key}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>{`${key} `}</Typography>
          <Chip label={`${value.length}`} style={{ marginLeft: 'auto' }} />
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>IP Address</TableCell>
                  <TableCell>Browser</TableCell>
                  <TableCell>Location</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {value.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(user => (
                  <TableRow key={user.uid}>
                    <TableCell>{user.uid}</TableCell>
                    <TableCell>{user.browser_info.ipaddress}</TableCell>
                    <TableCell>{extractBrowserName(user.browser_info.name)}</TableCell>
                    <TableCell>{`${user.geo.city}, ${user.geo.state}, ${user.geo.country}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component="div"
            count={value.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div className="container">
      <div className='map-holder'>
        <div className="left-pane">
          <div id="map" style={{ height: '100%', width: '100%' }}></div>
        </div>
      </div>
      {clusterUsers.length > 0 && (
        <div className="right-pane">
          <div className='allUsers'>
          <div className='switch-holder'>
            <div className='browser-country-holder switch'>
              <span>Location</span>
              <Switch
                checked={selectedOption === 'browser'}
                onChange={(e) => {
                  setSelectedOption(e.target.checked ? 'browser' : 'location');
                  setSortByCountryName(true);
                }}
                color="primary"
              />
              <span>Browser</span>&nbsp;<span>({selectedUser?1:users.length})</span>
            </div>
            <div className='country-sort-holder switch'>
              <span>Sort By Count</span>
              <Switch
                checked={sortByCountryName}
                onChange={() => setSortByCountryName(!sortByCountryName)}
                color="primary"
              />
              <span>Sort By Name</span>
            </div>
          </div>
          <List>
            {renderAccordionContent()}
          </List>
          </div>
          <div className='activeUsers'>
            {genrateActiveUsers()}
          </div>
        </div>
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <IconButton onClick={handleDrawerClose}>
          <CloseIcon />
        </IconButton>
        <List>
          {selectedUser && (
            <>
              <ListItem>
                <ListItemText primary="User ID" secondary={selectedUser.uid} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Browser" secondary={selectedUser.browser_info.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Location" secondary={`${selectedUser.geo.city}, ${selectedUser.geo.state}, ${selectedUser.geo.country}`} />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default MapComponent;
