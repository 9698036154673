import React from "react";
import "./DetailCard.scss";
import PropTypes from "prop-types";

export default function DetailCard({
  name,
  fname,
  lname,
  role,
  job_title,
  email,
  score,
  phone_number,
}) {
  // console.log(phone_number, "phone_number,");
  return (
    <div className="card-container">
      <div className="card-profile">
        <div className="image">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i>
        </div>
        <div className="name-role">
          <p className="name mb-0">
            {fname ? fname : name} {lname}
          </p>
          <p className="role mb-0">{role}</p>
        </div>
      </div>
      <div className="card-details">
        <div className="grid">
          <div className="grid-item ">
            <p className="head mb-0">Job Title</p>
            <p className="content mb-0">{job_title}</p>
          </div>
          <div className="grid-item">
            <p className="head mb-0">Email</p>
            <p className="content mb-0">{email}</p>
          </div>
          <div className="grid-item">
            <p className="head mb-0">Completion</p>
            <p className="content mb-0">{score}%</p>
          </div>
          <div className="grid-item">
            <p className="head mb-0">Phone Number</p>
            <p className="content mb-0">{phone_number}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

DetailCard.propTypes = {
  name: PropTypes.string,
  role: PropTypes.string,
  job_title: PropTypes.string,
  email: PropTypes.string,
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  phone_number: PropTypes.string,
};

DetailCard.defaultProps = {
  name: "NA",
  role: "NA",
  job_title: "NA",
  email: "NA",
  score: "NA",
  phone_number: "NA",
};
