import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CommentIcon from '@mui/icons-material/Comment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as utilityActions from '../../redux/actions/utilityActions'
import './topichistory.scss'
import APIConstant from '../../utility/APIConstant'
import ListItemText from '@mui/material/ListItemText';
import CourseComments from './CourseComments';


const TopicHistory = () => {


    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const open = Boolean(anchorEl);

    const [expanded, setExpanded] = useState(0);
    const userInfo = useSelector(state => state.user);
    const utilityInfo = useSelector(state => state.utility);

    const [topics, setTopicsInfo] = useState([])
    const [tpByStatus, setTpByStatus] = useState(APIConstant.STATUS_LRN_AIDS)
    const [isShowList, setStatusList] = useState(true)



    const handleClickListItem = (event) => {
        setAnchorEl(event.currentTarget);
        setStatusList(true)
    };

    const handleMenuItemClick = (event, index) => {
        index > 3 ? setSelectedIndex(-1) : setSelectedIndex(index);
        setAnchorEl(null);
        openAcc(null);
        setStatusList(false)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (userInfo.flathistory) {
            let activetps = []
            Object.keys(userInfo.flathistory).forEach(tp => {
                const tpc = userInfo.flathistory[tp];
                if (tpc.lrncontent.length > 0) {
                    activetps.push(tpc)
                    tpc.lrncontent.forEach(le => {
                        try {
                            if (!tpByStatus[le.status].topics) { tpByStatus[le.status].topics = {} }
                            if (!tpByStatus[le.status].topics[tpc.id]) { tpByStatus[le.status].topics[tpc.id] = { node: tpc.nodeId, title: tpc.title, list: [] } }
                            if (!isItemExist(tpByStatus[le.status].topics[tpc.id].list, le)) {
                                tpByStatus[le.status].topics[tpc.id].list.push(le)
                            };
                            setTpByStatus(tpByStatus);
                        }
                        catch (e) { console.log(e) }
                    })

                }
            })
            setTopicsInfo(activetps);
        }
    }, [Object.keys(userInfo?.flathistory)?.length]);


    const isItemExist = (list, item) => {
        try {
            const found = list.filter((el) => {
                return el._id === item._id && el.compid === item.compid
            });
            if (found.length > 0) {
                return true
            }
            return false
        } catch (e) {
            console.log(e);
        }
    }

    const renderLEStatus = () => {

        return <div>
            {(tpByStatus[selectedIndex]?.topics) ?
                Object.keys(tpByStatus[selectedIndex].topics).map(tpcs => {
                    return <Accordion key={tpcs} expanded={expanded === tpcs} onChange={() => openAcc(tpcs)} className="history-list">
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${tpcs}-content`}
                            id={`panel-${tpcs}-header`}
                        >
                            <Typography  >{tpByStatus[selectedIndex].topics[tpcs].node} - {tpByStatus[selectedIndex].topics[tpcs].title}</Typography>
                        </AccordionSummary>
                        <AccordionDetails className="history-list-lrn-continer">
                            <Typography component="div" className="history-list-lrn">
                                {tpByStatus[selectedIndex].topics[tpcs].list.length > 0 && renderLEItems(tpByStatus[selectedIndex]?.topics[tpcs])}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                })
                : <><h3>Loading....</h3></>
            }
        </div>

    }

    const handleStatusChange = (e, topic, lrn) => {
        try {
            lrn.status = Number(e.target.value);
        } catch (e) {
            console.log(e)
        }
    }


    const handleComment = (item) => {
        dispatch(utilityActions.toggleSideSheet({
            direction: 'right',
            width: 800,
            title: item?.name,
            open: true,
            component: <CourseComments commentsList={item?.logs} itemInfo={item} />
        }))
    }

    const renderLEItems = (topic) => {
        let lrnItems = topic.list;
        let today = new Date();
        return (<ul>
            {lrnItems.map((lrn, index) => {
                return <li key={index} className="flex-item">
                    <div className='lrnaid_col'>{lrn.name} </div>
                    <div>By  {lrn.author.lname} {lrn.author.fname}</div>

                    <select className="select-status"
                        defaultValue={lrn.status}
                        onChange={(e) => handleStatusChange(e, topic, lrn)}
                    >
                        {Object.keys(APIConstant.STATUS_LRN_AIDS).length > 0 && Object.keys(APIConstant.STATUS_LRN_AIDS).map((key) => {
                            return <option key={key} value={APIConstant.STATUS_LRN_AIDS[key].id} >
                                {APIConstant.STATUS_LRN_AIDS[key].status}
                            </option>
                        })}
                    </select>

                    <div>{today.toLocaleDateString("en-US", lrn.updatedAt)}</div>
                    <div onClick={() => handleComment(lrn)}><CommentIcon className='comment-icon' /></div>
                </li>
            })}
        </ul>)
    }




    const topicStatusList = () => {
        return (
            <>
                <div className='header-topic'>
                    <div onClick={handleClickListItem} className="tp-top-head">
                        <ListItemText primary="Status of learning elements" secondary={selectedIndex === -1 ? `Not-started` : Object.values(tpByStatus)[selectedIndex]?.status} />
                    </div>
                    {isShowList && <ul className='status-list'>
                        {Object.values(tpByStatus).length > 0 && Object.values(tpByStatus).map((option, index) => (
                            <li key={option.id}
                                className={index === selectedIndex ? 'status-selected' : 'list-item'}
                                onClick={(event) => handleMenuItemClick(event, index)}>{option.status}</li>
                        ))}
                    </ul>}
                </div>
            </>
        );
    }

    const openAcc = (el) => {
        if (expanded === el) {
            setExpanded(0)
        } else {
            setExpanded(el);
        }
    }

    const setbackMenu = () => {
        dispatch(utilityActions.setSelectedMenu(1))
    }

    return (
        <div className='log-history'>
            {utilityInfo?.selectedCourseUpdates?.coursename && <div className="sup-holder"> <div onClick={() => setbackMenu()}><ArrowBackIcon className='arrow-back' /></div>
                <div className="sup-cname"><p className="supplimentary_cname">{utilityInfo?.selectedCourseUpdates?.coursename}</p></div></div>}
            <br></br>
            {topicStatusList()}
            <br></br>
            {renderLEStatus()}
        </div>
    )
}




export default TopicHistory
