import React, { useState } from "react"
import { useToasts } from "react-toast-notifications";
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions'
import './SignUp.scss'

const SignUp = () => {
    const dispatch = useDispatch();
    const utilityInfo = useSelector(state => state.utility);
    const [showPassword, setShowPassword] = useState(false);
    const alphanumericRegExp = /^[A-Za-z0-9\s]+$/;
    const alphanumericRegExpNew = /^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[!@#$%^&*._-])[A-Za-z0-9\d!@#$%^&*._-]{6,30}$/;
    const [ initialValues, setInitialValues ] = useState( {
        user_name: '', first_name: '',
        last_name: '', email: '',
        password: '', role: ''
    } );

    const validationSchema = yup.object().shape( {
        user_name: yup.string().matches( alphanumericRegExp, 'FirstName is not valid' ).required( "Required" ),
        first_name: yup.string().matches( alphanumericRegExp, 'FirstName is not valid' ).required( "Required" ),
        last_name: yup.string().matches( alphanumericRegExp, 'LastName is not valid' ).required( "Required" ),
        email: yup.string().email( "Invalid email address" ).required( "Required" ),
        password: yup.string().matches( alphanumericRegExpNew, ' Password must have 6 characters that include atleast 1 character , 1 number and 1 special character in (!@#$%^&*)' ).required( "Required" ).min( 6, "Must be more than 6 characters" ),
        role: yup.string().matches( alphanumericRegExp, 'Designation is not valid' ).required( "Required" ),
    } );


    const onSubmit = (values) => {
        console.log(values)
    }

    const handleClickShowPassword = () => setShowPassword(!showPassword);


    return <section className="sign-in-container">
        <div className="container">
            <div className="row">
                <div className="pL0 col-lg-4 col-md-4 col-sm-12">
                    <div className="intro-section">
                            <h5>Join Content Enablers for free</h5>
                            <div className="divider"></div>
                            <p>Recognized as the most comprehensive online global trade compliance training solution</p>
                            <p>Difficulties with login ? Please contact us and we will be happy to help.</p>
                    </div>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12">
                    <Formik
                        initialValues={ initialValues }
                        onSubmit={ onSubmit }
                        validationSchema={validationSchema }
                        enableReinitialize={ true }
                    >
                        { (
                            { values, handleChange, errors, touched, handleSubmit } ) =>
                        (
                            <form className="signup-form" onSubmit={ handleSubmit }
                                onKeyDown={ ( e ) => {
                                    if ( e.key === 'Enter' || e.key == 'Unidentified' ) {
                                        handleSubmit();
                                    }
                                } }>
                                <div className="form-group">
                                    <label  htmlFor="username">User Name</label>
                                    <input type="text" onChange={ handleChange( 'user_name' ) } id="username" value={ values.user_name } label="User Name" className="form-control" maxLength="100" autoComplete="off" />
                                    <p className="error-msg">{ errors.user_name && touched.user_name && errors.user_name }</p>
                                </div>
                                <div className="form-group">
                                    <label  htmlFor="firstname">First Name</label>
                                    <input type="text" onChange={ handleChange( 'first_name' ) } id="firstname" value={ values.first_name } label="First Name" className="form-control" maxLength="100" autoComplete="off" />
                                    <p className="error-msg">{ errors.first_name && touched.first_name && errors.first_name }</p>
                                </div>
                                <div className="form-group">
                                    <label  htmlFor="lastname">Last Name</label>
                                    <input type="text" onChange={ handleChange( 'last_name' ) } id="lastname" value={ values.last_name } label="Last Name" className="form-control" maxLength="100" autoComplete="off" />
                                    <p className="error-msg">{ errors.last_name && touched.last_name && errors.last_name }</p>
                                </div>

                                <div className="form-group">
                                    <label  htmlFor="email">Email</label>
                                    <input type="email" onChange={ handleChange( 'email' ) } id="email" value={ values.email } label="Email" className="form-control" maxLength="150" autoComplete="off" />
                                    <p className="error-msg">{ errors.email && touched.email && errors.email }</p>
                                 </div>

                                 <div className="form-group">
                                    <label  htmlFor="password">Password</label>
                                    <input type="password" onChange={ handleChange( 'password' ) } id="password" value={ values.password } label="Password" className="form-control" maxLength="50" autoComplete="off" />
                                    <p className="error-msg">{ errors.password && touched.password && errors.password }</p>
                                </div>
                          
                                <button type="submit" className="btn btn-sign">SIGN UP</button>
                            </form>
                        ) }
                    </Formik>
                    <p className="sign-label">Already a member ? <label  onClick={ () => dispatch( utilityActions.openModal("sign-in") ) }>Sign in</label></p>
                </div>
            </div>
        </div>
    </section>
}
export default SignUp