import React from 'react';
import RichTextEditor from './RichTextEditor/RichTextEditor';
import TextField from '@mui/material/TextField';
import './SupplimentaryPanel.scss';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from '../../redux/actions/userActions';
import * as utilityActions from '../../redux/actions/utilityActions';
import * as yup from 'yup';
import { useToasts } from "react-toast-notifications";
import APIConstant from "../../utility/APIConstant";
import PropTypes from 'prop-types'

const SupplimentaryPanel = ({ name, mode, data }) => {

  const { addToast } = useToasts();
  // const category = APIConstant.CATEGORY;
  const dispatch = useDispatch();
  const userInfo = useSelector(state => state.user);
  const utility = useSelector(state => state.utility);


  const filledValues = {
    title: '',
    description: '',
    // category:'',
    path: '',
    thumb: ''
  };

  const editfilledValues = {
    title: data?.content?.title,
    description: data?.content?.description,
    // category:data?.content?.category,
    path: data?.content?.path,
    thumb: data?.content?.thumb,
  };
  const isURL = /^((http|https):\/\/)?[a-zA-Z0-9_-]+(\.[a-zA-Z]+)/;
  const thumbValidation = () => {
    return STYPE[name]?.id === 3 ? yup.string().matches(isURL, 'Please enter Correct Assets URL!').required('Required') : yup.string()
  }
  const pathValidation = () => {
    if (STYPE[name]?.id === 2 | STYPE[name]?.id === 3) {
      return STYPE[name]?.id === 2 ? yup.string().matches(isURL, 'Please enter Correct Assets URL!').required('Required') : yup.string().matches(/^((http|https):\/\/)/, 'Please enter Correct URL!').required('Required');
    }
    return yup.string();
  }
  const disValidation = (STYPE[name]?.id === 1) ? yup.string().required("Required") : yup.string();

  const validationSchema = yup.object().shape({
    title: yup.string().required("Required"),
    description: disValidation,
    thumb: thumbValidation(yup),
    path: pathValidation(yup),
  });

  const handleClose = () => {
    dispatch(utilityActions.toggleSideSheet({
      direction: 'right',
      title: '',
      open: false
    }))
  }

  const formik = useFormik({
    initialValues: mode === 'Edit' ? editfilledValues : filledValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      try {
        const obj = {
          ...values,
          description: values.description == '<p></p>\n' ? '' : values.description,
        }

        obj.title = obj.title
        obj.description = obj.description
        obj.path = obj.path
        obj.thumb = obj.thumb
        if (STYPE[name]?.id === 1) {
          delete obj.thumb
          delete obj.path
        }
        if (STYPE[name]?.id === 2) {
          delete obj.description
          delete obj.thumb
        }
        if (STYPE[name]?.id === 3) {
          delete obj.description
        }
        let newObj = {
          stype: STYPE[name]?.id,
          cid: utility?.selectedCourseUpdates?._id,
          content: { ...obj },
          sme: userInfo?.loggedInUser?._id,
          apm: "627122d94f3f1c4425ea377a",
          info: {},
          status: 1,

        }
        console.log("new Submit Data", newObj);
        { mode === 'Add' && dispatch(userActions.addSupplimentaryRequest(newObj)) };
        { mode === 'Edit' && dispatch(userActions.updateSupplimentaryRequest({ ...newObj, _id: data._id })) };
        addToast(`${mode} Successfully`, {
          appearance: "success",
          autoDismiss: true,
          placement: "bottom-center",
        });
        handleClose();
        delete obj.title;
        delete obj.description;
        // delete obj.category;
        delete obj.path;
        delete obj.thab;
      } catch { }
    },
  });

  return (
    <form className="user-form" onSubmit={formik.handleSubmit}>
      <div className='edit-continer'>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <TextField label="Title" id="outlined-size-small" value={formik.values.title} onChange={formik.handleChange('title')} InputLabelProps={{ shrink: true }} fullWidth inputProps={{ maxLength: 100 }} size="small" />
            <p className="error-msg">{formik.errors.title && formik.touched.title && formik.errors.title}</p>
          </div>
        </div>

        {STYPE[name]?.id === 3 && <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <TextField label="Thumb-Link" id="outlined-size-small" value={formik.values.thumb} onChange={formik.handleChange('thumb')} InputLabelProps={{ shrink: true }} fullWidth inputProps={{ maxLength: 100 }} size="small" />
            <p className="error-msg">{formik.errors.thumb && formik.touched.thumb && formik.errors.thumb}</p>
          </div>
        </div>}

        {(STYPE[name]?.id === 2 || STYPE[name].id === 3) && <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <TextField label={`${STYPE[name].name}-Link`} id="outlined-size-small" value={formik.values.path} onChange={formik.handleChange('path')} InputLabelProps={{ shrink: true }} fullWidth inputProps={{ maxLength: 100 }} size="small" />
            <p className="error-msg">{formik.errors.path && formik.touched.path && formik.errors.path}</p>
          </div>
        </div>}


        {STYPE[name]?.id === 1 && <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12"> Description: </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <RichTextEditor data={formik?.values?.description} eventHandler={formik.handleChange('description')} />
            <p className="error-msg">{formik.values?.description === '<p></p>\n' && 'Required'}</p>
          </div>
        </div>}
        <div className="btn-items">
          <button type="submit" disabled={formik.values?.description === '<p></p>\n'} className="btn btn-sign" >SAVE</button>
          <button type="submit" className="btn btn-cancel" onClick={handleClose} >CANCEL</button>
        </div>
      </div>
    </form>
  )
}

SupplimentaryPanel.propTypes = {
  /** Heading title of the component */
  name: PropTypes?.string,
  /** related extended content of the component  */
  mode: PropTypes?.string,
  /** function handler used to save the edited props */
  data: PropTypes?.object
}

export const STYPE = {
  "update": { id: 1, name: 'Update' },
  "notes": { id: 2, name: 'Note' },
  "vignettes": { id: 3,name: 'Vignette' },
}

export default SupplimentaryPanel
