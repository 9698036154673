import { getClientCoursesRequest } from "../redux/actions/clientActions";
import APIConstant from "../utility/APIConstant"

/**
 * 
 * @param {*} roleId 
 * @returns 
 * add multiple ids if required to give access
 * -1 static admin
 */
const PrivilegeHelper = (roleId)=>{
    return {
        roleId: roleId,
        getAccess() {
          return roleId === -1 ? true:false
        },
        getClientCourses() {
          return roleId !== APIConstant.CLIENT_ROLE_ID ? true:false
        }
    };
}

 

export default PrivilegeHelper