import ReactECharts from "echarts-for-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import * as utilityActions from '../../redux/actions/utilityActions'
import {
  optionGenrateChartDay,
  optionGenrateChartMonth,
  optionGenrateChartWeek,
  optionGenrateChartYear,
} from "./CommonGraph/EchartOptionGenerate";
import { useDispatch } from "react-redux";
import moment from "moment";
import CommonAccordion from "./accordion/CommonAccordion";

const StatisticsChartRender = ({ data, dataNew, select, maxVisual, pointerDate, handleClickCell }) => {
  const [graphWeek, setGraphWeek] = useState(null);
  const [graphyear, setGraphyear] = useState(null);
  const [graphMonth, setGraphMonth] = useState(null);
  const [graphDay, setGraphDay] = useState(null);
  const dispatch = useDispatch()
  const weekReturn = () => {
    setTimeout(() => {
      setGraphWeek(true);
      setGraphMonth(false);
      setGraphDay(false);
      setGraphyear(false);
    }, 100);
  };
  const dayReturn = () => {
    setTimeout(() => {
      setGraphWeek(false);
      setGraphMonth(false);
      setGraphDay(true);
      setGraphyear(false);
    }, 100);
  };
  const monthReturn = () => {
    setTimeout(() => {
      setGraphWeek(false);
      setGraphMonth(true);
      setGraphDay(false);
      setGraphyear(false);
    }, 100);
  };
  const yearReturn = () => {
    setTimeout(() => {
      setGraphWeek(false);
      setGraphMonth(false);
      setGraphDay(false);
      setGraphyear(true);
    }, 100);
  };
 
  if (select.dateSel && select.monthSel && select.weekSel) {
    //
    dayReturn();
    return (
      <>
        {graphDay && (
          <ReactECharts
            className=""
            onEvents={{click: handleClickCell(UserInteraction, pointerDate)}}
            style={{ height: "700px", width: "1200px" }}
            option={optionGenrateChartDay(data, select, {}, maxVisual)}
          />
        )}
      </>
    );
  } else if (select.weekSel && select.monthSel) {
    weekReturn();
    return (
      <>
        {graphWeek && (
          <ReactECharts
            className=""
            style={{ height: "700px", width: "1200px" }}
            // option={optionGenrateChart("week", data, select, {}, maxVisual)}
            // theme={"dark"}
            option={optionGenrateChartWeek(dataNew, select, {}, maxVisual)}
          />
        )}
      </>
    );
  } else if (select.monthSel && !select.weekSel) {
    monthReturn();
    return (
      <>
        {graphMonth && (
          <ReactECharts
            className=""
            style={{ height: "700px", width: "1200px" }}
            // option={optionGenrateChart("month", data, select, {}, maxVisual)}
            option={optionGenrateChartMonth(data, select, {}, maxVisual)}
          />
        )}
      </>
    );
  } else if (select.yearSel) {
    yearReturn();
    //
    return (
      <>
        {graphyear && (
          <ReactECharts
            className=""
            style={{ height: "700px", width: "1200px" }}
            // option={optionGenrateChart("month", data, select, {}, maxVisual)}
            option={optionGenrateChartYear(data, select, {}, maxVisual)}
          />
        )}
      </>
    );
  } else {
    //
  }
};
StatisticsChartRender.propTypes = {
  data: PropTypes.object.isRequired, // Example: data is required and should be an object
  dataNew: PropTypes.object.isRequired, // Example: dataNew is required and should be an object
  select: PropTypes.object.isRequired, // Example: select is required and should be an object
  maxVisual: PropTypes.number.isRequired,
};

// StatisticsChartRender.defaultProps = {
//   data: {}, // Default value for data if not provided (an empty object)
//   dataNew: {}, // Default value for dataNew if not provided (an empty object)
//   select: {}, // Default value for select if not provided (an empty object)
//   maxVisual: 10, // Default value for maxVisual if not provided
// };


const UserInteraction = ( { pointerDate, opts1, opts2} ) => {
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(-1)
  const groupPointerByUserName = (pointers) => {
    const groupByName = {}
    for(let data of pointers?.[`${opts1.data[0]}/${opts1.data[1]}`]){
      if(data?.name){
        if(!groupByName[data.name]){

          groupByName[data.name]=[data]
        }
        else{
          groupByName[data.name].push(data)
        }
      }
    }
   
    return groupByName
  }
  const interactionsByUser =  groupPointerByUserName(pointerDate)
  const handleAccordionChange = (index) => {
    setExpanded((prevExpanded) => (prevExpanded === index ? -1 : index));
  }
  const momentDate = (date) => {
    return  moment.utc(date).isSameOrAfter(moment().subtract(3, 'months')) ? (
    <p className="heatmap-side-data-name-date">{moment.utc(date).startOf('hour').fromNow()}</p>
  ) : (
    <p className="heatmap-side-data-name-date">{moment.utc(date).format('YYYY-MM-DD h:mm:ss A')}</p>
 )} 
  const accordionSummary = (item) => {
  
    return <div className="accordion-summary-container">
    <div className="d-flex gap-2 align-items-center user-icon-date">
      <div className="image">
          <i class="fa fa-user-circle-o" aria-hidden="true"></i>
        </div>
        <div className="user-date d-flex flex-column">
          <p className="m-0 user-name">{item}</p>
          {momentDate(interactionsByUser?.[item]?.[0]?.date)}
          {/* <p>{interactionsByUser?.[item][0].date}</p> */}
        </div>
    </div>
    <div className="d-flex gap-2 align-items-center icon-comment">
              <div className="d-flex justify-content-center icon"><i class="fa fa-commenting-o" aria-hidden="true"></i></div>
            <p className="m-0 py-1 comment">{interactionsByUser?.[item][0].stmt}</p>
    </div>
    <hr className="my-0"/>
    </div>
  }
  return (
 
    <div className="sidesheet-cartesian-date-map">
      {Object.entries(interactionsByUser).length !== 0 && Object.keys(interactionsByUser).map((item, i) => {
        return <CommonAccordion sx={{ marginBottom: "8px", boxShadow: "none", padding:"0" }} key={i} expanded={expanded} onClick={handleAccordionChange}
        index={i} summary={accordionSummary(item)}>
          <div>
            {interactionsByUser?.[item].slice(1).map((intrs, k) => {
              return <div className="date-comment">
               
                <div className="d-flex gap-2 user-date">
                  <div className="blank-date"></div>
                  {momentDate(intrs.date)}
                </div>
                <div className="d-flex gap-2 align-items-center icon-comment">
              <div className="d-flex justify-content-center icon"><i class="fa fa-commenting-o" aria-hidden="true"></i></div>
            <p className="m-0 py-1 comment">{intrs.stmt}</p>
                </div>
            <hr className="my-1"/>
              </div>
            })}
          </div>
        </CommonAccordion>

      }) }

      <div className="pt-5" onClick={() => {
        dispatch(utilityActions.toggleSideSheet({
              width: 700,
              direction: 'right',
              title: "User Interaction",
              open: false,
              component: <></>
          }))
      }}><a href="#statistics-info-charts" className="sidesheetinfo-charts-link">Show More Analytics</a></div>
    </div>
 
  );
}


export default StatisticsChartRender;
