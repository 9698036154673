import React, { useState } from "react"
import './UsersCreation.scss'
import { useToasts } from "react-toast-notifications";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import * as userActions from '../../redux/actions/userActions'
import * as utilityActions from '../../redux/actions/utilityActions'
import APIConstant from "../../utility/APIConstant";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

const UsersCreation = () => {

    const { addToast } = useToasts();
    const roles = APIConstant.ROLES;
    const userInfo = useSelector( state => state.user );
    const clientInfo = useSelector( state => state.client );
    const dispatch = useDispatch();
    const [ isDisabled, setIsDisabled ] = useState( true );
    const alphanumericRegExp = /^[A-Za-z0-9\s]+$/;
    const alphanumericRegExpNew = /^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[!@#$%^&*._-])[A-Za-z0-9\d!@#$%^&*._-]{6,30}$/;
    const initialValues = {
        user_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        role: '',
        client_id:'41'
    };
    // const initialValues = {
    //     user_name: 'tester',
    //     first_name: 'Test',
    //     last_name: 'User',
    //     email: 'user@ce.com',
    //     password: '11111!',
    //     role: '4',
    //     client_id:'41'
    // };

    const validationSchema = yup.object().shape( {
        user_name: yup.string().matches( alphanumericRegExp, 'Username is not valid' ).required( "Required" ),
        first_name: yup.string().matches( alphanumericRegExp, 'FirstName is not valid' ).required( "Required" ),
        last_name: yup.string().matches( alphanumericRegExp, 'LastName is not valid' ).required( "Required" ),
        email: yup.string().email( "Invalid email address" ).required( "Required" ),
        password: yup.string().matches( alphanumericRegExpNew, ' Password must have 6 characters that include atleast 1 character , 1 number and 1 special character in (!@#$%^&*)' ).required( "Required" ).min( 6, "Must be more than 6 characters" ),
        role: yup.string().required( "Required" ),
    } );


    const formik = useFormik( {
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {

            const obj = {
                ...values
            }

            obj.firstname = obj.first_name
            obj.lastname = obj.last_name
            obj.username = obj.user_name

            if(obj.role !== 5){
            // obj.client_id =obj.client_id
                delete obj.client_id;
            }
            delete obj.first_name;
            delete obj.last_name;
            delete obj.user_name;
            setIsDisabled( false );

            dispatch( userActions.addRLMUser( obj ) )
            addToast( "User Added Successfully", {
                appearance: "success",
                autoDismiss: true,
                placement: "bottom-center",
            } );

            dispatch(utilityActions.toggleSideSheet({
                direction:'left',
                title:'',
                open:false
            }))
        },
    } );


    const isDisabledBtn = () => {
        let value=formik?.values;
        if(value?.role===5 && value?.client_id?.length===0){
            formik.dirty=false
         }
        return ( !formik.dirty && isDisabled ) ? true : false;
    }


    return <>
        <form className="user-form" onSubmit={ formik.handleSubmit }>

            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TextField label="User Name" id="outlined-size-small" InputLabelProps={ { shrink: true } } fullWidth inputProps={ { maxLength: 100 } } value={ formik.values.user_name } onChange={ formik.handleChange( 'user_name' ) } size="small" />
                    <p className="error-msg">{ formik.errors.user_name && formik.touched.user_name && formik.errors.user_name }</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <TextField label="First Name" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } inputProps={ { maxLength: 100 } } value={ formik.values.first_name } onChange={ formik.handleChange( 'first_name' ) } size="small" />
                    <p className="error-msg">{ formik.errors.first_name && formik.touched.first_name && formik.errors.first_name }</p>
                </div> <div className="col-lg-6 col-md-6 col-sm-12">
                    <TextField label="Last Name" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } inputProps={ { maxLength: 100 } } value={ formik.values.last_name } onChange={ formik.handleChange( 'last_name' ) } size="small" />
                    <p className="error-msg">{ formik.errors.last_name && formik.touched.last_name && formik.errors.last_name }</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TextField label="Email" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } onChange={ formik.handleChange( 'email' ) } inputProps={ { maxLength: 100 } } value={ formik.values.email } size="small" />
                    <p className="error-msg">{ formik.errors.email && formik.touched.email && formik.errors.email }</p>
                </div>
            </div>

            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <TextField label="Password" id="outlined-size-small" type="password" fullWidth InputLabelProps={ { shrink: true } } onChange={ formik.handleChange( 'password' ) } inputProps={ { maxLength: 30 } } value={ formik.values.password } size="small" />
                    <p className="error-msg">{ formik.errors.password && formik.touched.password && formik.errors.password }</p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label" shrink>Role</InputLabel>
                        <Select
                            labelid="demo-simple-select-label"
                            SelectDisplayProps={{ style: { paddingTop: 8, paddingBottom: 8 } }}
                            id="demo-simple-select"
                            label="Role"
                            size="small"
                            notched
                            value={ formik.values.role }
                            onChange={ formik.handleChange( 'role' ) }
                        >
                            { Object.keys( roles ).map( ( key, index ) => {
                                return  <MenuItem key={ index } value={ roles[ key ].id }>{ roles[ key ].name }</MenuItem>

                            } ) }
                        </Select>
                    </FormControl>
                    <p className="error-msg">{ formik.errors.role && formik.touched.role && formik.errors.role }</p>
                </div>
            </div>

            {formik?.values?.role === 5 &&
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                <FormControl fullWidth>
                        <InputLabel id="client-simple-select-label" shrink>Client</InputLabel>
                        <Select
                            labelid="client-simple-select-label"
                            id="client-simple-select"
                            label="Client"
                            size="small"
                            notched 
                            value={ formik?.values?.client_id }
                            onChange={ formik.handleChange( 'client_id' ) }
                        >
                            { clientInfo?.allClientList.length > 0 && clientInfo?.allClientList.map( ( item, index ) => {
                                return  <MenuItem key={ item.clientid } value={ `${item._id}:${item.clientid}` }>{ item.clientname }</MenuItem>

                            } ) }
                        </Select>
                    </FormControl>
                    <p className="error-msg">{(formik?.values?.client_id.length===0) && "Required"}</p>
                </div>
            </div>}


            <div className="btn-items">
                <button type="submit" className="btn btn-sign" disabled={ isDisabledBtn() }>ADD USER</button>
                <button type="submit" className="btn btn-cancel" onClick={ formik.handleReset }>CANCEL</button>
            </div>
        </form>
    </>
}

export default UsersCreation