import React, { useState } from "react";
import "./RoleStatsAccordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import * as utilityActions from "../../../redux/actions/utilityActions";
import DetailCard from "../CommonCard/DetailCard";

function CommonAccordion({index, summary, children,expanded,onClick, ...props}) {

    
    const handleClickInsideContainer = (event) => {
        event.stopPropagation();
      };
      // const [expanded, setExpanded] = useState(-1);
      const openAccordion = (index) => {
        onClick(index)
      };

  return (
    <div>
         <Accordion
            expanded={index === expanded}
            key={index}
            sx={{ marginBottom: "8px", boxShadow: "none" }}
            onClick={() => openAccordion(index)}
            style={{ backgroundColor: "#f8f8f8" }}
            {...props}
            isOpen
          >
            <AccordionSummary
              expandIcon={
                expanded === index ? (
                  <i className="fa fa-chevron-down icon color-ce-green" />
                ) : (
                  <i className="fa fa-chevron-down icon color-ce-green" />
                )
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              {summary}
            </AccordionSummary>
            
              <AccordionDetails
                sx={{ paddingLeft: "30px" }}
                className="card-grid"
                onClick={handleClickInsideContainer}
              >
              {children}
              </AccordionDetails>
          </Accordion>
    </div>
  )
}

export default CommonAccordion