import React, { useState } from 'react';
import './UsersList.scss';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import * as utilityActions from '../../redux/actions/utilityActions'
import UserEdit from './UserEdit';
import APIConstant from '../../utility/APIConstant';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const UsersList = ({users,user}) => {

    const dispatch = useDispatch()
    const [opened,setMenuOpen] = useState(-1);
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const utilityInfo = useSelector( state => state.utility );

    const closeMenuItem = (item,title)=>{
        setMenuOpen(-1);
        dispatch(utilityActions.toggleSideSheet({
            width:900,
            direction:'right',
            title:title,
            open:true,
            component:<UserEdit data={item}/>
        }))
    }


    return <div className='users-list-holder'>
        { users.length > 0 && <ul className='users-list'>
            <li className='header-item' key={'header_2'}>
                <div className='flex-item'>
                    <div className='name-item'>Name</div>
                    <div>Role</div>
                    <div>Courses</div>
                    <div>Status</div>
                    <div></div>
                </div>
            </li>
            { users.map( ( item,index ) => {
                return  item?.user?.role !== -1 && <li key={index}>
                    <div className='flex-item' key={'fl_'+item?.id}>
                        <div className='name-item'> { item?.user?.firstname }</div>
                        <div title={item?.user?.role}>{APIConstant.ROLES[item?.user?.role]?.name}</div>
                        <div>{item?.courses?.length}</div>
                        <div>
                        <Switch {...label} defaultChecked />
                        </div>
                        <div>
                        <MoreVertIcon className={index === opened ? 'active' : 'non-active'} onClick={()=>setMenuOpen(index)} />
                        { index === opened && <div className='options-area'>
                            <ul>
                                <li key={'user_edit'} onClick={()=>closeMenuItem(item,'Edit Details')}>
                                    <span><i className='fa fa-user'></i></span>&nbsp;<span>Edit</span>
                                </li>
                            </ul>
                        </div>}
                        </div>
                    </div>
                </li>
            } ) }
        </ul> }
        
    </div>
}

export default UsersList