import React, { useState,useEffect } from 'react';
import './Notes.scss';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions';
import SupplimentaryPanel from './SupplimentaryPanel';
import CeAssets from '../../assets/img/CeAssets.png';
import APIConstant from '../../utility/APIConstant';

const Notes = () => {

    const userInfo = useSelector(state => state?.user);
    let Notes = userInfo?.supplementaries?.notes;
    const [slectedNotes, setSlectedNotes] = useState(null);
    const [selectedId, setSlectedId] = useState(0);
    const dispatch = useDispatch();
    let selectedNotes;

    const handleOpen = (item, id) => {
        setSlectedNotes(item);
        setSlectedId(id)
    }

    const handleEdit = (item) => {
        dispatch(utilityActions.toggleSideSheet({
            direction: 'right',
            width: 800,
            title: 'Edit Note',
            open: true,
            component: <SupplimentaryPanel name={'notes'} mode={'Edit'} data={item} />
        }))
    }

    useEffect(() => {
        if (Notes) { setSlectedNotes(Notes[selectedId]) };
    }, [Notes])

    if (Notes) { selectedNotes = (slectedNotes !== null ? slectedNotes : Notes[0]); }

    return (
        <div className='notes-continer'>
          {Notes?.length > 0 ? <div className="row">
                <div className="col-lg-5 col-md-5 col-xs-24  notes-list" >
                    <div className="row">
                        {
                            Notes?.map((item, idx) => {
                                let activeImge = 'name-layer ' + `${selectedId === idx && 'active-img'}`;
                                return (<div key={idx} className="col-6 col-md-4 notes-card" onClick={() => handleOpen(item, idx)}>
                                    <div className='inner-content'>
                                        <span className={activeImge}>{item?.content?.title}</span>
                                         <img src={APIConstant.getFilePath(item?.content?.path)}  onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets}}/>
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-xs-24 select-note" > 
                    {(selectedNotes && selectedNotes?.content?.path) &&<div className='selected-section'>
                        <div className='list-heading'><p>{selectedNotes?.content?.title}</p> <p className='edit-action' onClick={()=>handleEdit(selectedNotes)}><i className="fa fa-pencil" aria-hidden="true"></i>Edit</p> </div>
                        <div className='img-continer'>
                        <img className='slected-img' src={APIConstant.getFilePath(selectedNotes?.content?.path)} alt="Loading.."  onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets} }/></div>
                    </div>}
                </div>
            </div>
            :<p className='no-data'>Currently there is no posters available...</p> }
        </div>
    )
}

export default Notes