import React from 'react'
import "./CardT.scss"

const Card=({title="Tittle",description="This is a card for timeline",content="lorem ipsum tyext",image=true,preview, data})=> {
    const handleClick=(data)=>(e)=>{
        console.log(data)
        preview(data)
    }
  return (
    <div className='card-wrapper'>
        {image && <div className='image-wrapper'>
            <img src="https://imgs.search.brave.com/OP_Xmnn9vbqgPovJQ5zDxpNfczb6tF-INEzdC8-qZZ8/rs:fit:500:0:0/g:ce/aHR0cHM6Ly93d3cu/aXN0b2NrcGhvdG8u/Y29tL3Jlc291cmNl/cy9pbWFnZXMvUGhv/dG9GVExQL05hdHVy/ZUxhbmRzY2FwZXMt/NTE5NzYwOTg0Lmpw/Zw" alt="hi" /></div>}
        <div className='content-wrapper'>
            <h3>{title}</h3>
            <p className='desc'>{description}</p>
            <p className='content'>{content}</p>
            {preview && <div className='preview'>       
        {/* <button onClick={handleClick(data)}>Details &#x2192;</button>     */}
        <button onClick={handleClick(data)}>Details</button>    
    </div>
        }
        </div>
    </div>
  )
}

export default Card