import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import './SideSheet.scss'
import { useDispatch, useSelector } from 'react-redux';
import * as utilityActions from '../../redux/actions/utilityActions'

const SideSheet = ( ) => {

    const dispatch = useDispatch()

    const utilityInfo = useSelector(state=>state.utility)

    const [ sheetState, setSideSheetState ] = useState( {
        top: false,
        left: false,
        bottom: false,
        right: false,
    } );

    useEffect( () => {
        setSideSheetState( { ...sheetState, [ utilityInfo?.sideSheet?.direction ]: utilityInfo?.sideSheet?.open } );
    }, [ utilityInfo?.sideSheet?.open] )

    const toggleDrawer = ( anchor, open ) => ( event ) => {
        if ( event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' ) ) {
            return;
        }

        setSideSheetState( { ...sheetState, [ anchor ]: open } );
    };

    const closeSideSheet = (anchor,open)=>{
        setSideSheetState( { ...sheetState, [ anchor ]: open } );
        dispatch(utilityActions.toggleSideSheet({
             direction:utilityInfo?.sideSheet?.direction,
             title:utilityInfo?.sideSheet?.title,
             open:!utilityInfo?.sideSheet?.open
            }))
    }

    return <div className='drawer-container'>
        <Drawer
            anchor={ utilityInfo?.sideSheet?.direction }
            open={ sheetState[ utilityInfo?.sideSheet?.direction ] }
            onClose={ toggleDrawer( utilityInfo?.sideSheet?.direction, utilityInfo?.sideSheet?.open ) }
        >
           <div className='side-sheet-container' style={{width:utilityInfo?.sideSheet?.width}}>
                <div className='flex-item'>
                    <h5 className='sheet-title'>{utilityInfo?.sideSheet?.title}</h5>
                    <i className='fa fa-close' onClick={(e)=>closeSideSheet(utilityInfo?.sideSheet?.direction, false)}/> 
                </div>
                <div>
                    {utilityInfo?.sideSheet?.component}
                </div>
                {/* <div className='btn-actions'>
                        some action buttons
                </div> */}
            </div> 
        </Drawer>
    </div>
}

export default SideSheet