import React from "react"
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions'
import './CommonModal.scss'
import SignUp from "../auth/SignUp";
import SignIn from "../auth/SignIn";

const CommonModal = ()=>{

    const dispatch = useDispatch();
    const utilityInfo = useSelector(state => state.utility);

    const renderModal = modalType => {
        if (modalType === "")
            return
        switch (modalType) {
            case "sign-up":
                return <SignUp />
            case "sign-in":
                return <SignIn />
        }
    }
    const classList = () =>{
        return 'close-modal-btn'
    }

    return (
        utilityInfo.isDisplayModal ?
            <div className="modal-main-container">
                <div className={`modal-inner`}>
                    <div className={classList()}>
                        <i className="fa fa-times" onClick={() => dispatch(utilityActions.closeModal())}></i>
                    </div>
                    {renderModal(utilityInfo.modalType)}
                </div>
            </div>
            :
            null
    )
}

CommonModal.propTypes = {

}
export default CommonModal