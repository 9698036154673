import * as types from '../reducers/types'

const initialState = {
  allClientList:[],
  courses:{},
  activeClient:{}
}


const clientReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.ALL_CLIENT_RES:
            let list = [];
            if(action?.payload?.status === "success"){
                list = action?.payload?.data;
            }
            return {
                ...state,
                allClientList:list,
                
            }
        case types.LOGGEDIN_CLIENT_COURSES_RES:
                let crs = [];
                if(action?.payload?.status === "success"){
                    crs = action?.payload?.data;
                }
                return {
                    ...state,
                    courses:crs,
                    
             }
        case types.LOGGEDIN_CLIENT_COURSES_REQ:
        return {
            ...state,
            activeClient:action?.payload,
            
        }
        case types.LOGGEDOUT_CLIENT_COURSES_REQ:
                return {
                    ...state,
                    courses:{},
                    allClientList:[],
                    
                }
        default:
            return state
    }
}

export default clientReducer