import * as types from '../reducers/types'

const initialState = {
  isSideNavOpen:false,
  modalType:'',
  isDisplayModal:false,
  selectedMenuId:-1,
  isConfig: false,
  selectedCourseUpdates:{},
  sideSheet:{
      width:500,// in pixels
      direction:'left',
      title:"some title",
      open:false,
      component:{}
  }
}


const utilityReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case types.TOGGLE_SIDE_NAV:
            return {
                ...state,
                isSideNavOpen:action?.payload,
                
            }
        case types.OPEN_MODAL:
            return { ...state, isDisplayModal: true, modalType: action.payload.modalType, chkbtn:action.payload.chkbtn, modalData: action.payload.modalData }
        case types.CLOSE_MODAL:
            return { ...state, isDisplayModal: false, modalType: "", modalData: {} }
        case types.SELECTED_MENU:
            return { ...state,selectedMenuId:action?.payload ,selectedCourseUpdates:{}}
        case types.SELECTED_SUPPLIMENTARY_REQ:
            return { ...state,selectedMenuId:action?.payload?.menuId,selectedCourseUpdates:action?.payload?.course,isConfig: action?.payload?.isConfig}
        case types.SELECTED_HISTORY_REQ:
            return { ...state,selectedMenuId:action?.payload?.menuId,selectedCourseUpdates:action?.payload?.course}
        case types.TOGGLE_SIDE_SHEET:
            let sideSheet = {...state.sideSheet}
            sideSheet.width = action.payload?.width
            sideSheet.title = action.payload?.title
            sideSheet.direction = action.payload?.direction
            sideSheet.open = action.payload?.open
            sideSheet.component = action.payload?.component
            return { ...state,['sideSheet']:sideSheet}
        default:
            return state
    }
}

export default utilityReducer