import React, { useState } from "react";
import "./RoleStatsAccordion.scss";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { useSelector, useDispatch } from "react-redux";
import * as utilityActions from "../../../redux/actions/utilityActions";
import DetailCard from "../CommonCard/DetailCard";

export default function RoleStatsAccordion({
  specificCompanyData,
  getDualRole,
}) {
  const role = [
    "Primary_Owner_and_Compliance_Officer",
    "Other_Owners",
    "Employees",
  ];
  const handleClickInsideContainer = (event) => {
    event.stopPropagation();
  };
  const [expanded, setExpanded] = useState(-1);
  const getRoles = (role, data) => {
    if (role === "Other_Owners") {
      return "Other Owner" + getDualRole(data, "oo");
    } else {
      return "Employee" + getDualRole(data, "ep");
    }
  };
  const openAccordion = (index) => {
    if (expanded !== index) {
      setExpanded(index);
    } else {
      setExpanded(-1);
    }
  };

  return (
    <div class="role-stats-accordion">
      {role.map((item, index) => {
        return (
          <Accordion
            expanded={index === expanded}
            key={item}
            sx={{ marginBottom: "8px", boxShadow: "none" }}
            onClick={() => openAccordion(index)}
            style={{ backgroundColor: "#f8f8f8" }}
          >
            <AccordionSummary
              expandIcon={
                expanded === index ? (
                  <i className="fa fa-chevron-down icon color-ce-green" />
                ) : (
                  <i className="fa fa-chevron-down icon color-ce-green" />
                )
              }
              aria-controls={`panel${index}a-content`}
              id={`panel${index}a-header`}
            >
              <div className="accordion-title">
                {item.split("_").join(" ")}{" "}
              </div>
            </AccordionSummary>
            {item === "Primary_Owner_and_Compliance_Officer" && (
              <AccordionDetails
                sx={{ paddingLeft: "64px", paddingRight: "64px" }}
                className="card-grid"
                onClick={handleClickInsideContainer}
              >
                {Object.keys(specificCompanyData?.Primary_Owner).length ? (
                  <DetailCard
                    name={specificCompanyData?.Primary_Owner?.name}
                    fname={specificCompanyData?.Primary_Owner?.fname}
                    lname={specificCompanyData?.Primary_Owner?.lname}
                    role="Primary Owner"
                    job_title={specificCompanyData?.Primary_Owner?.job_title}
                    email={specificCompanyData?.Primary_Owner?.email}
                    score={
                      specificCompanyData?.Primary_Owner?.score
                        ? specificCompanyData?.Primary_Owner?.score
                        : 0
                    }
                    phone_number={
                      specificCompanyData?.Primary_Owner?.phone
                        ? specificCompanyData?.Primary_Owner?.phone
                        : "NA"
                    }
                  />
                ) : (
                  <p className="no-data-role-p">No user found</p>
                )}
                {specificCompanyData?.Compliance_Officer?.[0] ? (
                  <DetailCard
                    name={specificCompanyData?.Compliance_Officer?.[0]?.name}
                    role={
                      "Compliance Officer" +
                      getDualRole(
                        specificCompanyData?.Compliance_Officer?.[0],
                        "co"
                      )
                    }
                    email={specificCompanyData?.Compliance_Officer?.[0]?.email}
                    job_title={
                      specificCompanyData?.Compliance_Officer?.[0]?.job_title
                    }
                    score={
                      specificCompanyData?.Compliance_Officer?.[0]?.score
                        ? specificCompanyData?.Compliance_Officer?.[0]?.score
                        : 0
                    }
                    phone_number={
                      specificCompanyData?.Compliance_Officer?.[0]?.phone
                        ? specificCompanyData?.Compliance_Officer?.[0]?.phone
                        : "NA"
                    }
                  />
                ) : (
                  <></>
                )}
              </AccordionDetails>
            )}
            {item !== "Primary_Owner_and_Compliance_Officer" && (
              <AccordionDetails onClick={handleClickInsideContainer}>
                {specificCompanyData?.[item].length ? (
                  <div className="card-grid">
                    {specificCompanyData?.[item].map((key) => (
                      // <p
                      //   id={`${item}-${key?._id || key?.user_id}`}
                      //   onClick={(e) => handleClick(e, key)}
                      // >
                      //   {key?.name
                      //     ? key?.name
                      //     : key?.first_name + " " + key?.last_name}
                      // </p>
                      <DetailCard
                        name={key?.name}
                        fname={key?.firstName || key?.first_name}
                        lname={key?.lastName || key?.last_name}
                        role={getRoles(item, key)}
                        email={key?.email || key?.emailAddress}
                        job_title={key?.job_title}
                        score={key?.score ? key?.score : 0}
                        phone_number={key?.phone || key?.phoneNumber || "NA"}
                      />
                    ))}
                  </div>
                ) : (
                  <p className="no-data-role-p">No user found</p>
                )}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </div>
  );
}
