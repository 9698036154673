import React, { useEffect, useState } from 'react';
import { TextField, IconButton, Button } from '@material-ui/core';
import * as userActions from '../../../redux/actions/userActions'
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import { useToasts } from 'react-toast-notifications';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@material-ui/icons/Close';
import './modalForm.scss';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import WarningIcon from '@mui/icons-material/Warning';

const label = { inputProps: { 'aria-label': 'Approve all course' } };


const ModalForm = ({ open, onClose, configId, allCourses}) => {
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: ''
  });
  const [formError, setErrorForm] = useState("");
  const dispatch=useDispatch()
  const [checkedApproveAll, setCheckApproveAll] = useState(false);
  const courseApproval = useSelector(state=>state.user.courseApproval)

  
  const handleCheckChange = (event) => {
    setCheckApproveAll(event.target.checked);
  };
  
    const dataValidation=(form)=>{
      if(form?.firstName.length<2){
        setErrorForm(()=>"Firstname should not be empty / Minimum 2 characters")
        return false
      }
      if(form?.lastName.length<2){
        setErrorForm(()=>"LastName should not be empty / Minimum 2 characters")
        return false
      }
      if(! (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(form?.email))){
        setErrorForm(()=>"Please enter valid Email")
        return false
      }
      return true
    }

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  useEffect(()=>{
    if(formError.length>1){
      addToast( formError, {
        appearance: "error",
        autoDismiss: true,
        placement: "top-right",
    } );
    }
  },[formError])

  const {addToast}=useToasts();
  
//   const handleSubmit = () => {
//     if(dataValidation(form)){

//       if(!checkedApproveAll){
//         const data={
//             "course_ids": JSON.stringify([configId]),
//             "email": form.email,
//             "fname": form.firstName,
//             "lname": form.lastName,
//             "mobile": form.mobile || "",
//             "status": "1"
//           }
//       dispatch(userActions.courseApprovalReq({data,configId:[configId]}))
//     }
//     else{
//       const remaingNotApproved =allCourses?.filter(item=>item?.status!==1).map(item=>JSON.parse(item.course_data).id).filter(item=>!courseApproval.includes(item))
      
//       const data={
//         "course_ids": JSON.stringify([...remaingNotApproved]),
//         "email": form.email,
//         "fname": form.firstName,
//         "lname": form.lastName,
//         "mobile": form.mobile || "",
//         "status": "1"
//       }
//       dispatch(userActions.courseApprovalReq({data,configId:remaingNotApproved}))
//       onClose();
//     }
//     onClose();
//   };
// }
const handleSubmit = () => {
  if (dataValidation(form)) {
    let courseIds = [configId];

    if (checkedApproveAll) {
      const remainingNotApproved = allCourses
        ?.filter(item => item?.status !== 1)
        .map(item => JSON.parse(item.course_data).id)
        .filter(item => !courseApproval.includes(item));
      courseIds = remainingNotApproved;
    }

    const data = {
      "course_ids": JSON.stringify(courseIds),
      "email": form.email,
      "fname": form.firstName,
      "lname": form.lastName,
      "mobile": form.mobile || "",
      "status": "1"
    };

    dispatch(userActions.courseApprovalReq({ data, configId: courseIds }));
    onClose();
  }
};


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    maxWidth:"600px",
    minWidth:"360px",
    bgcolor: '#fff',
    border: '1px solid #757575',
    boxShadow: 2,
    borderRadius: 5, 
    p: 4,
  }; 

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <div className="modal-header-approve">
          <Typography variant="h6" component="h2">Approver Details</Typography>
          <div className='d-flex justify-space-between gap-5'> 
            <div className='d-flex align-items-center'>
            <Checkbox {...label}  
               checked={checkedApproveAll}
               onChange={handleCheckChange}
    sx={{
      "&.MuiButtonBase-root":{
        color:"gray",
      },
      '& .MuiSvgIcon-root': {
        fontSize: 20, 
        color: 'inherit', 
      },
      padding: '4px', // Adjust padding
      '&.Mui-checked': {
          color: '#0178e6 !important',
      }
    }}
    />
            <Typography sx={{padding:"4px",fontSize:"14px",color:"#000a27",fontWeight:"Bold"}}>Approve all course</Typography>
            </div>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          </div>
        </div>
        {checkedApproveAll?<Typography sx={{
          color:"#ec625a",
          backgroundColor:"#f8ebe7",
          borderRadius:"5px",
          padding:"8px",
          marginBottom:"8px"
        }}>
          <div className='d-flex gap-2'>
          <WarningIcon className='flex-shrink-0' ></WarningIcon>
          Please ensure that you have thoroughly verified all courses and their customizations. Once a course is approved, the personalization and editing features will be disabled, and the course will be locked and ready for deployment in the LMS and SCORM packaging.<br></br> If you have completed the verification, click "Approve" to confirm, or click "Cancel" to close the window.
          </div>
        </Typography>:<></>}
        <div className='d-flex justify-content-between'>
        <Typography>Please Enter Approver details</Typography>
        <Typography style={{color:"#ff0a3c"}}><sup>*</sup> Required Field</Typography>
        </div>
        <form className="modal-form">
        <Grid container spacing={{ xs: 2, md: 3 }} className={"mb-4"}>
        <Grid item xs={12} md={6}>
          
          <TextField className="col-md-6" label="First name" name="firstName" required fullWidth margin="normal" value={form.firstName} onChange={handleChange} />
        
      </Grid>
        <Grid item xs={12} sm={12} md={6}>
         
          <TextField className="col-md-6" label="Last name" name="lastName" required fullWidth margin="normal" value={form.lastName} onChange={handleChange} />

      </Grid>
        <Grid item xs={12}  sm={12} md={6}>
          
          <TextField className="col-md-6" label="Email" name="email" required fullWidth margin="normal" value={form.email} onChange={handleChange} />
      
      </Grid>
        <Grid item xs={12} sm={12} md={6}>
     
          <TextField className="col-md-6" label="Mobile No" name="mobile" fullWidth margin="normal" value={form.mobile} onChange={handleChange} />
   
      </Grid>
          </Grid>

          <div className="modal-actions">
            <Button variant="contained" color="secondary" onClick={onClose}>Cancel</Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Approve</Button>
          </div>
        </form>
      </Box>
    </Modal>
  );
};

export default ModalForm;
