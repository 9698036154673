import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions';
import './Vignettes.scss';
import SupplimentaryPanel from './SupplimentaryPanel';
import CeAssets from '../../assets/img/CeAssets.png';
import APIConstant from '../../utility/APIConstant';

const Vignettes = () => {

    const userInfo = useSelector(state => state?.user);
    let Vignettes = userInfo?.supplementaries?.vignettes;
    const dispatch = useDispatch();

    const handleEdit = (item) => {
        dispatch(utilityActions.toggleSideSheet({
            direction: 'right',
            width: 800,
            title: 'Edit Vignette',
            open: true,
            component: <SupplimentaryPanel name={'vignettes'} mode={'Edit'} data={item} />
        }))
    }


    return (
        <div className='vignettes-continer'>
           {!Vignettes?.length ? <p className='no-data'>Currently there is no vignettes available...</p> : <div className="row thamp-list">
           {
                      Vignettes?.map((el, idx) => {
                                return (<div key={idx} className="col-lg-4 col-md-6 col-xs-24  thamp-list-item">
                                     <span className='layer'> <i className="fa fa-pencil" aria-hidden="true" onClick={()=>handleEdit(el)}></i></span>
                                    <a target='_blank' className='a-tag' href={el?.content?.path} >
                                    <div className='inner-content' >
                                         <img src={APIConstant?.getFilePath(el?.content?.thumb)} onError={(e)=>{e.target.onerror = null; e.target.src=CeAssets} }/> 
                                         <p className="content">{el?.content.title}</p>
                                    </div>
                                    </a>
                                </div>
                                )
                            })
                        }
            </div>}
        </div>
    )
}


export default Vignettes