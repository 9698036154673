import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import Input from '@mui/material/Input';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import './AdminTags.scss';
import { useToasts } from 'react-toast-notifications';
import * as userActions from '../../redux/actions/userActions'



const AdminTags = () => {
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.user);
  const [selectedTag, setSelectedTag] = useState({ tag: '' });
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [tagList, setTagList] = useState(userInfo?.adminTags?.tags);
  const [keywords, setKeywords] = useState(userInfo?.adminTags?.data?.assetmgr_config?.keywords);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [externalIdx, setExternalIdx] = useState(userInfo?.adminTags?.externalIdx);
  const { addToast } = useToasts();

  const onEdit = (tag, index) => {
    setSelectedTag(tag);
    setSelectedIndex(index);
  }

  const handleChange = (e) => { setSelectedTag({ ...selectedTag, tag: e?.target?.value }); }

  useEffect(() => {
    setTagList(userInfo?.adminTags?.tags);
    setKeywords(userInfo?.adminTags?.data?.assetmgr_config?.keywords);
    setExternalIdx(userInfo?.adminTags?.externalIdx)
  }, [userInfo?.adminTags])

  const onAddEditTag = (tag) => {
    if (selectedTag?.tag?.length > 1) {
      let tagsArr = tagList;
      let keyTags = [];
      let keyTagIdx;
      if (selectedIndex > -1) {
        tagsArr[selectedIndex] = selectedTag;
        keyTags = keywords[tag?.pIdx]?.tags;
        keyTagIdx = keyTags.indexOf(tag?.tag);
        keyTags[keyTagIdx] = selectedTag?.tag;
        keywords[tag?.pIdx] = { ...keywords[tag?.pIdx], tags: keyTags };
        dispatch(userActions.adminTagsUpdate({ keywords: keywords, roles: [] }));
      } else if (isAdd && selectedIndex === -1) {
        if (externalIdx === -1) {
          let newTagObj = {
            topic: 'External Tags',
            tags: [selectedTag?.tag]
          }
          keywords.push(newTagObj);
          tagsArr.push({ ...selectedTag, pIdx: keywords?.length - 1 });
          setExternalIdx(keywords?.length - 1)
        } else {
          keyTags = keywords[externalIdx]?.tags;
          keyTags.push(selectedTag?.tag);
          keywords[externalIdx] = { ...keywords[externalIdx], tags: keyTags };
          tagsArr.push({ ...selectedTag, pIdx: externalIdx });
        }
        setIsUpdate(true);
      }
      setKeywords(keywords);
      setTagList(tagsArr);
      activeCancel();
    }
    setIsAdd(false);
  }

  const onDelete = (index, tag) => {
    if (isDelete) {
      let tags = tagList;
      let keyTags = keywords[tag?.pIdx]?.tags;
      let keyTagIdx = keyTags.indexOf(tag?.tag);
      keyTags.splice(keyTagIdx, 1);
      keywords[selectedTag?.pIdx] = { ...keywords[tag?.pIdx], tags: keyTags };
      addToast("Tag Deleted successfully", {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-center",
      });
      setKeywords(keywords);
      dispatch(userActions.adminTagsUpdate({ keywords: keywords, roles: [] }));
      tags.splice(index, 1);
      setTagList(tags);
      activeCancel();
      setIsUpdate(false);
    } else {
      setDelete(true);
      setSelectedIndex(index);
    }
  }

  /* Updating  Admin Tags */
  const handleSubmit = () => {
    if (isUpdate) {
      dispatch(userActions.adminTagsUpdate({ keywords: keywords, roles: [] }));
      addToast("AdminTags Updated Successfully", {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-center",
      });
      activeCancel();
      setIsAdd(false);
      setIsUpdate(false);
    }
  }
  const activeCancel = () => {
    setSelectedIndex(-1);
    setDelete(false);
    setSelectedTag({ tag: '' });
    setIsAdd(false);
  }


  return (
    <div className='admin-tag-continer'>
      <div className='tag-header'>
        <p className='heading-text'> Admin Tags</p>
      </div>
      <Box className='tag-box'>
        <div className='add-box'>
          <div className='add-box-inner'>
            {isAdd && selectedIndex === -1 ? <>
              <CheckIcon className='icon' onClick={onAddEditTag} />
              <Input
                id="standard-multiline-flexible"
                value={selectedTag.tag}
                className='add-text'
                onChange={handleChange}
                variant="standard"
              />
            </> : <AddIcon className='icon' onClick={() => setIsAdd(true)} />}
          </div>
          <Button className={`save-btn ${isUpdate && 'active-save'}`} disabled={!isUpdate} onClick={handleSubmit}>SAVE</Button>
        </div>
        <Divider variant="middle" className='divider' />

        <div className='row list-items'>
          {!(tagList?.length > 0) ? <p>Loading...</p> : tagList?.sort((itemA, itemB) => itemA.tag.localeCompare(itemB.tag))?.map((tag, index) => {
            return <div className={`col-lg-6 col-md-12 col-sm-12 list-box  ${(selectedIndex === index) ? 'active-tag' : 'tag'}`} key={index + 1}>
              <div className='list-item'>
                {(isDelete && selectedIndex === index) ? <>
                  <p className='delete-msg'> Delete this tag ?  </p>
                  <div className='actions'>
                    <Button className='cncel btn' onClick={activeCancel} >CANCEL </Button>
                    <Divider orientation="vertical" />
                    <Button className='delete-btn btn' onClick={() => onDelete(index, tag)} >DELETE </Button>
                  </div>
                </> :
                  <>
                    {(selectedIndex > -1 && selectedIndex === index) ? <Input
                      id="standard-multiline-flexible"
                      className='tag-text'
                      style={{ width: '85%' }}
                      value={selectedTag.tag}
                      onChange={handleChange}
                      variant="standard"
                    /> : <p className='lable'>{tag.tag}</p>}

                    <div className='actions'>
                      {(selectedIndex > -1 && selectedIndex === index) ?
                        <CheckIcon className='icon' onClick={() => onAddEditTag(tag)} />
                        : <EditIcon className='icon' onClick={() => onEdit(tag, index)} />}
                      <Divider orientation="vertical" />
                      <DeleteOutlineIcon onClick={() => onDelete(index, tag)} className='icon' />
                    </div></>}
              </div>
            </div>
          })}
        </div>
      </Box>
    </div>
  )
}

export default AdminTags