import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Chrono } from "react-chrono";
import CustomContent from "./CommonCard/CustomContent";
import "./Timeline.scss";
import Box from "@mui/material/Box";
import * as userActions from '../../redux/actions/userActions';
import { useToasts } from "react-toast-notifications";
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CommonSelect from "./CommonSelect/CommonSelect";
import SideSheet from "../sidesheet/SideSheet";
import { useParams } from "react-router-dom";

  const lrnList = {
    "1": { name: "Video", title: "VIDEO" },
    "2": { name: "Audio", title: "AUDIO" },
    "3": { name: "Accordion", title: "Accordion" },
    "4": { name: "Tabs", title: "Tabs" },
    "5": { name: "Carousels", title: "Carousels" },
    "6": { name: "CourseImageFlip", title: "Course Image Flip" },
    "7": { name: "CourseCardFlip", title: "Course Card Flip" },
    "8": { name: "DragnDrop", title: "Drag & Drop" },
    "9": { name: "HalfImageText", title: "Half Image Text" },
    "10": { name: "ImageText", title: "Image & Text" },
    "11": { name: "ImageMap", title: "ImageMap" },
    "12": { name: "OrderedList", title: "Ordered List" },
    "13": { name: "UnorderedList", title: "Unordered List" },
    "14": { name: "RadioSelect", title: "Radio Selection" },
    "15": { name: "ImageSelect", title: "Image Selection" },
    "16": { name: "TopicHeader", title: "Topic Header" },
    "17": { name: "TopicDescription", title: "Topic Description" },
    "20": { name: "ArchiveVideo", title: "Archive Video" },
    "21": { name: "BackgroundImage", title: "Background Image" },
    "22": { name: "Search", title: "Search" },
    "23": { name: "Attachments", title: "Attachments" },
    "24": { name: "CardStackCarousel", title: "Card Stack Carousel" },
    "25": { name: "ImageMapper", title: "ImageMapper" },
    "26": { name: "KnowledgeCheck", title: "KnowledgeCheck" },
    "27": { name: "Quizzes", title: "QUIZZES" },
    "28": { name: "Draggables", title: "Draggables" },
    "30": { name: "Quotes", title: "Quotes" },
    "31": { name: "ScenarioBlock", title: "ScenarioBlock" },
    "32": { name: "DragandMatch", title: "DragandMatch" },
    "33": { name: "ReorderingList", title: "ReorderingList" },
    "34": { name: "ImageGallery", title: "ImageGallery" },
    "a": { name: "experienced", title: "Experienced" },
    "b": { name: "played", title: "Played" },
    "c": { name: "paused", title: "Paused" },
    "d": { name: "launched", title: "Launched" },
    "e": { name: "attended", title: "Attended" },
    "f": { name: "cc-subtitle-enabled", title: "CC Subtitle Enabled" },
    "g": { name: "interacted", title: "Interacted" },
  };



export const Timeline = () => {
   
    
    const [clientId, setClientId] = useState(-1)
    const [expanded, setExpanded] = useState(-1);
    const [crumbsList, setCrumbsList] = useState([])
    const [lrnElements, setLrnElements] = useState({})
    const [userId, setUserId] = useState(0)
    const [locationId, setLocationId] = useState(0)
    const [courseId, setCourseId] = useState(0)
    const [flag,setFlag]=useState(false)
    const [cstContent, setCstContent] = useState([]);
    const [userIdSel,setUserIdSel] =useState(0);
    const [lrnid,setLrnId]=useState(-1)
    const [courseIdSel,setCourseIdSel]=useState(0)
    const [items, setItems] = useState([]);
    const [selectedTimePoint, setSelectedTimePoint] = useState(-1)
    const dispatch = useDispatch()
    const clientsList = useSelector((state) => state.client.allClientList)
    const clientData = useSelector((state) => state.user.clientData)
    const clientCoursesData = useSelector((state) => state.user.clientCoursesData)
    const companyDataLoader = useSelector((state) => state.user.companyDataLoader)
    const clientUserDataLoader = useSelector((state) => state.user.clientUserDataLoader)
    const containerRef = useRef(null);
    const courseLrnSelRef = useRef(null);
    const { addToast } = useToasts();

    const currentPath = window.location.pathname;
    const containsPath = currentPath.indexOf("timeline") !== -1;
  
   const handleLrnSelect=(e)=>{
    setLrnId(e.target.value)
   }

   const urlParams = useParams()
   

  useEffect(() => {
    const client = urlParams?.client || 0
    const location = urlParams?.location || 0
    if (containsPath) { 
      setClientId(client)
      setLocationId(location)
    }
  }, [])
 
const onClietSelectChange = (e) => {
  setFlag(false)
  setUserIdSel(0)

  let list = []
  const [clientid, clientname] = e.target?.value?.split("-")
  list.push(clientname)
  setCrumbsList([...list])
    setClientId(Number(clientid))
    
 
}

useEffect(() => {

if(!clientData?.[`${clientId}/${locationId}/0/0/0/0/0`]){
  if(clientId>0){
    dispatch(userActions.selectedClientDataRequest({clientId:clientId,locationId:locationId, page: 1,id:1,clientData}))
    dispatch(userActions.selectedClientCoursesDataRequest({clientId:clientId}))
  }
 
}

setSelectedTimePoint(-1)

}, [clientId])


useEffect(() =>{
  const cldata=clientData?.[clientId+`/${locationId}/0/0/0/0/0`]
if(cldata?.data?.length){ 

    const currData = cldata.data.map(key=>{
      const date = new Date(key?.date).toLocaleString('en-US', {
        
        day: '2-digit',
  month: '2-digit',
  year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      });
      return {title:date}
    })

    const cstContentData = cldata.data.map(key => key)
      
    setItems([...currData])
    setCstContent((currState) => [ ...cstContentData])

}
const clLrnTypeData = userIdSel !== 0 && courseIdSel !==0 ?  clientData?.[clientId+`/${locationId}/${userIdSel}/${courseIdSel}/0/0/0`] : {}

if(clLrnTypeData?.data?.length){
  setLrnElements(lrnTypesGenerator(clLrnTypeData.data))
}
}, [clientData,clientId])
    
useEffect(()=>{
if(userId && clientCoursesData?.[clientId] &&!clientCoursesData?.[clientId]?.["users"]?.[userId]){

            addToast(`No courses available for the selected user`, {
              appearance: "error",
              autoDismiss: true,
              placement: "top-right",
            });
           }

},[userId])

        
    const hasReachedEnd = (container) => {
        if (!container) {
          return false;
        }
    
        const { scrollTop, clientHeight, scrollHeight } = container;
     
    
        // Calculate the remaining space at the bottom
        const remainingSpace = scrollHeight - scrollTop - clientHeight;
    
        // Set a threshold for considering it as the end (adjust as needed)
        const threshold = 50;
    
        return remainingSpace <= threshold;
      }

         
      const debounce = (delay) => {
        return (func) => {
          let timeoutId;
            return (e) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
              func(e);
            }, delay);
          };
        };
      }

      const handleUserSelectChange=(e)=>{
          const userid = +e.target.id.split("-")[1]
          setUserIdSel(userid)
          setLrnElements({})
          setLrnId(-1)
          const courseid = clientCoursesData?.[clientId]?.users?.[userid]?.courses?.[0]
          setCourseIdSel(courseid)
          if(!clientData?.[`${clientId}/${locationId}/${userid}/${courseid}/0/0/0`]){

            dispatch(userActions.selectedClientDataRequest({clientId:clientId, locationId:locationId ,courseId:courseid,userId:userid, page: 1,id:1,clientData})) /// remove id later
          }
          else{
            const clLrnTypeData = userid !== 0 && courseid !==0 ?  clientData?.[clientId+`/${locationId}/${userid}/${courseid}/0/0/0`] : {}

if(clLrnTypeData?.data?.length){
  setLrnElements(lrnTypesGenerator(clLrnTypeData.data))
}
          }
      }

      const updateScrollHeight = debounce(200)((e) => {
       
        if (hasReachedEnd(e.target) && clientData?.[`${clientId}/${locationId}/0/0/0/0/0`]?.fetch) {
  
           const page = clientData?.[`${clientId}/${locationId}/0/0/0/0/0`].page
            dispatch(userActions.selectedClientDataRequest({clientId:clientId, locationId:locationId , page: page,id:1,clientData})) /// remove id later
        }
      });
   


      const dataLengthDivisibleBy10 =(length)=>{
        return length%10 === 0
      }
            //  check custom component previewFunctin prop
    const customContent =(data)=>{ 
      return [...data.map((item, i)=>  { 
        return <CustomContent type="" fn={getUserId} index={i} selectedContent={selectedTimePoint} previewFunc = {()=>{}}  keyc={3} data={item}></CustomContent> })]}
   
        const getUserId=(userid, index)=>{
         let list=crumbsList[0] ? [crumbsList[0]] : []
         if(clientCoursesData?.[clientId]?.["users"]?.[userid]?.name){
           list.push(clientCoursesData?.[clientId]?.["users"]?.[userid]?.name)
           setCrumbsList([...list])
         }
         else{
           setCrumbsList(list)
          }
          setExpanded(-1)
          setUserId(userid)
          const courseid = clientCoursesData?.[clientId]?.users?.[userid]?.courses?.[0]
          if(courseid){
            list.push(clientCoursesData?.[clientId]?.["courses"]?.[courseid])
        setCrumbsList([...list])

          }
          setCourseId(courseid)
          if(userid !== userId){
            dispatch(userActions.selectedClientDataRequest({clientId:clientId, locationId:locationId ,courseId:courseid,userId:userid, page: 1,id:1,clientData}))
          }
          setSelectedTimePoint(index)
          setFlag(true)
        }


  const ChevronDownIcon = () => <div className="pe-4 dropdown-icon-user-courses"><i class="fa fa-chevron-down" aria-hidden="true"></i></div>

  // const handleAccordionChange = (course) => (index)=>{
  //   setExpanded((prevExpanded) => (prevExpanded === index ? -1 : index));
  //   if(clientCoursesData?.[clientId]?.["courses"]?.[course]){
  //     let list=[...crumbsList]
  //     if(list.length==3){
  //       list.pop()
  //     }
  //     list.push(clientCoursesData?.[clientId]?.["courses"]?.[course])
  //     setCrumbsList([...list])
  //   }
  //   if(!clientData?.[`${clientId}/0/${userId}/${course}/0/0/0`]){
     
  //     dispatch(userActions.selectedClientDataRequest({clientId:clientId ,courseId:course,userId:userId, page: 1,id:1,clientData}))
  //   }

  // }
 

  const lrnTypesGenerator = (data) => {
    const lrnTypes = {}
    data.forEach(stmt => {
      // to handle data where lrntype is on 7 or 6
      let parts = stmt?.key?.split(":") || [];
      let type = parts[7] || "";
      
      if (type.length > 2 || !type) {
        type = parts[6] || "";
      }
      if(lrnTypes?.[type]){
        lrnTypes[type].push(stmt)
      }
      else{
        
        lrnTypes[type] = [stmt]
      }
    });
    
    return lrnTypes
  }
  const handleUserCourseSelect=(e)=>{
    const course = e.target.value;
    setCourseIdSel(course)
    setLrnElements({})
    setLrnId(-1)
    if(!clientData?.[`${clientId}/${locationId}/${userIdSel}/${course}/0/0/0`]){
    dispatch(userActions.selectedClientDataRequest({clientId:clientId, locationId:locationId ,courseId:course,userId:userIdSel, page: 1,id:1,clientData}))
    }
    else{
      const clLrnTypeData = userIdSel !== 0 && course !==0 ?  clientData?.[clientId+`/${locationId}/${userIdSel}/${course}/0/0/0`] : {}

if(clLrnTypeData?.data?.length){
setLrnElements(lrnTypesGenerator(clLrnTypeData.data))
}
    }
  }
  const handleCourseSelectChange = (e) => {
    const course = e.target.value
    setCourseId(course)
    if(clientCoursesData?.[clientId]?.["courses"]?.[course]){
      let list=[...crumbsList]
      if(list.length==3){
        list.pop()
      }
      list.push(clientCoursesData?.[clientId]?.["courses"]?.[course])
      setCrumbsList([...list])
    }
    
    if(!clientData?.[`${clientId}/${locationId}/${userId}/${course}/0/0/0`]){
      dispatch(userActions.selectedClientDataRequest({clientId:clientId, locationId:locationId ,courseId:course,userId:userId, page: 1,id:1,clientData}))
    }
  }
  
  
  const sortClientList = (list) => {
    const sortedList = [...list]?.sort((a, b) => a.clientname.localeCompare(b.clientname))
    return sortedList
  }

  const seeMoreClick=(course)=>()=>{
    let page = clientData?.[`${clientId}/${locationId}/${userId}/${course}/0/0/0`]?.page

    
    if(clientData?.[`${clientId}/${locationId}/${userId}/${course}/0/0/0`]?.fetch){
      
      dispatch(userActions.selectedClientDataRequest({clientId:clientId,locationId:locationId ,courseId:course,userId:userId, page: page || 1,id:1,clientData}))
    }
    else{
      // document.getElementById(i).classList.add("hidden")
    }
    
  }
 
  
  return (
    <>
    {containsPath && <SideSheet />}
    <div className="timeline-page-wrapper">
    <h3 className={`header-timeline-main ${containsPath?'header-align':''}`}>Interaction Timeline</h3>
    <Box className="tag-box">
      {!containsPath && <div className="client-list" style={{width:"100%"}}>

    <CommonSelect  onChange={onClietSelectChange} data={sortClientList(clientsList)} first="" flag={true} selected={clientId}/>
      </div>}
    {crumbsList.length!==0 && <div className="timeline-breadcrumbs d-flex gap-2">
     {crumbsList.join(" / ")}
    </div>}
    <div className="timeline-container-changed d-flex "> { /*to be changed after getting design*/ }
       <div className="timeline-container-wrapper">
       <h6 className="timeline-header mb-0">User Timeline</h6>
        <div
      onScroll={(e)=>updateScrollHeight(e)}
      ref={containerRef}
      className="timeline-container"
      
      >
     
            <div>
 { clientData?.[`${clientId}/${locationId}/0/0/0/0/0`]?.data.length ? 
  <Chrono
        hideControls
        activeItemIndex={-1}
        
        borderLessCards
        classNames={{ card: `mycard-chrono` , cardText:"card-text", title:"title-chrono"}}
        theme={{
          primary: "#0178E6",
          secondary: "#0178E6",
          // secondary: "transparent",
          // cardBgColor: 'yellow',
          titleColor: "#0178E6",
          titleColorActive: "#0178E6",
        }}
        timelinePointDimension={20}
        cardHeight={"auto"}
        // disableClickOnCircle
        // highlightCardsOnHover
        items={items}
        allowDynamicUpdate
        mode="VERTICAL"
        >
        {cstContent && customContent(cstContent)}
      </Chrono>:<>
      {!companyDataLoader &&<div className="no-data-wrapper">
      <img
      src="https://assets.contentenablers.com/storefront/imgs/aml/aml_search.png"
      width={250}
      />
      <p className="no-client-data"> Details could not be found for the selected client.
            </p>
      </div> 
      
            }
      </>}
            </div>
       
      <div className="loader-wrapper">
        {companyDataLoader && (
          <div className="loader-cnt"> 
            <i class="fa fa-spinner" aria-hidden="true"></i>
            Loading
          </div>
        )}
      </div>
    </div>
        </div> 
    
    
    {flag && clientCoursesData?.[clientId]?.users?.[userId] && 
   <div className="course-accordion-wrapper course-selection-data-wrapper">
    <h6 className="header mb-0">User Course</h6>
     <Box className="course-selector">
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          fullWidth
          value={courseId}
          displayEmpty
          IconComponent={ChevronDownIcon}
          onChange={handleCourseSelectChange}
        >
          {clientCoursesData?.[clientId]?.users?.[userId]?.courses.map((course, i) => {
          
          return <MenuItem key={i+1} value={course}>{clientCoursesData?.[clientId]?.courses?.[course]}</MenuItem>
        })}
        </Select>
      
  
<div className="courses-data-wrap">

{clientData?.[`${clientId}/${locationId}/${userId}/${courseId}/0/0/0`]?.data?.map(item=><div className="courses-data">
  <p className="mb-0 user-timestamp">{moment.utc(item.date).isSameOrAfter(moment().subtract(3, 'months')) ? (
          moment.utc(item.date).startOf('hour').fromNow()
        ) : (
          moment.utc(item.date).format('YYYY-MM-DD h:mm:ss A')
        )}</p>
  <div className="user-comment d-flex gap-2">
    <div className="comment-icon"><i class="fa fa-commenting-o" aria-hidden="true"></i></div>
    <p className="mb-0 comment">
  {item.stmt}
  </p>
  </div>

</div>)}
<div className="loader-wrapper courses-loader-wrapper">
{clientUserDataLoader && clientData?.[`${clientId}/${locationId}/0/0/0/0/0`]?.data.length  && (
  <div className="loader-cnt small-loader">
    <i class="fa fa-spinner" aria-hidden="true"></i>
    Loading
  </div>
)}
</div>
{!clientUserDataLoader && dataLengthDivisibleBy10(clientData?.[`${clientId}/${locationId}/${userId}/${courseId}/0/0/0`]?.data.length) && <button onClick={seeMoreClick(courseId)}  className={`accordion-fetch ${clientData?.[`${clientId}/${locationId}/${userId}/${courseId}/0/0/0`]?.fetch?"":"hidden"}`  }>See More</button>}
</div>
    </Box>
   </div>
}
    </div>
    {clientCoursesData?.[clientId] && <div className="learning-element-data w-100 d-flex">
      <div className="user-selection w-50">
      <h6 className="header-bggrey mb-0">Users</h6>
        <div className="users">

          {Object.keys(clientCoursesData?.[clientId]?.users).map((userid, i) => {
            
            return <div className={`user-box ${userid == userIdSel?'selected-user':''}`} onClick={handleUserSelectChange} key={i+1} id={`user-${userid}`}>{clientCoursesData?.[clientId]?.users[userid].name}</div>
          })}
          </div>
        {/* </Select> */}
        </div> 
       
    {/* <h6 className="header mb-0">User Course</h6> */}
     {userIdSel!==0 && <div className="course-selection w-50">
     <h6 className="header-bggrey mb-0">Learning Aids</h6>
     <div className="courses-lrns-wrapper">
     <div ref={courseLrnSelRef} className="crs-lrns-select d-flex flex-column ">

<Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  fullWidth
  value={courseIdSel}
  displayEmpty
  IconComponent={ChevronDownIcon}
  onChange={handleUserCourseSelect}
  >
  {clientCoursesData?.[clientId]?.users?.[userIdSel]?.courses.map((course, i) => {
  
    return <MenuItem key={i+1} value={course}>{clientCoursesData?.[clientId]?.courses?.[course]}</MenuItem>
  })}
</Select>
{courseIdSel !=0 &&  Object.entries(lrnElements).length !==0 && 
 <Select
 labelId="demo-simple-select-label"
 id="demo-simple-select"
 fullWidth
 value={lrnid !== -1?lrnid:""}
 displayEmpty
 IconComponent={ChevronDownIcon}
 onChange={handleLrnSelect}
 >
  <MenuItem value="" disabled>
            <em>Please select a learning aid</em>
          </MenuItem>
 {Object.entries(lrnElements).length !=0 && Object.entries(lrnElements)?.map((item, i) => {
   
   return <MenuItem key={i+1} value={item[0]}>{lrnList[String(item[0])].title}</MenuItem>
})}
</Select>}
</div>
<div className="courses-lrns" style={{height: `calc(75vh - ${courseLrnSelRef.current?.getBoundingClientRect()?.height||'200'}px)`}}>

       
       {lrnid !== -1 && lrnElements?.[lrnid].map(item => <div className="courses-data">
  <p className="mb-0 user-timestamp">{moment.utc(item.date).isSameOrAfter(moment().subtract(3, 'months')) ? (
          moment.utc(item.date).startOf('hour').fromNow()
        ) : (
          moment.utc(item.date).format('YYYY-MM-DD h:mm:ss A')
        )}</p>
  <div className="user-comment d-flex gap-2">
    <div className="comment-icon"><i class="fa fa-commenting-o" aria-hidden="true"></i></div>
    <p className="mb-0 comment">
  {item.stmt}
  </p>
  </div>

</div>)}
{companyDataLoader && ( <div className="loader-wrapper">
        
          <div className="loader-cnt"> 
            <i class="fa fa-spinner" aria-hidden="true"></i>
            Loading
          </div>
        
      </div>)}
{!companyDataLoader && Object.entries(lrnElements).length ===0 && <div className="empty-lrn-box d-flex flex-column align-items-center" >
  <img src="https://assets.contentenablers.com/storefront/imgs/Empty_Box.png" alt="Empty"/>
  <p className="fw-bold">This course has no learning aids</p>
  </div>}
       </div>
     </div>
     
       
    </div>}
   </div>
        }
    </Box>
</div>
    </>
  )
}

