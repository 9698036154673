import React from 'react';
import ProfileIcon from '../../assets/img/ProfileIcon.png';

const CourseComments = ({ commentsList ,itemInfo}) => {
  let today = new Date();

  return (
    <div>
      <div className='author-info-main'>
        <p className='author-id'>ID No: <span >{itemInfo?._id}</span></p>
        <div className='author-list  row'>

          <div className='col-md-6'>
            <p className='topic-title'>Author</p>
            <p className='author-name'>{itemInfo?.author?.fname}</p>
          </div>
          <div className='col-md-6'>
            <p className='topic-title'>Created on</p>
            <p className='created-date'>{today.toLocaleDateString("en-US", itemInfo?.createdAt)}</p>
          </div>
          {commentsList.length > 0 ?
            < >
              <div className='col-md-6'>
                <p className='topic-title'>Modified by </p>
                <p className='author-name'>{commentsList[0].name} </p>
              </div>
              <div className='col-md-6'>
                <p className='topic-title'>Last updated on </p>
                <p className='created-date'> {today.toLocaleDateString("en-US", commentsList[0].modified)}</p>
              </div>
            </ >
            : <><p>Loading...</p></>}
        </div>
      </div>
      <br/><br/>
      {commentsList.length > 0 ? commentsList.map((item, index) => {
        return <div className='author-comment-section' key={item._id.toString()}>
          <div className='comment-row row'>
            <div className='profile-badge col-md-2' style={{ textAlign: 'center' }}>
              <img src={ProfileIcon} alt="" className='badge-tag' />
            </div>
            <div className='col-md-6'>
              <p className='profile-name '>{item.name}</p>

              <p className='modified-date'>{today.toLocaleDateString("en-US", item.modified)} </p>
            </div>
            <div className='profile-type col-md-3'>
              <p>Author</p>
            </div>
          </div>
          <p><span className='tag-text'> </span>{item.comments}</p>
        </div>
      }) : <h4 style={{ textAlign: 'center' }}>No Data found</h4>}
    </div>
  )
}

export default CourseComments