import React from 'react'
import Card from './CardT'

function CustomContent({ type,data, selectedContent,index, previewFunc,keyc,fn }) {
  
  const handleClick=(data,keyc)=>(e)=>{
    
    
    if(fn){

      fn(data.key.split(":")[keyc], index)
    } 
}
  switch (type) {

      
    case 'card': return <Card data={data} preview = {previewFunc}></Card>
      
  
    default:
      return <div className={`content-container ${selectedContent === index? "highlighted" : ""}`}>
<div onClick={handleClick(data,keyc)} className={`content-wrapper `}>
  <div className='arrow-icon'><i class="fa fa-chevron-right" aria-hidden="true"></i></div>
            <div className='d-flex align-items-center gap-2 user-detail'>
              <div className='user-image d-flex justify-content-center'> <i class="fa fa-user-circle-o" aria-hidden="true"></i> </div>
              <div className='user-name-email'>
              <h3 className='name mb-0'>{data.name}</h3>
              <p className='desc email mb-0'>{data.email}</p>
              </div>
            </div>
            <div className='d-flex align-items-center gap-2 user-comment mt-2'>
              <div className='comment-icon d-flex justify-content-center'><i class="fa fa-commenting-o" aria-hidden="true"></i></div> 
            <p className='comment mb-0'>{data.stmt}</p>
            </div>
            {/* <h3>{data.name}</h3>
            <p className='desc'>{data.email}</p>
            <p className='content'>{data.stmt}</p>
             <div className='preview'>
    </div> */}
        
        </div>
  </div>
  }
}

export default CustomContent