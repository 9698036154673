import React from "react";
import "./SideNav.scss";
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from "../../redux/actions/utilityActions";
import * as userActions from "../../redux/actions/userActions";
import PrivilegeHelper from "../../utility/PrivilegeHelper";

const SideNav = ({ user }) => {
  const dispatch = useDispatch();
  const utilityInfo = useSelector((state) => state.utility);
  const userInfo = useSelector((state) => state.user);
  //id 5 using as history
  let menuItems = [
    { id: 1, icon: "fa fa-address-card", name: "List of Courses" },
  ];

  if (PrivilegeHelper(user?.role).getAccess()) {
    menuItems.push({ id: 3, icon: "fa fa-bank", name: "Roles / Permissions" });
    menuItems.push({ id: 6, icon: "fa fa-tags", name: "Admin Tags" });
    // menuItems.push({ id: 7, icon: "fa fa-question", name: "Add AML Questionnaire" });
    menuItems.push({
      id: 10,
      icon: "fa fa-table",
      name: "AML - Companies",
    });
    menuItems.push({
    id: 11,
    icon: "fa fa-area-chart",
    name: "Learner Analytics",
    });
    menuItems.push({
    id: 12,
    icon: "fa fa-tasks",
    name: "Timeline",
    });
    menuItems.push({
      id: 13,
      icon: "fa fa-map",
      name: "Active User Session",
      });
  }

  if (utilityInfo.selectedMenuId === 4) {
    menuItems.push({ id: 4, icon: "fa fa-magic", name: "Supplementaries" });
  }

  const setSelectedMenu = (id) => {
    if (id === 6 && !userInfo.adminTags?.data?.assetmgr_config) {
      dispatch(userActions.adminTagsRequest());
    }
    dispatch(utilityActions.setSelectedMenu(id));
  };

  return (
    <div
      className={`ce-admin-side-nav ${
        utilityInfo.isSideNavOpen ? "side-nav-open" : "side-nav-close"
      }`}
    >
      <ul className="menu-items">
        {menuItems.length > 0 &&
          menuItems.map((menu) => {
            return (
              <li
                key={menu?.id}
                className={
                  menu?.id === utilityInfo.selectedMenuId
                    ? "active-menu"
                    : "list-item"
                }
                onClick={() => setSelectedMenu(menu?.id)}
              >
                <div className="icon-item">
                  <i className={menu.icon} title={menu.name} />
                </div>
                <div className="menu-item">{menu.name}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default SideNav;
