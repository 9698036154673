import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import * as utilityActions from '../../redux/actions/utilityActions';
import * as userActions from '../../redux/actions/userActions';
import './Vignettes.scss';
import SupplimentaryPanel from './SupplimentaryPanel';
import CeAssets from '../../assets/img/CeAssets.png';
import { useToasts } from 'react-toast-notifications';
import APIConstant from '../../utility/APIConstant';

const Vignettes = () => {
    const { addToast } = useToasts();
    const userInfo = useSelector(state => state?.user);
    const utility = useSelector(state => state.utility);
    const Vignettes = userInfo?.supplementaries?.vignettes || [];
    const [selectedIds, setSelectedIds] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const dispatch = useDispatch();

    const courseId = utility?.selectedCourseUpdates?._id;

    useEffect(() => {
        // Load selectedIds from session storage on mount
        const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
        if (courseId && savedSelections[courseId]?.vignette) {
            setSelectedIds(savedSelections[courseId].vignette);
        }
    }, [courseId]);
    
    useEffect(() => {
        // Only save to sessionStorage if selectedIds is populated
        if (selectedIds.length > 0) {
            const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
            if (courseId) {
                savedSelections[courseId] = {
                ...savedSelections[courseId],
                vignette: selectedIds
            };
                sessionStorage.setItem('vignetteSelections', JSON.stringify(savedSelections));
            }
        }
    }, [selectedIds, courseId]);
    const handleEdit = (item) => {
        dispatch(utilityActions.toggleSideSheet({
            direction: 'right',
            width: 800,
            title: 'Edit Vignette',
            open: true,
            component: <SupplimentaryPanel name={'vignettes'} mode={'Edit'} data={item} />
        }));
    };

    const handleSave = () => {
        let reqObj = {
            configid: courseId,
            vids: selectedIds,
            type: 9
        };
        dispatch(userActions.addConfigVignettesRequest(reqObj, addToast));
        // dispatch(utilityActions.setSelectedSupplimentaryReq(utility?.selectedCourseUpdates, 1));

        // Clear session storage for this course
        // const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
        // if (courseId && savedSelections[courseId]) {
        //     delete savedSelections[courseId];
        //     sessionStorage.setItem('vignetteSelections', JSON.stringify(savedSelections));
        // }

        // // Clear local state
        // setSelectedIds([]);
        // setSelectAll(false);
    };

    // const handleSelectAll = () => {
    //     if (selectAll) {
    //         setSelectedIds([]); // Deselect all
    //     } else {
    //         setSelectedIds(Vignettes.map(v => v?._id)); // Select all
    //     }
    //     // setSelectAll(!selectAll);
    // };
    const handleSelectAll = () => {
        if (selectedIds.length === Vignettes.length) {
            setSelectedIds([]); // Deselect all
                const savedSelections = JSON.parse(sessionStorage.getItem('vignetteSelections')) || {};
                savedSelections[courseId] = {
                ...savedSelections[courseId],
                vignette: []
            };
                sessionStorage.setItem('vignetteSelections', JSON.stringify(savedSelections));
        } else {
            setSelectedIds(Vignettes.map(v => v._id)); // Select all
        }
    };
    const toggleSelect = (id) => {
        setSelectedIds((prevSelected) => {
            if (prevSelected.includes(id)) {
                return prevSelected.filter(itemId => itemId !== id);
            }
            return [...prevSelected, id];
        });
    };

    return (
        <div className='vignettes-continer'>
            {!Vignettes?.length ? (
                <p className='no-data'>Currently there is no vignettes available...</p>
            ) : (
                <>
                    <div className="select-all-container">
                        <button
                            className={`select-all-button ${selectAll ? 'selected' : ''}`}
                            onClick={handleSelectAll}
                        >
                            {selectedIds.length === Vignettes.length ? 'Deselect All' : 'Select All'}
                            {/* {selectAll ? 'Deselect All' : 'Select All'} */}
                        </button>
                    </div>
                    <div className="row thamp-list">
                        {Vignettes.map((el, idx) => {
                            const isSelected = selectedIds.includes(el?._id);
                            return (
                                <div key={idx} className="col-lg-4 col-md-6 col-xs-24 thamp-list-item">
                                    <div
                                        className={`inner-content ${isSelected ? 'selected' : ''}`}
                                        onClick={() => toggleSelect(el?._id)}
                                    >
                                        <img
                                            src={APIConstant?.getFilePath(el?.content?.thumb)}
                                            onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = CeAssets;
                                            }}
                                            alt="Thumbnail"
                                        />
                                        <p className="content">{el?.content?.title}</p>
                                        <div className="icons-container">
                                            {isSelected && <div className="check-icon">✔</div>}
                                            <a
                                                href={el?.content?.path}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="play-icon"
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                ▶
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="save-button-container">
                        <button
                            className="save-button"
                            disabled={selectedIds.length === 0}
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};

export default Vignettes;
