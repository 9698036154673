import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import './RichTextEditor.scss'



const RichTextEditor = ( { data, eventHandler, height } ) => {

    if ( !data ) {
        data = '<p></p>'
    }


    const contentBlock = htmlToDraft( data );
    const contentState = ContentState.createFromBlockArray( contentBlock.contentBlocks );
    const [ editorState, setEditorstate ] = useState( EditorState.createWithContent( contentState ) )


    const onEditorStateChange = ( state ) => {
        setEditorstate( state );
    }

    /** Initial save button active check TODO */
    useEffect( () => {
        try {
            let html = draftToHtml( convertToRaw( editorState.getCurrentContent() ) )
            eventHandler(html)
        } catch ( e ) {
            console.log( e );
        }
    }, [ editorState ] )


    const editorStyle = {
        height: height ? height : window.innerHeight - 500,
        padding: '.5rem'
    }

    return (
        <div className='ck-editor-container'>
            <Editor
                editorState={ editorState }
                wrapperClassName="richtxt-editor-wrapper-class"
                editorStyle={ editorStyle }
                editorClassName="demo-editor"
                onEditorStateChange={ onEditorStateChange }
                toolbar={ {
                    // options: ['inline', 'blockType', 'fontSize', 'textAlign', 
                    //           'history', 'colorPicker'],
                    options: [ 'inline', 'blockType', 'fontSize', 'fontFamily', 'list','colorPicker', 'link', 'textAlign',  'remove', 'history' ],
                    inline: {
                        options: [ 'italic', 'bold' ],
                        bold: { className: 'demo-option-custom' },
                        italic: { className: 'demo-option-custom' },
                        underline: { className: 'demo-option-custom' },
                        strikethrough: { className: 'demo-option-custom' },
                        monospace: { className: 'demo-option-custom' },
                        superscript: { className: 'demo-option-custom' },
                        subscript: { className: 'demo-option-custom' }
                    },
                    blockType: {
                        className: 'demo-option-custom-wide',
                        dropdownClassName: 'demo-dropdown-custom'
                    },
                    fontSize: { className: 'demo-option-custom-medium' },
                    fontFamily: {
                        options: [ 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana', 'WorkSansRegular', 'Roboto' ],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    }

                } }
            />
        </div>
    )
}



RichTextEditor.propTypes = {
    /** data props used to map the content innerhtml to the  server*/
    data: PropTypes.string
}

export default RichTextEditor
