import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import * as utilityActions from "../../redux/actions/utilityActions";
import "./Analytics.scss";
import copy from "../../assets/img/ic_content-copy.svg";
import {
  companyDataRequest,
  selectedCompanyDataRequest,
  setCompanyLoader,
  resetProgramWriter
} from "../../redux/actions/userActions";
import Box from "@mui/material/Box";
import RoleStatsAccordion from "./accordion/RoleStatsAccordion";
import DetailCard from "./CommonCard/DetailCard";
import { useToasts } from "react-toast-notifications";
import { Autocomplete, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { isObject } from "formik";

export const Analytics = () => {
  const [selectedTab, setSelectedTab] = useState(1);

  const [delegationData, setDelegationData] = useState(false);

  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState({});
  const [delegationCard, setDelegationCard] = useState(null);
  const [resetLoader, setResetLoader] = useState(false);
  const [flag, setFlag] = useState(false);
  const [searchData, setsearchData] = useState(null);
  const compData = useSelector((state) => state.user.companyData);
  const CompanyData = useSelector((state) => state.user.companyStats);
  const companySeats = useSelector((state) => state.user.companySeat);
  const loader = useSelector((state) => state.user.companyDataLoader);
  const { addToast } = useToasts();

  const defaultProps = {
    options: flag ? searchData : compData,
    getOptionLabel: (option) => {
      return option?.company || "";
    },
  };

  const getData = (newData) => {
    let filterData = {
      Primary_Owner: {},
      Compliance_Officer: [],
      Other_Owners: [],
      Employees: [],
      employee_info: [],
      Name: "",
      stage: "",
      id: "",
      riskAssesment: null,
      programWriter: null,

      other_enties: [],
    };
    for (let i in filterData) {
      if (newData["0"]?.stages?.[0]?.progress?.[i]) {
        if (i !== "Primary_Owner") {
          filterData[i] = [
            ...newData?.["0"]?.stages[0]?.progress[i].map((item) => {
              return { ...item, email: item?.email || item?.emailAddress };
            }),
          ];
        } else {
          filterData[i] = {
            ...newData["0"]?.stages[0]?.progress[i],
            role: "Primary owner",
          };
        }
      }
    }

    filterData["Employees"] = [
      ...filterData["employee_info"],
      ...filterData["Employees"],
    ];

    if (newData["0"]?.stages?.[0]?.progress) {
      filterData.id = newData["0"]?._id;
      filterData.Name = newData["0"]?.company;
      let entities = newData["0"]?.stages[0]?.progress?.entities;
      for (let key in entities) {
        filterData.other_enties.push(entities[key].name);
      }
    }
    if (newData["0"]?.stages?.[0].progress?.dual_role) {
      let dual_role = newData["0"]?.stages?.[0]?.progress?.dual_role;
      if (dual_role?.oo) {
        dual_role?.oo?.forEach((item) => {
          if (item && item === filterData?.Compliance_Officer?.[0]?.id) {
            filterData.Other_Owners.push(filterData?.Compliance_Officer?.[0]);
          }
        });
      }
      if (dual_role?.ep) {
        dual_role?.ep?.forEach((item) => {
          if (item && item === filterData?.Compliance_Officer?.[0]?.id) {
            filterData.Employees.push(filterData.Compliance_Officer?.[0]);
          }
          if (item !== filterData.Compliance_Officer?.[0]?.id) {
            filterData.Other_Owners.forEach((owner) => {
              if (owner?.id === item) {
                filterData.Employees.push(owner);
              }
            });
          }
        });
      }
    }
    // if (newData["0"]?.stages?.[0].progress?.dual_role) {
    //   let dual_role = newData["0"].stages[0].progress?.dual_role;
    //   // if (dual_role?.oo) {
    //   //   // filterData.Other_Owners.push(
    //   //   //   ...dual_role?.oo.map((item) => {
    //   //   //     return { ...item, email: item.email || item.emailAddress };
    //   //   //   })
    //   //   // );
    //   //   dual_role?.oo?.forEach((item) => {
    //   //     const dual_email = item?.email || item?.emailAddress;
    //   //     const data_unique = filterData.Other_Owners.filter((key) => {
    //   //       const oo_email = key?.email || key?.emailAddress;
    //   //       return oo_email === dual_email;
    //   //     });

    //   //     if (data_unique.length === 0) {
    //   //       filterData.Other_Owners.push({
    //   //         ...item,
    //   //         email: item?.email || item?.emailAddress,
    //   //       });
    //   //     }
    //   //   });
    //   // }

    //   if (dual_role?.ep) {
    //     // filterData.Employees.push(
    //     //   ...dual_role?.ep.map((item) => {
    //     //     return { ...item, email: item.email || item.emailAddress };
    //     //   })
    //     // );
    //     dual_role?.ep?.forEach((item) => {
    //       const dual_email = item?.email || item?.emailAddress;
    //       const data_unique = filterData.Employees.filter((key) => {
    //         const emp_email = key?.email || key?.emailAddress;
    //         return emp_email && dual_email && emp_email === dual_email;
    //       });

    //       if (data_unique.length === 0) {
    //         filterData.Employees.push({
    //           ...item,
    //           email: item?.email || item?.emailAddress,
    //         });
    //       }
    //     });
    //   }
    // }

    if (
      newData["0"].stages?.[0]?.progress?.["risk_assessment_delegate"] ===
      filterData.Compliance_Officer?.[0]?.id
    ) {
      filterData.riskAssesment = "co";
    } else {
      filterData.riskAssesment = "po";
    }

    if (
      newData["0"].stages?.[0]?.progress?.["compliance_policies_delegate"] ===
      filterData.Compliance_Officer?.[0]?.id
    ) {
      filterData.programWriter = "co";
    } else {
      filterData.programWriter = "po";
    }

    filterData.stage = getStage(newData).stage_name;
    return filterData;
  };

  const getStage = (newData) => {
    let stage = -1,
      latest_stage_status;
    if (newData["0"]?.stages) {
      stage = newData["0"]?.stages.length;
      latest_stage_status = newData["0"]?.stages[stage - 1]?.status;
    }
    switch (stage) {
      case 1:
        return {
          stage_name: "On Boarding",
          status: latest_stage_status,
        };
      case 2:
        return {
          stage_name: "Risk Assessment",
          status: latest_stage_status,
        };
      case 3:
        return {
          stage_name: "Program Writer",
          status: latest_stage_status,
        };
      default:
        return {
          stage_name: null,
          status: null,
        };
    }
  };

  let specificCompanyData = {};

  const handleTabSelect = (e) => {
    setSelectedTab(Number(e.target.id));
  };

  // const isValidSearchInput = (input) => {
  //   const regex = /^[a-zA-Z0-9_\- ]*$/;
  //   return regex.test(input);
  // };

  if (Object.keys(CompanyData).length) {
    specificCompanyData = getData(CompanyData);
  }

  const getDualRole = (newData, checkRole) => {
    let roles = "";
    if (checkRole === "co") {
      if (newData?.oo) {
        roles += ", Other Owner";
      }
      if (newData?.ep) {
        roles += ", Employee";
      }
    } else if (checkRole === "oo") {
      if (
        newData?.ep ||
        specificCompanyData?.Employees.filter(
          (item) =>
            item?.email && newData?.email && item?.email === newData?.email
        ).length
      ) {
        roles += ", Employee";
      }
      if (
        specificCompanyData?.Compliance_Officer?.[0]?.email &&
        newData?.email === specificCompanyData?.Compliance_Officer?.[0]?.email
      ) {
        roles += ", Compliance Officer";
      }
    } else if (checkRole === "ep") {
      if (
        specificCompanyData?.Other_Owners.filter(
          (item) =>
            item?.email && newData?.email && item?.email === newData?.email
        ).length
      ) {
        roles += ", Other Owner";
      }
      if (
        specificCompanyData?.Compliance_Officer?.[0]?.email &&
        newData?.email === specificCompanyData?.Compliance_Officer?.[0]?.email
      ) {
        roles += ", Compliance Officer";
      }
    }
    return roles;
  };

  const delegationTab = ["Risk Assessment", "Program Writer"];
  const handleCompanyId = (e, id) => {
    navigator.clipboard.writeText(id);
    addToast("ID Copied Successfully", {
      appearance: "success",
      autoDismiss: true,
      placement: "top-right",
    });
  };
  const getDelegationCard = (deleg, data) => {
    if (deleg === "po") {
      return (
        <DetailCard
          fname={data?.Primary_Owner?.fname}
          lname={data?.Primary_Owner?.lname}
          role="Primary Owner"
          job_title={data?.Primary_Owner?.job_title}
          email={data?.Primary_Owner?.email}
          score={data?.Primary_Owner?.score ? data?.Primary_Owner?.score : 0}
          phone_number={
            data?.Primary_Owner?.phone ? data?.Primary_Owner?.phone : "NA"
          }
        />
      );
    } else if (deleg === "co") {
      return (
        <DetailCard
          name={data?.Compliance_Officer?.[0]?.name}
          role={
            "Compliance Officer" +
            getDualRole(data?.Compliance_Officer?.[0], "co")
          }
          email={data?.Compliance_Officer?.[0]?.email}
          job_title={data?.Compliance_Officer?.[0]?.job_title}
          score={
            data?.Compliance_Officer?.[0]?.score
              ? data?.Compliance_Officer?.[0]?.score
              : 0
          }
          phone_number={
            data?.Compliance_Officer?.[0]?.phone
              ? data?.Compliance_Officer?.[0]?.phone
              : "NA"
          }
        />
      );
    } else return <></>;
  };

  useEffect(() => {
    if (selectedTab === 1) {
      setDelegationData(specificCompanyData?.riskAssesment);
    }
    if (selectedTab === 2) {
      setDelegationData(specificCompanyData?.programWriter);
    }
  }, [selectedTab, selectedCompany, CompanyData]);

  // const [searchTimeout, setSearchTimeout] = useState(null);
  // console.log(specificCompanyData);

  // const handleSearch = (e) => {
  //   if (isValidSearchInput(e.target.value)) {
  //     setSearch(e.target.value);
  //     if (searchTimeout) {
  //       clearTimeout(searchTimeout);
  //     }
  //     const newTimeout = setTimeout(() => {
  //       dispatch(companyDataRequest(e.target.value.trim()));
  //     }, 300);
  //     setSearchTimeout(newTimeout);
  //   } else {
  //     addToast("Do not use speical charachters other than '_' and '-'", {
  //       appearance: "warning",
  //       autoDismiss: true,
  //       placement: "top-left",
  //     });
  //   }
  // };

  useEffect(() => {
    // console.log("hiiiii");
    setDelegationCard(getDelegationCard(delegationData, specificCompanyData));

    const stages = CompanyData?.["0"]?.stages || []
    const check = stages.find((item) => item?.stageid === 3 && item?.status !== 1)
    if(check){
      setResetLoader(false)
    }
  }, [delegationData, CompanyData]);

  function containsWholeLine(paragraph, targetString) {
    const regex = new RegExp(`.*${targetString}.*`, "i");
    return regex.test(paragraph);
  }
  const handleSelection = (e, data) => {
    const temp = compData.filter((item) =>
      containsWholeLine(item?.company, data?.company || "")
    );
    setsearchData(temp);
    dispatch(setCompanyLoader(true));
    dispatch(selectedCompanyDataRequest(data?._id));
    setDelegationCard(getDelegationCard(delegationData, specificCompanyData));
  };

  // const handleSelection = (e, data) => {
  //   setSearch(data?.company?.trim());
  //   setShow(false);
  //   if (data?.company) {
  //     dispatch(companyDataRequest(data?.company?.trim()));
  //   }

  //   setSelectedCompany(data);
  //   dispatch(selectedCompanyDataRequest(data?._id));
  //   setDelegationCard(getDelegationCard(delegationData, specificCompanyData));
  // };
  // console.log(specificCompanyData);
  const handleAutoCompleteChange = (event, value) => {
    if (!isObject(value) && value !== null) {
      if (value?.trim() === "") {
        addToast(`Company name cannot be empty`, {
          appearance: "warning",
          autoDismiss: true,
          placement: "top-right",
        });
        return;
      }
      addToast(`No company found with following ${value} name`, {
        appearance: "warning",
        autoDismiss: true,
        placement: "top-right",
      });
    } else {
      handleSelection(event, value);
      setFlag(true);
    }
    // console.log("Selected:", value);
  };

  const buildPayload = (cmpId, stageId) => ({
    id: cmpId,
    pwReset: {
      id: cmpId,
      stageid: stageId,
      status: 0,
      progress: {
        data: []
      }
    }
  });
//  const reset =(cmpId,stageid)=>{
//   if(stageid===1){
//     setResetLoader(true)
//     let payload = buildPayload(cmpId,1)
//     dispatch(resetProgramWriter(payload))
//     payload = buildPayload(cmpId,3)
//     dispatch(resetProgramWriter(payload))
    
//   }
//   else{
//     let payload = buildPayload(cmpId,3)
//     dispatch(resetProgramWriter(payload))

//   }
//  }
  const resetPwBtnClick = (cmpId) => (e) => {
    const payload = buildPayload(cmpId,3)
    setResetLoader(true)
    dispatch(resetProgramWriter(payload))
    
  }
  const resetRABtnClick = (cmpId) => (e) => {
   
    resetPwBtnClick(cmpId)
    const payload=buildPayload(cmpId,2)
    const payload2=buildPayload(cmpId,3)
    dispatch(resetProgramWriter(payload2))
    dispatch(resetProgramWriter(payload))
    
  }

  // checks if user is on program writer stage and completed, if Yes than reset button will show
  const validateStagePW = () => {
   
    const stages = CompanyData?.["0"]?.stages || []
    const cmpId = CompanyData?.["0"]?._id || 0
    const check = stages.find((item) => item?.stageid === 3 && item?.status === 1)
    const check2= stages.find((item) => item?.stageid === 2 && item?.status === 1)
    if(selectedTab===1){
      if(check2){
        return <>
        {/* Reset Program Writer Added */}
        <div className="reset-program-writer text-end my-3">
            <Button className="reset-btn" disabled={resetLoader} variant="contained" onClick={resetRABtnClick(cmpId)}>
              <>
              <span>Reset Risk Assessment</span>
              {resetLoader && (
                <i class="fa fa-circle-o-notch ms-2" aria-hidden="true"></i>
              ) }
              </>
              </Button>
        </div>
        </> 
      }
    }
    else{
      if(check){
        return <>
        {/* Reset Program Writer Added */}
        <div className="reset-program-writer text-end my-3">
            <Button className="reset-btn" disabled={resetLoader} variant="contained" onClick={resetPwBtnClick(cmpId)}>
              <>
              <span>Reset Program Writer</span>
              {resetLoader && (
                <i class="fa fa-circle-o-notch ms-2" aria-hidden="true"></i>
              ) }
              </>
              </Button>
        </div>
        </> 
      }
  
    } 
    }


  return (
    <div className="analytics-container">
      <div className="analytics-title">
        <p>Search Company Details</p>
      </div>

      <Box className="tag-box">
        <div className="search-bar-auto mb-5">
          <Autocomplete
            {...defaultProps}
            id="auto-complete"
            freeSolo
            onChange={handleAutoCompleteChange}
            autoComplete
            includeInputInList
            renderOption={(props, item) => {
              return (
                <li {...props} key={item.id}>
                  {item?.company}
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    label="Search Company"
                    onChange={(event, newValue) => {
                      setFlag(false);
                    }}
                  />
                  <div className="search-icon-auto">
                    {loader ? (
                      <i class="fa fa-circle-o-notch" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-search " aria-hidden="true"></i>
                    )}
                  </div>
                </>
              );
            }}
          />
        </div>

        {/* <div className="company-search mb-5">
          <div className="search">
            <input
              type="text"
              id="search-company"
              onChange={handleSearch}
              value={search}
              onFocus={() => setShow(true)}
              onBlur={() =>
                setTimeout(() => {
                  setShow(false);
                }, 275)
              }
            />
            <i
              class="fa fa-search "
              style={{ color: "#13984b" }}
              aria-hidden="true"
            ></i>
            {show && search.length !== 0 && (
              <>
                {compData.length !== 0 && (
                  <div className="dropdown">
                    {compData?.map((item) => {
                      return (
                        <div
                          className="pointer"
                          onClick={(e) => {
                            handleSelection(e, item);
                          }}
                        >
                          {item.company}
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            )}
            {}
          </div>
        </div> */}
        {Object.keys(specificCompanyData).length !== 0 && (
          <>
            <div className="d-flex justify-content-between company-details">
              <div className="company-info">
                <div className="d-flex justify-content-between pb-4">
                  <div className="w-50">
                    <p className="company-info-item">Company Name</p>
                    <p className="company-data">{specificCompanyData.Name}</p>
                  </div>
                  <div className="w-50">
                    <p className="company-info-item">Current Status</p>
                    <p className="company-data">{specificCompanyData.stage}</p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="company-info w-50">
                    <p className="company-info-item">Company ID</p>

                    <p className="company-data ">
                      {specificCompanyData.id.slice(0, 5)}...
                      {specificCompanyData.id.slice(-3)}
                      <span
                        style={{ width: "30px" }}
                        className="ml-auto d-inline-flex justify-content-center"
                      >
                        <img
                          src={copy}
                          alt="copy"
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            handleCompanyId(e, specificCompanyData.id)
                          }
                        ></img>
                        {/* <i
                          style={{ cursor: "pointer" }}
                          class="fa fa-clone"
                          onClick={(e) =>
                            handleCompanyId(e, specificCompanyData.id)
                          }
                        ></i> */}
                      </span>
                    </p>
                    <p className="company-info-item pt-4">Other Entities</p>
                    {specificCompanyData.other_enties.map((item) => (
                      <p className="company-data">{item}</p>
                    ))}
                  </div>
                  <div className="w-50">
                    <p className="company-info-item">Seats</p>
                    <p className="company-data-seats">
                      Primary Owner :{" "}
                      <span>
                        {companySeats?.["1"] ||
                          (Object.keys(specificCompanyData?.Primary_Owner)
                            .length !== 0
                            ? 1
                            : "NA")}{" "}
                      </span>
                    </p>
                    <p className="company-data-seats">
                      Compliance Officer :{" "}
                      <span>
                        {companySeats?.["3"] ||
                          specificCompanyData?.Compliance_Officer?.length ||
                          "NA"}
                      </span>
                    </p>
                    <p className="company-data-seats">
                      Other Owners :
                      <span>
                        {" "}
                        {companySeats?.["2"] ||
                          specificCompanyData?.Other_Owners?.length ||
                          "NA"}{" "}
                      </span>
                    </p>
                    <p className="company-data-seats">
                      Employees :{" "}
                      <span>
                        {companySeats?.["4"] ||
                          specificCompanyData?.Employees?.length ||
                          "NA"}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="company-delegation-details">
                <p className="delegation-box-title">Delegation</p>
                {Object.keys(specificCompanyData?.Primary_Owner).length ? (
                  <>
                    <div className="delegation-tabs">
                      {delegationTab.map((item, i) => {
                        return (
                          <div
                            className={
                              selectedTab === i + 1 ? "tab selected" : "tab"
                            }
                            onClick={handleTabSelect}
                            key={i + 1}
                            id={i + 1}
                          >
                            <p>{item}</p>
                          </div>
                        );
                      })}
                    </div>

                    <div className="delegation-card">
                      {delegationData ? delegationCard : <></>}
                    </div>
                  </>
                ) : (
                  <p>No data available</p>
                )}
              </div>
            </div>
        {/* if program writer stage is present than button will appeat for reset of program writer */}
        {validateStagePW()}
            <RoleStatsAccordion
              getDualRole={getDualRole}
              specificCompanyData={specificCompanyData}
            />
          </>
        )}
        {Object.keys(specificCompanyData).length === 0 && (
          <div className="d-flex flex-column align-items-center gap-2 mt-5">
            <p className="fs-4 mb-0 fw-medium">
              {/* No data found, Please search a company! */}
              The company details you searched for could not be found.
            </p>
            <img
              src="https://assets.contentenablers.com/storefront/imgs/aml/aml_search.png"
              width={250}
            />
          </div>
        )}
      </Box>
    </div>
  );
};
