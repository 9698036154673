import React from "react"
import { useSelector } from "react-redux";
import CoursesSelection from "./CoursesSelection";
import './CoursesPanel.scss'

const CoursesPanel = () => {

    const userInfo = useSelector( state => state.user );
    return <>
            <div className="row">
                {/* <div className="col-lg-6 col-md-12 col-sm-12">
                    <h5 className="title-uppercase">All Courses List</h5>
                    <CoursesSelection />
                </div> */}
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <h5 className="title-uppercase">Assigned Courses List</h5>
                    {/* should be change components */ }
                    { userInfo?.assignedCourses.length > 0 && <ul className="courses-list">
                        { userInfo?.assignedCourses.map( ( course, index ) => {
                            return <li key={ index }>{ course.coursename }</li>
                        } ) }
                    </ul> }
                    {userInfo?.assignedCourses.length === 0 && <p>No Courses Found..</p>}
                </div>

            </div>
    </>
}

export default CoursesPanel