import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import APIConstant from '../../utility/APIConstant';
import GoogleMapComponent from './GoogleMapComponent';
import './MapComponent.scss';
const MapComponent = () => {
  const users = APIConstant.USERS;
  const [selectedUser, setSelectedUser] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [reset, setReset] = useState(true); // to reset map

  const handleMarkerClick = (user) => {
    setSelectedUser(user);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedUser(null);
  };

  return (
    <div>
      <div className='head-for-activeSession'>
      <h2 className={`header-timeline-main`}>Active User Session</h2>
      <button onClick={()=>{
        setReset(!reset)
        setSelectedUser(null)
      }}>Reset Map</button>
      </div>
      <GoogleMapComponent onMarkerClick={handleMarkerClick} reset={reset}/>
      <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
        <List>
          <ListItem>
            <ListItemText primary="User Details" />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          {selectedUser && (
            <>
              <ListItem>
                <ListItemText primary="User ID" secondary={selectedUser.uid} />
              </ListItem>
              <ListItem>
                <ListItemText primary="IP Address" secondary={selectedUser.browser_info.ipaddress} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Browser" secondary={selectedUser.browser_info.name} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Location" secondary={`${selectedUser.geo.city}, ${selectedUser.geo.state}, ${selectedUser.geo.country}`} />
              </ListItem>
            </>
          )}
        </List>
      </Drawer>
    </div>
  );
};

export default MapComponent;
