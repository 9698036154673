import { optionCalenderHeatmap, optionCartesianHeatmap, optionLineChart, optionPieChart, optionRadarChart } from "./EchartTypes";

/**
 * Generate heatmap data for a specific year and month, considering leap years.
 *
 * @param {object} data - Data containing information for each year and month.
 * @param {string} year - The selected year.
 * @param {number} currMonth - The current month to generate data for (default is null).
 * @returns {Array<Array>} - Heatmap data for the specified year and month.
 */

const isValidDate = (year, month, day) => {
  const date = new Date(year, month - 1, day);
  return (
    date.getFullYear() === year &&
    date.getMonth() === month - 1 &&
    date.getDate() === day
  );
};

const returnNumberSuffix = (num) => {
  try {
      const parsedNum = parseInt(num, 10);
      if (isNaN(parsedNum)) {
          throw new Error('Invalid input. Please provide a valid number.');
      }

      const lastTwoDigits = parsedNum % 100;
      const lastDigit = parsedNum % 10;

      if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
          return 'th';
      }

      switch (lastDigit) {
          case 1:
              return 'st';
          case 2:
              return 'nd';
          case 3:
              return 'rd';
          default:
              return 'th';
      }
  } catch (error) {
      console.error(error.message);
      return '';
  }
}
function convertToAmPm(hourValue) {
  const hour = +hourValue 
  if (hour >= 1 && hour < 12) {
      return [hour, "AM"];
  } else if (hour === 0|| hour === 24) {
      return [12,"AM"];
  } else if (hour === 12) {
    return [12,"PM"];
  } else if (hour >= 13 && hour <= 23) {
      return [hour - 12,"PM"];
  }  else {
      return ["",""]; 
  }
}
function getMonthName(monthNumber) {
  // array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Validating the month number
  if (monthNumber < 1 || monthNumber > 12) {
    throw new Error("Invalid month number. Month number must be between 1 and 12.");
  }

  // Return the corresponding month name
  return monthNames[monthNumber - 1];
}

export const genrateHeatmapData = (data, year, currMonth = null) => {
  const yearDataHeatMap = [];
  const yearData = data;

  // Loop through each month (1 to 12) or a specific month if provided
  for (let month_i = currMonth ? currMonth : 1; month_i <= 12; month_i++) {
    // Convert the month to a two-digit format, e.g., '01' for January
    const _month = month_i < 10 ? "0" + month_i : month_i;

    // Loop through each day (1 to 31)
    for (let day_i = 1; day_i <= 31; day_i++) {
      // Convert the day to a two-digit format, e.g., '01' for the 1st day
      const _date = day_i < 10 ? "0" + day_i : day_i;

      // Check if the date is valid (considering February 29 for leap years)
      if (isValidDate(Number(year), month_i, day_i)) {
        // Construct the date string in the format 'YYYY-MM-DD'
        const date = `${year}-${_month}-${_date}`;

        // Check if data is available
        if (yearData[_month] && yearData[_month]["dates"][_date]) {
          // If data is available, push it into the heatmap array with the count
          yearDataHeatMap.push([
            date,
            yearData?.[_month]["dates"][_date]["count"],
          ]);
        } else {
          // If data is not available, push a default value of 0 for the count
          yearDataHeatMap.push([date, 0]);
        }
      }
    }
    if (currMonth) break;
  }

  return yearDataHeatMap;
};

export const optionGenrateChartYear = (data, select, options, maxVisual) => {
  const { yearSel } = select;

  // Get the data for months
  const yearData = data[yearSel]["months"];

  // Initialize an array to store heatmap data
  const yearDataHeatMap = genrateHeatmapData(yearData, yearSel);

  const seriesData = [...yearDataHeatMap];

  // console.log(seriesData);
  const range = [yearSel];
  return optionCalenderHeatmap(maxVisual, seriesData, range);
};

export const optionGenrateChartMonth = (data, select, options, maxVisual) => {
  // console.log(data);
  const { monthSel, yearSel } = select;

  const seriesData = genrateHeatmapData(
    data[yearSel]["months"],
    // data,
    Number(yearSel),
    Number(monthSel)
  );
  const lastDate = new Date(yearSel, Number(monthSel), 0).getDate();
  // console.log(seriesData);
  const range = [
    `${yearSel}-${monthSel}-01`,
    `${yearSel}-${monthSel}-${lastDate}`,
  ];
  return optionCalenderHeatmap(maxVisual, seriesData, range);
};

export const optionGenrateChartWeek = (data, select, options, maxVisual) => {
  const { weekSel } = select;

  const weekC = weekSel.split("-")[1];
  // prettier-ignore
  let dataY = [
            ...Array(7)
            
          ].map((_, i)=> {
           return(weekC - 1)*7 + i + 1
          } )
  
  if(dataY[6]>31){
    dataY = dataY.slice(0,3)
  }
  // prettier-ignore
  const dataX = [
            "00", "01", "02", "03", "04", "05", "06", "07",
            "08", "09", "10", "11", "12", "13", "14", "15",
            "16", "17", "18", "19", "20", "21", "22", "23"
          ];
  const seriesData = [];
  const filteredDataDate = data["dates"];

  for (let dates of dataY) {
    const datesStr = dates<10?`0${dates}`:dates
    if (!filteredDataDate[datesStr]) continue;
    for (let hours of dataX) {
      if (filteredDataDate[datesStr].hours[hours]) {
        
        seriesData.push([
          Number(hours),

          (Number(dates) % 7) - 1 < 0 ? 6 : (Number(dates) % 7) - 1,
          filteredDataDate[datesStr].hours[hours].count,
        ]);
      }
    }
  }

  const option = {
    tooltip: {
      
        trigger: 'item', // Trigger the tooltip when hovering over data items (heatmap cells)
        position: function (point, params, dom, rect, size) {
          // Extract the position of the mouse pointer
          const mouseY = point[1];
          // Return the position of the tooltip based on the mouse pointer position
          return mouseY <= 110 ?  'bottom' :  'top';
      },
      formatter: function (params) {
        const xHour = params.data[0];
        const yDate = params.data[1];
        const value = params.data[2];
        const date = (weekC - 1) * 7 + yDate + 1
        const heatmapColor = params.color
          ? `style="background-color: ${params.color}"`
          : "";
        return `<div class="tooltip-box">
                <div class="box-clr-ct">
                <div class="box-color" ${heatmapColor}></div>
                </div>
                <div>
               
                <div>${date}${returnNumberSuffix(date)} ${getMonthName(select?.monthSel)?.slice(0,3) || ''}, ${convertToAmPm(xHour).join("")}</div>
                <div>${value}</div>
                </div>
              </div>`;
      },
     
    },
    xAxis: {
      type: "category",
      data: dataX,
      name:"Hours",
      // show: false,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: dataY,
      name:"Date",
      // show: false,
      splitArea: {
        show: true,
      },
    },
  };

  return optionCartesianHeatmap(
    maxVisual,
    seriesData,
    dataX,
    dataY,
    "Week Map",
    option
  );
};

export const optionGenrateChartDay = (data, select, options, maxVisual) => {
  const { monthSel, yearSel, weekSel, dateSel } = select;
  // prettier-ignore
  const dataY = [
    "00", "01", "02", "03", "04", "05", "06", "07",
    "08", "09", "10", "11", "12", "13", "14", "15",
    "16", "17", "18", "19", "20", "21", "22", "23"
  ]
  // prettier-ignore
  const dataX = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09",
    "10", "11", "12", "13", "14", "15", "16", "17", "18", "19",
    "20", "21", "22", "23", "24", "25", "26", "27", "28", "29",
    "30", "31", "32", "33", "34", "35", "36", "37", "38", "39",
    "40", "41", "42", "43", "44", "45", "46", "47", "48", "49",
    "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"
  ];

  const XYDataMap = [];
  const filteredDataDate = data[yearSel]["months"][monthSel]["dates"];

  for (let hours of dataY) {
    if (!filteredDataDate[dateSel].hours[hours]) {
      for (let i = 0; i < dataX.length; i++) {
        XYDataMap.push([Number(dataX[i]), Number(hours), 0]);
      }
      continue;
    }
    for (let minutes of dataX) {
      if (filteredDataDate[dateSel].hours[hours].minutes[minutes]?.count) {
        XYDataMap.push([
          Number(minutes),
          Number(hours),
          filteredDataDate[dateSel].hours[hours].minutes[minutes]?.count,
        ]);
      } else {
        XYDataMap.push([Number(minutes), Number(hours), 0]);
      }
    }
  }

  const reducedSeriesData = [];
  // Loop through the XYDataMap in 2x2 blocks (in chunks of 60 (rows))
  for (let k = 0; k < XYDataMap.length / 60; k += 2) {
    // Iterate through the X and Y coordinates within each 2x2 block(in chunks of 2 (columns))

    for (let i = k * 60; i < (k + 1) * 60; i += 2) {
      // Calculate the sum of values in the 2x2 block
      const sum =
        XYDataMap[i][2] + // Top-left value
        XYDataMap[i + 1][2] + // Top-right value
        XYDataMap[60 + i][2] + // Bottom-left value
        XYDataMap[60 + i + 1][2]; // Bottom-right value

      // Calculate the new X and Y coordinates for the reduced data
      // Store the reduced data in the reducedSeriesData array
      reducedSeriesData.push([
        Math.floor(XYDataMap[60 + i + 1][0] / 2),
        Math.floor(XYDataMap[60 + i + 1][1] / 2),
        sum,
      ]);
    }
  }

  // combining  adjacent hours to one

  // prettier-ignore
  const dataYnew = [
    "01",  "03",  "05",  "07",
    "09",  "11", "13", "15",
    "17",  "19",  "21",  "23"
 ]
  // prettier-ignore
  const dataXnew = [
    "01",  "03",  "05",  "07",  "09",
    "11",  "13", "15",  "17", "19",
   "21",  "23",  "25",  "27",  "29",
    "31",  "33",  "35",  "37",  "39",
    "41",  "43",  "45",  "47",  "49",
    "51",  "53",  "55",  "57",  "59"
 ];

  // data to be showned when tooltip shows and how it should be shown are defined in this formatter
  const option = {
    tooltip: {
      position: function (point, params, dom, rect, size) {
        // Extract the position of the mouse pointer
        const mouseY = point[1];
        // Return the position of the tooltip based on the mouse pointer position
        return mouseY < 117 ?  'bottom' :  'top';
    },
      formatter: function (params) {
        const xMin = params.data[0];
        const yHour = params.data[1];
        const value = params.data[2];
        const heatmapColor = params.color
          ? `style="background-color: ${params.color}"`
          : "";
        return `<div class="tooltip-box">
                <div class="box-clr-ct">
                <div class="box-color" ${heatmapColor}></div>
                </div>
                <div>
                <div>${convertToAmPm(yHour * 2).join("")} - ${convertToAmPm(yHour * 2 + 2).join("")} </div>
                <div>${xMin * 2} - ${xMin * 2 + 2} Min</div>
                <div>${value}</div>
                </div>
              </div>`;
      },
     
    },
    xAxis: {
      type: "category",
      data: dataXnew,
      name:"Minutes",
      // show: false,
      splitArea: {
        show: true,
      },
    },
    yAxis: {
      type: "category",
      data: dataYnew,
      name:"Hours",
      // show: false,
      splitArea: {
        show: true,
      },
    },
  };
  return optionCartesianHeatmap(
    maxVisual,
    reducedSeriesData,
    dataXnew,
    dataYnew,
    "Day Map",
    option
  );
};

export const optionGenerateChartInteractions = (options, data) => {
  const dataX = []
  const dataY = []

  data?.forEach(item => {
    dataX.push(item[0])
    dataY.push(item[1])
  })
  
  return optionLineChart(options,dataX, dataY)
} 
export const optionGeneratePieChartInteractions = (options,data) => {
  return optionPieChart(options,data)
} 
export const optionGenerateRadarChart = (options,data) => {
  return optionRadarChart(options,data)
} 