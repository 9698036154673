import './App.css';
import './app.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications';
import Home from './container/Home/Home';
import DashBoard from './container/DashBoard/DashBoard';
import NotFound from './container/NotFound';
import { useSelector } from 'react-redux';
import APIConstant from './utility/APIConstant';
import { Timeline } from './components/content/Timeline';
import  MapComponent  from './components/content/MapComponent';
import Statistics from './components/content/Statistics';



const CEAuthorAdmin = () => {

  const userInfo = useSelector(state => state.user);
  // console.log("---------------->  ",APIConstant.getBaseUrlPath())
  const isUserLoggedIn = () =>{
   return Object.keys(userInfo.loggedInUser).length  >  0 ? true:false
  }
  const basename = window.ce.basepath

  return (
    <div className="ce-admin-app">
      <Router >
      {/* <Router basename={basename}> */}
        <ToastProvider placement="bottom-left" autoDismiss={ true }>
        <Routes>
            <Route default path="/" element={ <Home/> } />
            <Route path="/home" element={<Home/> } />
            <Route path="/dashboard" element={ <DashBoard /> } />
             <Route path={`/analytics/:client/:location`} element={ <Statistics /> } />
            <Route path="/timeline/:client/:location" element={ <Timeline /> } /> 
            <Route path="/user-map" element={ <MapComponent /> } /> 
             <Route path="/*" element={ <Home /> }/>
            <Route path="*" element={ <Home />}/>
          </Routes>
        </ToastProvider>
      </Router>
    </div>
  );
}

export default CEAuthorAdmin;
