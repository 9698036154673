import React, { useEffect, useState,useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import Header from "../../components/layout/Header";
import SideNav from "../../components/layout/SideNav";
// import './DashBoard.scss'
import { useDispatch, useSelector } from "react-redux";
import CommonModal from "../../components/modal/CommonModal";
import UsersPanel from "../../components/content/UsersPanel";
import CoursesPanel from "../../components/content/CoursesPanel";
import * as utilityActions from "../../redux/actions/utilityActions";
import * as userActions from "../../redux/actions/userActions";
import { useNavigate } from "react-router-dom";
import Statistics from "../../components/content/Statistics";
import SideSheet from "../../components/sidesheet/SideSheet";
import UsersCreation from "../../components/content/UsersCreation";
import Acl from "../../components/content/Acl";
import PrivilegeHelper from "../../utility/PrivilegeHelper";
import * as clientActions from "../../redux/actions/clientActions";
import APIConstant from "../../utility/APIConstant";
import SupplimentaryPanel from "../../components/content/SupplimentaryPanel";
import TopicHistory from "../../components/content/TopicHistory";
import AdminTags from "../../components/content/AdminTags";
import  AMLQuestionnaire  from "../../components/content/AMLQuestionnaire"
import { Analytics } from "../../components/content/Analytics";
import { Timeline } from "../../components/content/Timeline";
import MapComponent  from "../../components/content/MapComponent";
import CryptoJS from "crypto-js";
import { useToasts } from 'react-toast-notifications';
// import { socket } from "../../utility/Socket";


const DashBoard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const utilityInfo = useSelector((state) => state.utility);
  const userInfo = useSelector((state) => state.user);
  const clientsInfo = useSelector((state) => state.client);
  const [isaddListOpen, setIsAddListOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // to allow autologin
  // by default : directLogin is false
  // if we redirect to signIN or start app from sign IN direct login is false .
  const directLogin=useSelector((state)=>state.user.directLogin)
  const isMounted = useRef(true);
  const { addToast } = useToasts();
  
  /**
   * on loggin user informations are stored in session
   * on refresh get the session stored username and password and loggin back
   * if user not set state then route back to home
   *
   * */

  function decryptCredentials(encryptedCrds) {
    try {
      
        let decryptedValue= CryptoJS.AES.decrypt(encryptedCrds, APIConstant.ENCRYPT_CODE);
        let decryptedStr = decryptedValue.toString(CryptoJS.enc.Utf8);
     

        return decryptedStr;
    } catch (error) {
        console.error("Decryption error:", error);
        return null;
    }
}


  useEffect(()=>{
    let values=[]
  try{
    values=searchParams?window.atob(`${searchParams?.get("id")}`)?.split("^") : []
    if(values.length>1 && isMounted.current){
      dispatch(userActions.directLoginReq(true))
        let localUser = window.sessionStorage.getItem("user");
        let pastEmail= JSON.parse(localUser)?.email || null   
        if(values.length>1 && pastEmail!==values[0]){
          let email=values[0]
          let pass=window.btoa(decryptCredentials(values[1]))
          let role=Number(values[2])
          let obj={"email":email,"role":role}

          window.sessionStorage.setItem('p', pass)
          window.sessionStorage.setItem('user', JSON.stringify(obj))
          let localUser = window.sessionStorage.getItem("user");
          let localPassword = window.sessionStorage.getItem("p");
          
            dispatch(
              userActions.checkUserInfo({
                email: JSON.parse(localUser).email,
                password: window.atob(localPassword),
              })
            );
       
          
        }
      }
    }
      catch(e){
        
          values=[]
          if(window.sessionStorage.getItem("user")){
            sessionStorage.removeItem("user");
          }
          if(window.sessionStorage.getItem("p")){
             sessionStorage.removeItem("p");
          }
            addToast( "Invalid Credential", {
              appearance: "error",
              autoDismiss: true,
              placement: "top-right",
          } );
          dispatch(userActions.directLoginReq(false))
          navigate("/");
          
        
      }
   
    

   return () => {
    isMounted.current = false;
  };} ,[])

  useEffect(() => {
    let localUser = window.sessionStorage.getItem("user");
    let localPassword = window.sessionStorage.getItem("p");
    if (Object.keys(userInfo?.loggedInUser).length > 0) {
      dispatch(userActions.getAllCoursesRequest(userInfo?.loggedInUser));
      dispatch(userActions.loggedInUserCourses(userInfo?.loggedInUser));
      dispatch(userActions.getAllUsersRequest(userInfo?.loggedInUser));
      dispatch(userActions.getAllAdminAclRequest(userInfo?.loggedInUser));
      dispatch(clientActions.getAllClientRequest(userInfo?.loggedInUser));
      dispatch(userActions.s3ConfigReq())
      // // delete later
      dispatch(userActions.courseCategoryRequest())
      dispatch(userActions.userCacheRequest())
      dispatch(userActions.activeUserCacheRequest())
      //
      dispatch(userActions.companyDataRequest());
      dispatch(userActions.languagePackageRequest());

      // Clinet role id is 5 so static check
      // should pass dynamic client id from logged in clinet
      // now passing static id
      if (userInfo?.loggedInUser?.role === APIConstant.CLIENT_ROLE_ID) {
        console.debug("userInfo?.loggedInUser", userInfo?.loggedInUser);
        if (
          userInfo?.loggedInUser?.clients &&
          userInfo?.loggedInUser?.clients.length > 0
        ) {
        }
        dispatch(
          clientActions.getClientCoursesRequest(
            userInfo?.loggedInUser?.clients[0]?.id
          )
        );
      }
      //user login is completed then listing the course directly
      if (userInfo?.allCourses && utilityInfo.selectedMenuId === -1) {
        dispatch(utilityActions.setSelectedMenu(1));
      }
    } else if (
      Object.keys(userInfo.loggedInUser).length === 0 &&
      localUser !== null &&
      localPassword !== null
    ) {
      if(!directLogin){
        dispatch(
          userActions.checkUserInfo({
            email: JSON.parse(localUser).email,
            password: window.atob(localPassword),
          })
        );
      }
    } else if (
      Object.keys(userInfo.loggedInUser).length === 0 &&
      localUser === null
    ) {
      navigate("/");
    }
  }, [userInfo.loggedInUser]);

  useEffect(() => {
    if (clientsInfo.allClientList.length > 0 && !userInfo.authInfo) {
      // console.log(" -- -  -- -  -- -  -- -  -- -  -- -  -- -  -- -  -- -  -- - ",!userInfo.authInfo, clientsInfo.allClientList.length)

      // console.log("capi ---> ", APIConstant.getUrl("capi"));
      // console.log("ceauthor ---> ", APIConstant.getUrl("ceauthor"));
      // console.log("api ---> ", APIConstant.getUrl("api"));
      // console.log("player ---> ", APIConstant.getUrl("player"));
      // console.log("lms ---> ", APIConstant.getUrl("lms"));
      // console.log("clientid ---> ", APIConstant.getUrl("clientid"));
      dispatch(userActions.getLmsSignedUrl(userInfo?.loggedInUser));
    }
  }, [clientsInfo.allClientList]);

  const addUser = () => {
    dispatch(
      utilityActions.toggleSideSheet({
        direction: "right",
        width: 600,
        title: "Create User",
        open: true,
        component: <UsersCreation></UsersCreation>,
      })
    );
  };

  const addUpdate = (type) => {
    if (type === "updates") {
      dispatch(
        utilityActions.toggleSideSheet({
          direction: "right",
          width: 800,
          title: "Create Update",
          open: true,
          component: (
            <SupplimentaryPanel name={"update"} mode={"Add"} data={{}} />
          ),
        })
      );
    } else if (type === "vignettes") {
      dispatch(
        utilityActions.toggleSideSheet({
          direction: "right",
          width: 800,
          title: "Create Vignette",
          open: true,
          component: (
            <SupplimentaryPanel name={"vignettes"} mode={"Add"} data={{}} />
          ),
        })
      );
    } else if (type === "notes") {
      dispatch(
        utilityActions.toggleSideSheet({
          direction: "right",
          width: 800,
          title: "Create Note",
          open: true,
          component: (
            <SupplimentaryPanel name={"notes"} mode={"Add"} data={{}} />
          ),
        })
      );
    }
  };
  
  // useEffect(()=>{
    
  //   if (userInfo?.isLoggedIn && socket){
      
  //     socket.connect()
  //      socket.on("error", (error) => {
  //      console.log("Connection error ", error);
  // });
  
  // socket.on("ping", () => {
  //     console.log("Ping server");
  // });
  // socket.on("reconnect", (attempt) => {
  //      console.log("Reconnection -- > ", attempt);
  //     });
  // socket.emit("signal", { action: "subscribe", params: {'userid':121, 'clientid':123452} });
  // socket.on("notifications", (data) => {
  //       console.log("Server sent - ", data);
  //   });
  //   }

  //   return () => {
  //     socket.off()
  //     socket.disconnect()
  //   }  
  // },[userInfo?.isLoggedIn])


  return (
    <>
      <Header />
      <section className="ce-content">
        <CommonModal />
        <SideSheet />
        <aside>
          <SideNav user={userInfo?.loggedInUser} />
        </aside>
        <div className="main">
          <div className="container">
            {(utilityInfo?.selectedMenuId === 1 ||
              utilityInfo?.selectedMenuId === 4 || utilityInfo?.selectedMenuId === 14) && (
              <UsersPanel user={userInfo?.loggedInUser} />
            )}
            {utilityInfo?.selectedMenuId === 2 && <CoursesPanel />}
            {utilityInfo?.selectedMenuId === 3 && <Acl />}
            {utilityInfo?.selectedMenuId === 5 && <TopicHistory />}
            {utilityInfo?.selectedMenuId === 6 && <AdminTags />}
            {utilityInfo?.selectedMenuId === 10 && <Analytics />}
            {utilityInfo?.selectedMenuId === 11 && <Statistics />} 
            {/* { utilityInfo?.selectedMenuId === 7 && <AMLQuestionnaire /> } */}
            {utilityInfo?.selectedMenuId === 12 && <Timeline />}
            {utilityInfo?.selectedMenuId === 13 && <MapComponent />}
           
          </div>
          {Number(utilityInfo?.selectedMenuId) !== 6 &&
            Number(utilityInfo.selectedMenuId) !== 10 &&
            Number(utilityInfo.selectedMenuId) !== 11 &&
            Number(utilityInfo.selectedMenuId) !== 12 &&
            Number(utilityInfo.selectedMenuId) !== 13 &&
            (utilityInfo?.selectedMenuId === 4 &&
            PrivilegeHelper(userInfo?.loggedInUser.role).getAccess() ? (
              <div className="add-user-btn-holder">
                {isaddListOpen && (
                  <ul className="update-active">
                    <li onClick={() => addUpdate("updates")}>Updates</li>
                    <li onClick={() => addUpdate("vignettes")}>Vignettes</li>
                    <li onClick={() => addUpdate("notes")}>Notes</li>
                  </ul>
                )}
                {isaddListOpen && (
                  <i
                    className="fa fa-times-circle"
                    onClick={() => setIsAddListOpen(false)}
                  ></i>
                )}
                {!isaddListOpen && (
                  <i
                    className="fa fa-plus-circle"
                    onClick={() => setIsAddListOpen(true)}
                  ></i>
                )}
              </div>
            ) : (
              PrivilegeHelper(userInfo?.loggedInUser.role).getAccess() && (
                <div className="add-user-btn-holder">
                  <i
                    className="fa fa-plus-circle"
                    onClick={() => addUser()}
                  ></i>
                </div>
              )
            ))}
        </div>
      </section>
    </>
  );
};

export default DashBoard;
