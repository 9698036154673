import * as types from '../reducers/types'

export const getAllClientRequest =(payload)=>{
    return {
        type: types.ALL_CLIENT_REQ,
        payload:payload,
    }
}

export const getAllClientResponse =(payload)=>{
    return {
        type: types.ALL_CLIENT_RES,
        payload:payload,
    }
}


export const getClientCoursesRequest =(payload)=>{
    return {
        type: types.LOGGEDIN_CLIENT_COURSES_REQ,
        payload:payload,
    }
}

export const setLoggedOutUser =(payload)=>{
    return {
        type: types.LOGGEDOUT_CLIENT_COURSES_REQ,
        payload:payload,
    }
}
export const getClientCoursesResponse =(payload)=>{
    return {
        type: types.LOGGEDIN_CLIENT_COURSES_RES,
        payload:payload,
    }
}

