import { combineReducers } from "redux"; 
import clientReducer from "./clientReducer";
import userReducer from "./userReducer";
import utilityReducer from "./utilityReducer";

const rootReducer = combineReducers({
    utility:utilityReducer,
    user:userReducer,
    client:clientReducer
})

export default rootReducer;