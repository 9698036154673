import React, { useEffect, useState } from 'react'

import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from '../../redux/actions/userActions'
import "./UserEdit.scss";
import * as utilityActions from '../../redux/actions/utilityActions'




import { useFormik } from "formik";
import * as yup from 'yup';

const UserEdit = ( { data } ) => {
    // let cateList = [
    //     { "id": 1, "category": "Import", "courses": [] },
    //     { "id": 2, "category": "Export", "courses": [] },
    //     { "id": 3, "category": "Sanctions", "courses": [] },
    //     { "id": 4, "category": "Miscellaneous", "courses": [] },
    //     { "id": 5, "category": "Security & Trade", "courses": [] },
    //     { "id": 6, "category": "Corruption & Antiboycott", "courses": [] }]

    //     let assignedCateList = [
    //         { "id": 1, "category": "Import","assignedCourses":[] },
    //         { "id": 2, "category": "Export","assignedCourses":[] },
    //         { "id": 3, "category": "Sanctions","assignedCourses":[] },
    //         { "id": 4, "category": "Miscellaneous","assignedCourses":[] },
    //         { "id": 5, "category": "Security & Trade","assignedCourses":[] },
    //         { "id": 6, "category": "Corruption & Antiboycott","assignedCourses":[] }]

    const alphanumericRegExp = /^[A-Za-z0-9\s]+$/;
    const alphanumericRegExpNew = /^(?=.*[A-Za-z0-9])(?=.*\d)(?=.*[!@#$%^&*._-])[A-Za-z0-9\d!@#$%^&*._-]{6,30}$/;
    const [checked, setChecked] = useState([]);
    const [checkedTwo, setCheckedTwo] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const userInfo = useSelector( state => state.user );
    // const clientInfo = useSelector(state => state.client);
    const [equal, setEqual] = useState(true)

    const { addToast } = useToasts();
    const dispatch = useDispatch()
    const cateList =useSelector((state)=>state.user.courseCategory).map(item=>{return{...item,category:item.coursecategory,courses:[]}})
    const assignedCateList=useSelector((state)=>state.user.courseCategory).map(item=>{return{...item,category:item.coursecategory,assignedCourses:[]}})
    const [categoryList, setCategoryList] = useState(cateList);
    const [assignedCategoryList, setAssignedCategoryList] = useState(assignedCateList);
    const [totalAssignCourses, setTotalAssignCourses] = useState([]);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    }
  
    useEffect(() => {
      dispatch(userActions.assignedCoursesResponse());
    }, []);
      useEffect(() => {
        const courseIds = data?.courses?.map((course) => {
          return course?._id;
        });
        const courseVal = userInfo?.allCourses?.filter((item) => {
          if (courseIds.includes(item._id)) {
            return item._id;
          }
        });
        courseVal?.map((item) => {
          let assignedList = assignedCategoryList;
          assignedList.filter((ele) => {
            if (item.coursecategory === ele.id) {
              ele.assignedCourses.push(item);
            }
            setAssignedCategoryList(assignedList);
          });
        });
      }, [data?.courses]);
     
      
  useEffect(() => {
        const courses = userInfo?.allCourses.filter((ele) => {

          let assignedIds = data?.courses?.map((item) => {
            return item._id;
          });

          return !assignedIds.includes(ele._id);
        });
        const finalCoursesList=data?.courses ? courses : userInfo?.allCourses;
        if (Array.isArray(finalCoursesList)) {
          finalCoursesList?.forEach((el, cIndex) => {
                categoryList.forEach((category, categoryIndex) => {
                    const duplicate = categoryList[categoryIndex]?.courses?.findIndex(obj => {
                        return obj.id === categoryIndex + '_' + cIndex;
                    });
                    if (Number(el?.coursecategory) === Number(category.id) && duplicate === -1) {
                        categoryList[categoryIndex].courses.push({ "id": categoryIndex + '_' + cIndex, "courseList": el })
                    }
                })
            })
            setCategoryList(categoryList);
            dispatch(userActions.assignedCoursesResponse());

        }
    }, [userInfo?.allCourses])


    const filledValues = {
        user_name: data?.user?.username,
        first_name: data?.user?.firstname,
        last_name: data?.user?.lastname,
        email: data?.user?.email,
        role: data?.user?.role
    };

    const validationSchema = yup.object().shape( {
        user_name: yup.string().matches( alphanumericRegExp, 'FirstName is not valid' ).required( "Required" ),
        first_name: yup.string().matches( alphanumericRegExp, 'FirstName is not valid' ).required( "Required" ),
        last_name: yup.string().matches( alphanumericRegExp, 'LastName is not valid' ).required( "Required" ),
        email: yup.string().email( "Invalid email address" ).required( "Required" ),
        password: yup.string().matches( alphanumericRegExpNew, ' Password must have 6 characters that include atleast 1 character , 1 number and 1 special character in (!@#$%^&*)' ).required( "Required" ).min( 6, "Must be more than 6 characters" ),
        role: yup.string().required( "Required" ),
    } );

    console.debug( "data", data?.user, "filledValues", filledValues );



    const formik = useFormik( {
        initialValues: filledValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.debug( values );
            const obj = {
                ...values
            }

            obj.firstname = obj.first_name
            obj.lastname = obj.last_name
            obj.username = obj.user_name

            delete obj.first_name;
            delete obj.last_name;
            delete obj.user_name;
        },
    } );

   
    
      const notLeft = (a, b) => {
        b.map((courselist) => {
          const catevalue =
            categoryList[courselist.courseList.coursecategory - 1];
          const finalRes = catevalue?.courses?.filter((item) => {
            return Number(item.courseList._id) !== Number(courselist.courseList._id);
          });
          categoryList[courselist.courseList.coursecategory - 1].courses =
            finalRes;
          setCategoryList(categoryList);
        });
        return a.filter((value) => b.indexOf(value) === -1);
      };


      const notRight = (a, b) => {
        b.map((courselist) => {
          const catevalue =
          assignedCategoryList[courselist?.coursecategory
            - 1];
          const finalRes = catevalue?.assignedCourses?.filter((item) => {
            return Number(item._id) !== Number(courselist._id);
          });
          assignedCategoryList[courselist?.coursecategory- 1].assignedCourses =
            finalRes;
          setAssignedCategoryList(assignedCategoryList);
        });
        return a.filter((value) => b.indexOf(value) === -1);
      };
      const leftIntersection = (a, b) => {
        let allCoursesRes = [];
        a.map((courselist) => {
          const catevalue =
            categoryList[courselist?.courseList?.coursecategory - 1];

            allCoursesRes = catevalue?.courses?.filter((item) => {
            return item.courseList._id === courselist.courseList._id;
          });
        });
        return a.filter(
          (value) => b.courses?.courseList?.indexOf(value.courseList._id) !== -1
        );
      };

      const rightIntersection = (a, b) => {
        let allAssignedRes = [];
        a.map((courselist) => {
          const catevalue =
            assignedCategoryList[courselist?.coursecategory - 1];
            allAssignedRes = catevalue?.assignedCourses?.filter((item) => {
            return item._id === courselist._id;
          });
        });
        return a.filter(
          (value) => b.assignedCourses?.indexOf(value.courseList._id) !== -1
        );
      };
    
      const leftChecked = leftIntersection(checked, categoryList);
      const rightChecked =  rightIntersection(checkedTwo, assignedCategoryList);  

      const handleSelectedCourses = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);

      };

      const handleSelectedAssignCourses = (value) => () => {
        const currentIndex = checkedTwo.indexOf(value);
        const newChecked = [...checkedTwo];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setCheckedTwo(newChecked);
      };
      
    
    
      const handleTransferCourses = () => {
        const selected = leftChecked.map((item) => {
          return item.courseList.coursecategory;
        });
        let test = assignedCategoryList?.map((ele) => {
          if (selected.includes(ele.id)) {
            const final = leftChecked.forEach((value) => {
              if (ele.id === value.courseList.coursecategory) {
                return ele.assignedCourses.push(value.courseList);
              }
            });
          }
        });
        setCategoryList(notLeft(categoryList, leftChecked));
        setChecked(notLeft(checked, leftChecked));
        setTotalAssignCourses(leftChecked)
        setEqual(false)

      };
    
      const handleTransferAssignCourses = () => {

        const rightSelected = rightChecked.map((item) => {
          return item.coursecategory;
        });

let testright = categoryList.map((ele) => {
  if (rightSelected.includes(ele.id)) {
    const final =rightChecked.forEach((value) => {
      if (ele.id === value.coursecategory) {
        return ele.courses.push({ "id": "425", "courseList": value });
      }
    });
  }
});
        setCategoryList(categoryList)
        setCheckedTwo(notRight(checkedTwo, rightChecked));
        setAssignedCategoryList(notRight(assignedCategoryList, rightChecked));
        setEqual(true)

      };
    
      const assignCourses = () => {
        const courseIds = totalAssignCourses.map((ele) => {
          return ele.courseList?._id;
        });

        let assignCourseObj = {
          userid: data?.user?._id,
          courseids: courseIds,
          flag: "course",
          assignedby: userInfo?.loggedInUser?._id,
        };
      dispatch( userActions.AssignCourses(assignCourseObj) );

      addToast( "Courses Assigned Successfully", {
        appearance: "success",
        autoDismiss: true,
        placement: "bottom-right",
    } );
    setTimeout( () => {
      dispatch( utilityActions.toggleSideSheet( {
          direction: 'left',
          title: '',
          open: false
      } ) );
  }, 300 )

      };


      const customList = (title, categoryList) => (
        <div>
            <div className='title-container'>
                {title}
            </div>
    
        <Card>
        {categoryList.map((category, index) => {
        return(
          <Accordion
          key={category.id} expanded={expanded === category.id} onChange={handleChange(category.id)}
          >
             <AccordionSummary
            expandIcon={<ExpandMoreIcon className='color-ce-green' />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography sx={{ width: '80%', flexShrink: 0 }}>{category.category?category.category:""} </Typography>
        </AccordionSummary>
            <AccordionDetails>
          <Divider /> 
          <div>
          <List
              key={index}
                sx={{
                  width: 360,
                  height: 230,
                  bgcolor: "background.paper",
                  overflow: "auto"
                }}
                dense
                component="div"
                role="list"
              >
                {
                    category?.courses?
                    category?.courses?.map((course, cIndex) => {
                        const labelid = `course-courseList-coursename-${index}`;
          
                        return (
                          <ListItem
                          
                         key={cIndex}
                            role="listitem"
                            button
                            onClick={handleSelectedCourses(course)}
                          >
                            <ListItemIcon sx={{minWidth:"32px"}}>
                              <Checkbox
                              sx={{padding:"2px 7px 2px 7px"}}
                                checked={checked.indexOf(course) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": labelid
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                            id={labelid}
                              primary= {course?.courseList?.coursename}
                            />
                          </ListItem>
                        );
                      }) :
                      category?.assignedCourses?.map((course, cIndex) => {
                        const labelid = `course-courseList-coursename-${index}`;
          
                        return (
                          <ListItem
                         key={cIndex}
                            role="listitem"
                            button
                            onClick={handleSelectedAssignCourses(course)}
                          >
                            <ListItemIcon sx={{minWidth:"32px"}}>
                              <Checkbox
                              sx={{padding:"2px 7px 2px 7px"}}
                                checked={checkedTwo.indexOf(course) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{
                                  "aria-labelledby": labelid
                                }}
                              />
                            </ListItemIcon>
                            <ListItemText
                            id={labelid}
                              primary={course?.coursename}
                            />
                          </ListItem>
                        );
                      })

                }
              </List>
          </div>
            
            </AccordionDetails>
          </Accordion>)
})}
        </Card>
        </div>
      );
    return <div>

        <form className="user-form" onSubmit={ formik.handleSubmit }>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <TextField label="User Name" id="outlined-size-small" InputLabelProps={ { shrink: true } } fullWidth inputProps={ { maxLength: 100 } } value={ formik.values.user_name } onChange={ formik.handleChange( 'user_name' ) } size="small" />
                    <p className="error-msg">{ formik.errors.user_name && formik.touched.user_name && formik.errors.user_name }</p>
                </div>
            </div>
        </form>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <TextField label="First Name" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } inputProps={ { maxLength: 100 } } value={ formik.values.first_name } onChange={ formik.handleChange( 'first_name' ) } size="small" />
                <p className="error-msg">{ formik.errors.first_name && formik.touched.first_name && formik.errors.first_name }</p>
            </div> <div className="col-lg-6 col-md-6 col-sm-12">
                <TextField label="Last Name" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } inputProps={ { maxLength: 100 } } value={ formik.values.last_name } onChange={ formik.handleChange( 'last_name' ) } size="small" />
                <p className="error-msg">{ formik.errors.last_name && formik.touched.last_name && formik.errors.last_name }</p>
            </div>
        </div>

        <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <TextField label="Email" id="outlined-size-small" fullWidth InputLabelProps={ { shrink: true } } onChange={ formik.handleChange( 'email' ) } inputProps={ { maxLength: 100 } } value={ formik.values.email } size="small" />
                <p className="error-msg">{ formik.errors.email && formik.touched.email && formik.errors.email }</p>
            </div>
        </div>
        <div className='course-assign-container'>
        <Grid container spacing={1} justifyContent="space-between" alignItems="center">
      <Grid item>{customList("Courses List", categoryList)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleTransferCourses}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
           
            <i class="fa fa-chevron-right"></i>

          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleTransferAssignCourses}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            <i class="fa fa-chevron-left"></i>

          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList("Assigned Courses", assignedCategoryList)}</Grid>
    </Grid>

        </div>
        <div className='assigned-courses'>
              <button 
              disabled={equal}
                        className={ equal ? 'btn-disabled' : 'add-btn' }
                        onClick={ () => assignCourses() }
                    >Assign</button>
            </div>
    </div>
}

export default UserEdit